import React from 'react';
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const WarningContainer = styled.div`
    background-color: #f1a6a6;
    color: black;
    height: 55px;
    width: 100%;
    position: absolute;
    top: 55px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: env(safe-area-inset-top);
    
    @media (min-width: 600px){
        top: 64px;
    }
`;

const VerificationWarning = (props) => {
  let history = useHistory();
  return (
    <WarningContainer>
      <div>Twój numer telefonu nie jest zweryfikowany.</div>
      <div onClick={() => {
        history.push('phone-verification')
      }}><u>Potwierdź kodem SMS</u></div>
    </WarningContainer>
  );
};

export default VerificationWarning;
