import {
  SET_WEATHER_ALERTS_DATA,
  SET_WEATHER_ALERTS_FETCH_STATUS,
  SET_WEATHER_FORECAST_DATA,
  SET_WEATHER_FORECAST_FETCH_STATUS
} from "./weather.actionTypes";


export const INITIAL_STATE: any = {
  weatherForecast: null,
  weatherForecastFetchStatus: null,
  weatherAlerts: null,
  weatherAlertsFetchStatus: null
};

export default function utils(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_WEATHER_FORECAST_FETCH_STATUS:
      return {
        ...state,
        weatherForecastFetchStatus: action.payload,
      };
    case SET_WEATHER_FORECAST_DATA:
      return {
        ...state,
        weatherForecast: action.payload,
      };
    case SET_WEATHER_ALERTS_FETCH_STATUS:
      return {
        ...state,
        weatherAlertsFetchStatus: action.payload,
      };
    case SET_WEATHER_ALERTS_DATA:
      return {
        ...state,
        weatherAlerts: action.payload,
      };
    // case userActionTypes.UNAUTH_USER:
    //     return {...INITIAL_STATE};
    default:
      return state;
  }
}
