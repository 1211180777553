import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import styled from 'styled-components';
import BottomNavigation from '@material-ui/core/BottomNavigation/BottomNavigation';
import FilterDramaOutlinedIcon from '@material-ui/icons/FilterDramaOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import { colors } from './../../theme';
import { useDispatch } from 'react-redux';
import { SET_MENU_OPEN_STATUS } from '../../reducers/utils.actionTypes';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import VerificationWarning from '../../components/VerificationWarning/VerificationWarning';
import { getEventsList, getNewsList } from '../../routes';

const BaseTemplateContainer = styled.div`
  height: 100vh;
`;

const StyledBaseTemplate = styled.div`
  ${props => (props.isGrayBackground ? 'background-color: ' + colors.backgroundColor : '')};
`;

const HeaderContainer = styled.div`
  display: flex;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const HeaderPrimaryLabel = styled.div`
  font-size: 23px;
`;

const PaddingBox = styled.div`
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${props => (props.addPadding ? '55px' : 0)};
`;

const CalendarIcon = styled(DateRangeIcon)`
  position: absolute;
  height: 100%;
  right: 15px;
`;

const useStyles = makeStyles({
  root: {
    background: '#F7F7F7',
    padding: '6px 0px 6px',
    minWidth: 'initial',
    color: '#B3B2B3',
    '&.Mui-selected': {
      color: 'black'
    }
  },
  label: {
    fontSize: '0.6rem',
    color: '#B3B2B3',
    '&.Mui-selected': {
      color: 'black',
      fontSize: '0.7rem !important',
      fontWeight: 'bold'
    }
  }
});

function BaseTemplate(props) {
  const classes = useStyles({});
  const { children, history, headerPrimaryText, headerSecondaryText, showMenu, user } = props;
  const [openMenuStatus, setMenuOpenStatus] = useState(showMenu);
  const paths = [getNewsList(), '/your-voice', '/events', '/weather'];

  const isGrayBackground = () =>
    window.location.href.includes(getNewsList()) || window.location.href.includes(getEventsList());

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname.indexOf('phone-verification') >= 0
    ) {
      setIndex('');
    }
  });

  const [index, setIndex] = useState(() => {
    let result = '';
    if (history && history.location && history.location.pathname) {
      paths.forEach(key => {
        if (history.location.pathname.indexOf(key) >= 0) result = key;
      });
    }
    return result;
  });

  const [showVerificationPopup, setShowVerificationPopup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let result = false;
    if (user && !user.is_phone_verified) {
      const location = history.location.pathname.replace('/', '');
      ['news', 'your-voice', 'events', 'weather'].forEach(key => {
        result = result || location.endsWith(key);
      });
    }

    if (result !== showVerificationPopup) setShowVerificationPopup(result);
  });

  useEffect(() => {
    dispatch({ type: SET_MENU_OPEN_STATUS, payload: openMenuStatus });
  }, [openMenuStatus]);

  useEffect(() => {
    if (showMenu !== openMenuStatus) setMenuOpenStatus(showMenu);
  }, [showMenu]);

  return (
    <BaseTemplateContainer isGrayBackground={isGrayBackground()}>
      <StyledBaseTemplate isGrayBackground={isGrayBackground()}>
        <CustomDrawer showMenu={openMenuStatus} history={history} callback={setMenuOpenStatus} />
        {window.location.href.indexOf('/events') !== -1 && (
          <CalendarIcon
            onClick={() => {
              history.push('/calendar');
            }}
          />
        )}
        <AppBar
          position="static"
          style={{
            zIndex: 1200,
            background: colors.primaryColor,
            width: '100%',
            position: 'fixed',
            top: 0,
            paddingTop: 'env(safe-area-inset-top)',
            left: 0
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                setMenuOpenStatus(true);
              }}
            >
              <MenuIcon />
            </IconButton>
            <HeaderContainer>
              <HeaderPrimaryLabel>{headerPrimaryText}</HeaderPrimaryLabel>
            </HeaderContainer>
            {window.location.href.indexOf('/events') !== -1 && (
              <CalendarIcon
                onClick={() => {
                  history.push('/calendar');
                }}
              />
            )}
          </Toolbar>
          {user && showVerificationPopup && <VerificationWarning />}
        </AppBar>

        <PaddingBox addPadding={user && showVerificationPopup}>{children}</PaddingBox>

        <BottomNavigation
          value={index}
          showLabels
          style={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            paddingBottom: 'env(safe-area-inset-bottom)',
            zIndex: 1,
            left: 0,
            background: '#F7F7F7'
          }}
          className="nav primary"
          onChange={(event, value) => {
            setIndex(value);
            window.scrollTo(0, 0);
          }}
        >
          <BottomNavigationAction
            label="INFORMATOR"
            value={getNewsList()}
            icon={<LocationCityOutlinedIcon />}
            classes={{
              root: classes.root,
              label: classes.label
            }}
            component={Link}
            to={paths[0]}
          />
          <BottomNavigationAction
            label="TWÓJ GŁOS"
            value="/your-voice"
            icon={<QuestionAnswerOutlinedIcon />}
            classes={{
              root: classes.root,
              label: classes.label
            }}
            component={Link}
            to={paths[1]}
          />
          <BottomNavigationAction
            label="ATRAKCJE"
            value="/events"
            icon={<DateRangeOutlinedIcon />}
            classes={{
              root: classes.root,
              label: classes.label
            }}
            component={Link}
            to={paths[2]}
          />
          <BottomNavigationAction
            label="POGODA"
            value="/weather"
            icon={<FilterDramaOutlinedIcon />}
            classes={{
              root: classes.root,
              label: classes.label
            }}
            component={Link}
            to={paths[3]}
          />
        </BottomNavigation>
      </StyledBaseTemplate>
    </BaseTemplateContainer>
  );
}

export default withRouter(BaseTemplate);
// BaseTemplate.whyDidYouRender = true;
