import axios from 'axios';
import { RequestStatus } from '../utils/RequestStatus';
import {
  CHANGE_PASSWORD_POST_STATUS,
  LOGIN_REQUEST_FETCH_STATUS,
  PHONE_LOGIN_CONFIRMATION_REQUEST_FETCH_STATUS,
  PHONE_LOGIN_DATA,
  PHONE_LOGIN_REQUEST_FETCH_STATUS,
  PHONE_VERIFICATION_SEND_DATA,
  PHONE_VERIFICATION_SEND_FETCH_STATUS,
  PHONE_VERIFICATION_VERIFY_FETCH_STATUS,
  REGISTRATION_POST_STATUS,
  SET_IS_LOGGED_STATUS,
  SET_PASSWORD_RESET_POST_STATUS,
  SET_USER_DATA,
  SET_USER_FETCH_STATUS,
  SET_USER_PATCH_STATUS,
  TOKEN_UPDATE
} from './auth.actionTypes';
import { SNACKBAR_STATUS } from './utils.actionTypes';
import { askForPermissioToReceiveNotifications } from '../push-notification';
import { postDevice } from './notifications.actions';

export function loginAction(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/login/`;
  return function(dispatch) {
    dispatch(setLoginStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setLoginStatus(RequestStatus.status.DONE));
        dispatch(updateToken(response.data));
        dispatch(getAuthInfo());
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Zalogowano pomyślnie!' }
        });
      })
      .catch(error => {
        Object.keys(error.response.data).forEach(key => {
          dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: error.response.data[key][0] }
          });
        });
        dispatch(setLoginStatus(RequestStatus.status.ERROR));
      });
  };
}

export function getAuthInfo() {
  let url = process.env.REACT_APP_API_URL + `/auth/user/`;
  return function(dispatch) {
    dispatch(setUserFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        let isIOS =
          /iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        if (!isIOS) {
          if (Notification.permission === 'granted') {
            askForPermissioToReceiveNotifications().then(function(response) {
              dispatch(
                postDevice({
                  registration_id: response,
                  type: 'web'
                })
              );
            });
          } else {
            console.log(':(');
            askForPermissioToReceiveNotifications().then(function(response) {
              console.log('response', response);
              dispatch(
                postDevice({
                  registration_id: response,
                  type: 'web'
                })
              );
            });
          }
        }

        dispatch(setIsLoggedStatus(true));
        dispatch(setUserData(response.data));
        dispatch(setUserFetchStatus(RequestStatus.status.DONE));
      })

      .catch(response => {
        dispatch(setUserFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

export function updateUserData(data, photos) {
  let url = process.env.REACT_APP_API_URL + `/auth/user/`;
  return function(dispatch) {
    dispatch(setUserPatchStatus(RequestStatus.status.FETCHING));
    let formData = null;
    if (photos.hasOwnProperty('photo') && photos['photo'] !== null) {
      formData = new FormData();
      Object.keys(photos).forEach(key => {
        if (photos[key])
          formData.append(
            key,
            new Blob(photos[key], { type: photos[key][0].type }),
            photos[key][0].name
          );
      });
      Object.keys(data)
        .filter(key => key.indexOf('photo') === -1)
        .forEach(key => {
          formData.set(key, data[key]);
        });
    } else {
      data.photo = null;
    }
    axios
      .patch(url, formData || data)
      .then(response => {
        dispatch(setUserData(response.data));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Dane zmienione pomyślnie!' }
        });
        dispatch(setUserPatchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        Object.keys(error.response.data).forEach(key => {
          dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: error.response.data[key][0] }
          });
        });
        dispatch(setUserPatchStatus(RequestStatus.status.ERROR));
      });
  };
}

export function registerAction(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/registration/`;
  return function(dispatch) {
    dispatch(setRegistrationPostStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setRegistrationPostStatus(RequestStatus.status.DONE));
        dispatch(updateToken(response.data));
        dispatch(getAuthInfo());
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Zarejestrowano pomyślnie!' }
        });
      })
      .catch(error => {
        dispatch(setRegistrationPostStatus(RequestStatus.status.ERROR));
        console.log('error', error.response);
        if (+error.response.status !== 500)
          Object.keys(error.response.data).forEach(key => {
            dispatch({
              type: SNACKBAR_STATUS,
              payload: { status: true, content: error.response.data[key][0] }
            });
          });
      });
  };
}

export function sendPasswordReset(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/password/reset/`;
  return function(dispatch) {
    dispatch(setPasswordResetPostStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setPasswordResetPostStatus(RequestStatus.status.DONE));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: response.data.detail }
        });
      })
      .catch(error => {
        dispatch(setPasswordResetPostStatus(RequestStatus.status.ERROR));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: error.response.data['email'] }
        });
      });
  };
}

export function changePasswordAction(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/password/change/`;
  return function(dispatch) {
    dispatch(setChangePasswordPostStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setChangePasswordPostStatus(RequestStatus.status.DONE));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Hasło zmienione pomyślnie!' }
        });
      })
      .catch(error => {
        Object.keys(error.response.data).forEach(key => {
          dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: error.response.data[key][0] }
          });
        });
        dispatch(setChangePasswordPostStatus(RequestStatus.status.ERROR));
      });
  };
}

export function phoneLoginAction(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/phone_login/send/`;
  return function(dispatch) {
    dispatch(setPhoneLoginStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setPhoneLoginData(response.data));
        dispatch(setPhoneLoginStatus(RequestStatus.status.DONE));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Sprawdź wiadomości SMS' }
        });
      })
      .catch(error => {
        if (error.response && error.response.data)
          Object.keys(error.response.data).forEach(key => {
            dispatch({
              type: SNACKBAR_STATUS,
              payload: { status: true, content: error.response.data[key] }
            });
          });
        else if (error) {
          dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: 'Błąd logowania' }
          });
          console.error('Login error: \n', error);
        }
        dispatch(setPhoneLoginStatus(RequestStatus.status.ERROR));
      });
  };
}

export function phoneLoginConfirmationAction(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/phone_login/login/`;
  return function(dispatch) {
    dispatch(setPhoneLoginConfirmationStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setLoginStatus(RequestStatus.status.DONE));
        dispatch(setPhoneLoginConfirmationStatus(RequestStatus.status.DONE));
        dispatch(updateToken(response.data));
        dispatch(getAuthInfo());
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Zalogowano pomyślnie!' }
        });
      })
      .catch(error => {
        Object.keys(error.response.data).forEach(key => {
          dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: error.response.data[key] }
          });
        });
        dispatch(setPhoneLoginConfirmationStatus(RequestStatus.status.ERROR));
      });
  };
}

export function phoneVerificationSendAction(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/phone_verification/send/`;
  return function(dispatch) {
    dispatch(setPhoneVerificationSendStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setPhoneVerificationSendData(response.data));
        dispatch(setPhoneVerificationSendStatus(RequestStatus.status.DONE));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Sprawdź wiadomości SMS' }
        });
      })
      .catch(error => {
        Object.keys(error.response.data).forEach(key => {
          dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: error.response.data[key] }
          });
        });
        dispatch(setPhoneVerificationSendStatus(RequestStatus.status.ERROR));
      });
  };
}

export function phoneVerificationVerifyAction(data) {
  let url = process.env.REACT_APP_API_URL + `/auth/phone_verification/verify/`;
  return function(dispatch) {
    dispatch(setPhoneVerificationVerifyStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setPhoneVerificationVerifyStatus(RequestStatus.status.DONE));
        dispatch(getAuthInfo());
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Weryfikacja pozytywna!' }
        });
      })
      .catch(error => {
        Object.keys(error.response.data).forEach(key => {
          dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: error.response.data[key] }
          });
        });
        dispatch(setPhoneVerificationVerifyStatus(RequestStatus.status.ERROR));
      });
  };
}

const setLoginStatus = status => {
  return {
    type: LOGIN_REQUEST_FETCH_STATUS,
    payload: status
  };
};

export const setPhoneLoginStatus = status => {
  return {
    type: PHONE_LOGIN_REQUEST_FETCH_STATUS,
    payload: status
  };
};

export const setPhoneLoginConfirmationStatus = status => {
  return {
    type: PHONE_LOGIN_CONFIRMATION_REQUEST_FETCH_STATUS,
    payload: status
  };
};

export const setIsLoggedStatus = status => {
  return {
    type: SET_IS_LOGGED_STATUS,
    payload: status
  };
};

export const setUserFetchStatus = status => {
  return {
    type: SET_USER_FETCH_STATUS,
    payload: status
  };
};

export const setUserPatchStatus = status => {
  return {
    type: SET_USER_PATCH_STATUS,
    payload: status
  };
};

export const updateToken = (data?) => {
  if (data && data.key) {
    axios.defaults.headers.common['Authorization'] = `Token ${data.key}`;
    localStorage.setItem('token', data.key);
    return {
      type: TOKEN_UPDATE,
      payload: data.key
    };
  } else {
    axios.defaults.headers.common = {};
    localStorage.setItem('token', null);
    return {
      type: TOKEN_UPDATE,
      payload: null
    };
  }

  localStorage.setItem('token', data.key);
  return {
    type: TOKEN_UPDATE,
    payload: data.key
  };
};

export const setUserData = data => {
  return {
    type: SET_USER_DATA,
    payload: data
  };
};

export const setRegistrationPostStatus = status => {
  return {
    type: REGISTRATION_POST_STATUS,
    payload: status
  };
};

export const setChangePasswordPostStatus = status => {
  return {
    type: CHANGE_PASSWORD_POST_STATUS,
    payload: status
  };
};

export const setPasswordResetPostStatus = status => {
  return {
    type: SET_PASSWORD_RESET_POST_STATUS,
    payload: status
  };
};

export const setPhoneVerificationSendStatus = status => {
  return {
    type: PHONE_VERIFICATION_SEND_FETCH_STATUS,
    payload: status
  };
};

export const setPhoneVerificationSendData = data => {
  return {
    type: PHONE_VERIFICATION_SEND_DATA,
    payload: data
  };
};

export const setPhoneVerificationVerifyStatus = status => {
  return {
    type: PHONE_VERIFICATION_VERIFY_FETCH_STATUS,
    payload: status
  };
};

export const setPhoneLoginData = data => {
  return {
    type: PHONE_LOGIN_DATA,
    payload: data
  };
};
