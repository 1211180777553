import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, CardActionArea, CardMedia, CardContent, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useMaterialStyles = makeStyles({
  root: {
    maxWidth: '90vw',
    margin: '0 auto',
    marginTop: '15px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  content: {
    paddingLeft: 15,
    paddingRight: 15
  }
});

const GenericCard = props => {
  const { image, children, onClick = () => {}, onImageClick = () => {} } = props;
  const classes = useMaterialStyles();

  return (
    <Card variant="outlined" className={classes.root} onClick={onClick}>
      <CardActionArea>
        {image && <CardMedia className={classes.media} image={image} onClick={onImageClick} />}
        <CardContent className={classes.content}>{children}</CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(GenericCard);
