import axios from 'axios';
import { RequestStatus } from '../utils/RequestStatus';
import {
  SET_EVENT_DETAILS,
  SET_EVENT_LIST,
  SET_EVENT_LIST_FETCH_STATUS,
  SET_EVENT_USER_STATUS_POST_STATUS,
  SET_LAST_SELECTED_DATE
} from './events.actionTypes';
import { SNACKBAR_STATUS } from './utils.actionTypes';

export function getEventList(params) {
  let qs = Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&');
  let url = process.env.REACT_APP_API_URL + `/events/?${qs}`;
  return function(dispatch) {
    dispatch(setEventListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setEventList(response.data));
        dispatch(setEventListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setEventListFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

export const setEventList = status => {
  return {
    type: SET_EVENT_LIST,
    payload: status
  };
};

const setEventListFetchStatus = status => {
  return {
    type: SET_EVENT_LIST_FETCH_STATUS,
    payload: status
  };
};

//-------------------------------------------

export function getEventDetails(id) {
  let url = process.env.REACT_APP_API_URL + `/events/${id}/`;
  return function(dispatch) {
    dispatch(setEventListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setEventDetails(response.data));
        dispatch(setEventListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setEventListFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

export const setEventDetails = payload => {
  return {
    type: SET_EVENT_DETAILS,
    payload
  };
};

//-------------------------------------------

export function setEventUserStatus(id, status, refetchEvents = {}) {
  let url = process.env.REACT_APP_API_URL + `/events/${id}/set_status/`;
  return function(dispatch) {
    dispatch(setEventUserStatusPostStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, status)
      .then(response => {
        dispatch(setEventDetails(response.data));
        dispatch(setEventUserStatusPostStatus(RequestStatus.status.DONE));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Status zmieniono pomyślnie!' }
        });
        if (refetchEvents) dispatch(getEventList(refetchEvents));
      })
      .catch(error => {
        dispatch(setEventUserStatusPostStatus(RequestStatus.status.ERROR));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: { status: true, content: 'Problem ze zmianą statusu.' }
        });
      });
  };
}

export const setLastSelectedDate = payload => ({
  type: SET_LAST_SELECTED_DATE,
  payload
});

const setEventUserStatusPostStatus = status => {
  return {
    type: SET_EVENT_USER_STATUS_POST_STATUS,
    payload: status
  };
};
