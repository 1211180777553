import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SET_HEADER_PRIMARY_TEXT, SET_MENU_OPEN_STATUS } from '../../reducers/utils.actionTypes';
import { Field, Form } from 'react-final-form';
import MaskedInput from 'react-text-mask';
import FormGroup from '@material-ui/core/FormGroup';
import {
  phoneVerificationSendAction,
  phoneVerificationVerifyAction,
  setPhoneVerificationSendData,
  setPhoneVerificationSendStatus,
  setPhoneVerificationVerifyStatus
} from '../../reducers/auth.actions';
import { RequestStatus } from '../../utils/RequestStatus';
import { composeValidators, minLength, required } from '../../utils/formValidators';
import {
  FormStyles,
  LabelError,
  PrimaryButton,
  PrimaryFieldLabel,
  PrimaryInput
} from '../../utils/uiComponents';
import { getNewsList } from '../../routes';

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  color: white;
  display: grid;
  grid-template-columns: 70%;
  justify-content: center;
  align-items: center;
  height: calc(100vh - (56px + env(safe-area-inset-top)) - (56px + env(safe-area-inset-bottom)));
`;

const Paragraph = styled.p`
  color: ${props => props.theme.primaryColor};
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
`;

const FormGroupStyles = styled(FormGroup)`
  flex-wrap: initial !important;
  color: ${props => props.theme.primaryColor};
`;

function PhoneVerification(props) {
  const {
    phoneVerificationFetchStatus,
    user,
    phoneVerificationSendData,
    phoneVerificationVerifyFetchStatus
  } = props;
  let history = useHistory();
  const [step, setStep] = useState(1);
  const [verificationData, setVerificationData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Weryfikacja' });
  }, []);

  useEffect(() => {
    if (user) {
      if (user.is_phone_verified) history.push('news');
      else if (!user.phone) {
        dispatch({ type: SET_MENU_OPEN_STATUS, payload: true });
        setTimeout(() => {
          history.goBack();
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (phoneVerificationFetchStatus === RequestStatus.status.DONE) {
      setVerificationData(phoneVerificationSendData);
      setStep(2);
      dispatch(setPhoneVerificationSendStatus(RequestStatus.status.NULL));
      dispatch(setPhoneVerificationSendData(null));
    }
  }, [phoneVerificationFetchStatus]);

  useEffect(() => {
    if (phoneVerificationVerifyFetchStatus === RequestStatus.status.DONE) {
      dispatch(setPhoneVerificationVerifyStatus(RequestStatus.status.NULL));
      history.push(getNewsList());
    }
  }, [phoneVerificationFetchStatus]);

  const onSubmitPhoneVerificationSend = data => {
    dispatch(phoneVerificationSendAction(data));
  };

  const onSubmitPhoneVerificationVerify = data => {
    dispatch(phoneVerificationVerifyAction(data));
  };

  const resendSMSCode = data => {
    dispatch(phoneVerificationSendAction(data));
  };

  return (
    <>
      {user && step === 1 && (
        <Container>
          <Paragraph style={{ alignSelf: 'end' }}>
            Weryfikacja numeru telefonu pozwoli nam potwierdzić autentyczność wprowadzonych danych.
          </Paragraph>

          {/*<Form style={{marginTop: '30px'}}*/}
          <Form
            onSubmit={onSubmitPhoneVerificationSend}
            initialValues={{ phone: user.phone ? user.phone : '' }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <FormStyles onSubmit={handleSubmit} style={{ alignSelf: 'start' }}>
                <FormGroupStyles>
                  <PrimaryButton type="submit">Wyślij kod SMS</PrimaryButton>
                </FormGroupStyles>
              </FormStyles>
            )}
          />
        </Container>
      )}

      {verificationData && step === 2 && (
        <Container>
          <Paragraph style={{ alignSelf: 'end' }}>{`Wprowadź ${verificationData.label}`}</Paragraph>

          {/*<Form style={{marginTop: '30px'}}*/}
          <Form
            onSubmit={onSubmitPhoneVerificationVerify}
            initialValues={{ raw_code: '', id: verificationData.id }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <FormStyles onSubmit={handleSubmit} style={{ alignSelf: 'start' }}>
                <FormGroupStyles>
                  <PrimaryFieldLabel whiteBg>Kod</PrimaryFieldLabel>
                  <Field name="raw_code" validate={composeValidators(required, minLength(6))}>
                    {({ input, meta }) => (
                      <React.Fragment>
                        <MaskedInput
                          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                          placeholder="Kod"
                          id="raw_code-id"
                          guide={false}
                          render={(ref, props) => (
                            <PrimaryInput
                              type="number"
                              pattern="[0-9]*"
                              whiteBg
                              name="raw_code"
                              defaultValue={input.value}
                              ref={inputRef => ref(inputRef)}
                              placeholder={props.placeholder}
                              onChange={e => {
                                props.onChange(e);
                                input.onChange(e);
                              }}
                            />
                          )}
                        />
                        {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                      </React.Fragment>
                    )}
                  </Field>
                </FormGroupStyles>
                <FormGroupStyles>
                  <PrimaryButton type="submit">Zweryfikuj</PrimaryButton>
                </FormGroupStyles>

                {user && user.phone && (
                  <FormGroupStyles>
                    <div>
                      <div
                        onClick={() => {
                          resendSMSCode(user.phone);
                        }}
                      >
                        Wyślij SMS weryfikacyjny ponownie
                      </div>
                    </div>
                  </FormGroupStyles>
                )}
              </FormStyles>
            )}
          />
        </Container>
      )}
    </>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    user: state.auth.user,
    phoneVerificationFetchStatus: state.auth.phoneVerificationFetchStatus,
    phoneVerificationSendData: state.auth.phoneVerificationSendData,
    phoneVerificationVerifyFetchStatus: state.auth.phoneVerificationVerifyFetchStatus
  };
}

export default connect(mapStateToProps, {})(PhoneVerification);
