import { RequestStatus } from '../utils/RequestStatus';
import axios from 'axios';
import { setEventList } from './events.actions';
import {
  SET_KNOWLEDGE_BASE_FETCH_STATUS,
  UPDATE_KNOWLEDGE_BASE_LOGIN_LINK,
  UPDATE_KNOWLEDGE_BASE_VIDEOS
} from './knowledge-base.actionTypes';

export function getVideosList() {
  let url = process.env.REACT_APP_API_URL + `/knowledge_base/videos/`;
  return function(dispatch) {
    dispatch(setKnowledgeBaseFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        const playlistIdx = response.data.findIndex(video => video.context && video.context.includes('playlist'));
        let loginLink = null;
        if (playlistIdx) {
          loginLink = response.data[playlistIdx];
          response.data.splice(playlistIdx);
        }
        dispatch(setKnowledgeBaseVideosList(response.data));
        loginLink && dispatch(setKnowledgeBaseLink(loginLink));
        dispatch(setKnowledgeBaseFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setKnowledgeBaseFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

export const setKnowledgeBaseLink = videos => {
  return {
    type: UPDATE_KNOWLEDGE_BASE_LOGIN_LINK,
    payload: videos
  };
};

export const setKnowledgeBaseVideosList = videos => {
  return {
    type: UPDATE_KNOWLEDGE_BASE_VIDEOS,
    payload: videos
  };
};

const setKnowledgeBaseFetchStatus = status => {
  return {
    type: SET_KNOWLEDGE_BASE_FETCH_STATUS,
    payload: status
  };
};
