import React, { useState, forwardRef, useEffect } from 'react';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import withStyles from '@material-ui/core/styles/withStyles';
import { ExpansionPanelElement } from '../types/ExpansionPanelElement';
import { sizes } from '../theme';

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: sizes.expansionPanelElementHeight,
    '&$expanded': {
      minHeight: sizes.expansionPanelElementHeight
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

function CustomExpansionPanel(props) {
  const {
    listData,
    onExpandClick
  }: { listData: Array<ExpansionPanelElement>; onExpandClick: (title) => {} } = props;

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (title: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? title : false);
    if (onExpandClick) onExpandClick(title);
  };

  return (
    <>
      {listData &&
        listData.map((listElement, idx) => (
          <ExpansionPanel
            key={idx}
            square
            expanded={expanded === listElement.title}
            onChange={handleChange(listElement.title)}
          >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
              {listElement.title}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{listElement.content}</ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
    </>
  );
}

export default forwardRef((props: any, ref) => (
  <CustomExpansionPanel {...props} forwardRef={ref} />
));
