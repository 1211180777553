import React from 'react';
import styled from "styled-components";
import { connect } from "react-redux";


const Container = styled.div`
       padding:120px 60px;
       text-align: center;
       color:darkgray;
`;


const UnavailablePageComponent = (props) => {
  return (
    <Container>
      Ta funkcjonalność w aplikacji jest niedostępna. Moduł jest usługą odpłatną dla Jednostki Samorządu
      Terytorialnego. Jeśli uważasz, że funkcjonalność jest potrzebna pomóż nam z przekonaniem Twojego samorządu
      do jej aktywacji.
    </Container>
  );
};

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(UnavailablePageComponent);
