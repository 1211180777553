import { SET_SETTING_LIST, SET_SETTINGS_FETCH_STATUS, SET_SETTINGS_PATCH_STATUS } from "./settings.actionTypes";

export const INITIAL_STATE: any = {
  settingList: [],
  settingsFetchStatus: null,
  settingsPatchStatus: null,
};

export default function settings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SETTINGS_FETCH_STATUS:
      return {
        ...state,
        settingsFetchStatus: action.payload,
      };
    case SET_SETTINGS_PATCH_STATUS:
      return {
        ...state,
        settingsPatchStatus: action.payload
      };
    case SET_SETTING_LIST:
      return {
        ...state,
        settingList: action.payload
      };
    default:
      return state;
  }
}
