import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { Field, Form, FormSpy } from 'react-final-form';
import { postContactMessage } from '../../reducers/contact-messages.actions';
import { RequestStatus } from '../../utils/RequestStatus';
import UploadComponent from '../../components/UploadComponent/UploadComponent';
import { composeValidators, minLength, required } from '../../utils/formValidators';
import {
  AddPhotoSquare,
  FormContainer,
  FormGroupStyles,
  FormStyles,
  LabelError,
  PrimaryFieldLabel,
  PrimaryInput,
  PrimarySmallButton,
  PrimaryTextarea,
  StyledForm
} from '../../utils/uiComponents';
import BrowserLocation from '../../components/Location/BrowserLocation';
import Map from '../../components/Location/MapComponent';

const Container = styled.div`
  margin: 0 30px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 100%;
`;

const HeaderContainer = styled.div`
  text-align: center;
  margin: 5px 0 40px 0;
  font-size: 23px;
  text-transform: uppercase;
  display: block;
  padding-top: 15px;
  font-weight: bold;
`;

const HeaderLabel = styled.div`
  display: initial;
  border-bottom: 4px solid ${props => props.theme.secondaryColor};
  padding-bottom: 10px;
`;

const AddPhotoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 55px);
  grid-column-gap: 15px;
  margin-top: 10px;
  color: ${props => props.theme.secondaryColor};
`;

const AcceptLabel = styled.div`
  font-size: 13px;
`;

const SaveButtonContainer = styled.div`
  display: grid;
  justify-content: end;
`;

const FormContainerStyles = styled(FormContainer)`
  padding-bottom: 50px;
  grid-template-columns: 100%;
  position: relative;
`;

const LocationButtonField = styled.div`
  display: flex;
  margin: 0px;
  padding: 0px;
  height: 30px;
`;

function ContactMessageAdd(props) {
  const { user, contactMessagePostStatus } = props;
  let history = useHistory();

  const [photo, setPhoto] = useState(null);
  const [photosToSend, setPhotosToSend] = useState({});

  const dispatch = useDispatch();

  const onSubmit = data => {
    data.user_agent = navigator.userAgent;
    const query = new URLSearchParams(props.location.search);
    data.type = query.get('type');
    if (data.location && data.location.latitude && data.location.longitude) {
      data.latitude = _.round(data.location.latitude, 6);
      data.longitude = _.round(data.location.longitude, 6);
      delete data.location;
    }
    dispatch(postContactMessage(data, photosToSend));
  };

  useEffect(() => {
    setPhotosToSend(Object.assign({}, photosToSend, photo));
  }, [photo]);

  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Zgłoszenie' });
  }, []);

  useEffect(() => {
    if (contactMessagePostStatus === RequestStatus.status.DONE) {
      history.push(`/your-voice/contact-messages/confirmation`);
    }
  }, [contactMessagePostStatus]);

  return (
    <React.Fragment>
      <Container>
        <HeaderContainer>
          <HeaderLabel>Zgłoszenie</HeaderLabel>
        </HeaderContainer>

        {/*<FormContainer style={{    gridTemplateColumns: '80%'}}>*/}
        <FormContainerStyles>
          {/*<Form style={{margin: '30px'}}*/}
          <Form
            onSubmit={onSubmit}
            initialValues={{ username: user ? `${user.first_name} ${user.last_name}` : '' }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <StyledForm
                onSubmit={handleSubmit}
                style={{
                  gridTemplateColumns: '80%',
                  justifyContent: 'center'
                }}
              >
                <FormSpy subscription={{ values: true }} onChange={values => {}} />
                <FormGroupStyles>
                  <PrimaryFieldLabel whiteBg>Zgłaszający</PrimaryFieldLabel>
                  <Field name="username" validate={composeValidators(required)}>
                    {({ input, meta }) => (
                      <React.Fragment>
                        <PrimaryInput
                          whiteBg
                          placeholder="Imię i nazwisko"
                          type="text"
                          {...input}
                        />
                        {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                      </React.Fragment>
                    )}
                  </Field>
                </FormGroupStyles>

                <FormGroupStyles>
                  <PrimaryFieldLabel whiteBg>Temat</PrimaryFieldLabel>
                  <Field name="subject" validate={composeValidators(required)}>
                    {({ input, meta }) => (
                      <React.Fragment>
                        <PrimaryInput whiteBg type="text" placeholder="Temat" {...input} />
                        {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                      </React.Fragment>
                    )}
                  </Field>
                </FormGroupStyles>

                <FormGroupStyles>
                  <PrimaryFieldLabel whiteBg>Treść</PrimaryFieldLabel>
                  <Field name="message" validate={composeValidators(required, minLength(5))}>
                    {({ input, meta }) => (
                      <React.Fragment>
                        <PrimaryTextarea placeholder="Treść" {...input}></PrimaryTextarea>
                        {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                      </React.Fragment>
                    )}
                  </Field>
                </FormGroupStyles>

                {/*<FormGroup style={{flexWrap: 'noWrap'}}>*/}
                <FormGroupStyles>
                  <PrimaryFieldLabel whiteBg>Dodaj zdjęcie</PrimaryFieldLabel>
                  <AddPhotoContainer>
                    <AddPhotoSquare>
                      <UploadComponent imageKey={'image1'} callback={setPhoto} />
                    </AddPhotoSquare>
                    <AddPhotoSquare>
                      <UploadComponent imageKey={'image2'} callback={setPhoto} />
                    </AddPhotoSquare>
                    <AddPhotoSquare>
                      <UploadComponent imageKey={'image3'} callback={setPhoto} />
                    </AddPhotoSquare>
                  </AddPhotoContainer>
                </FormGroupStyles>

                <FormGroupStyles>
                  <PrimaryFieldLabel whiteBg style={{ marginBottom: '10px' }}>
                    Lokalizacja
                  </PrimaryFieldLabel>
                  <Field name="location">
                    {({ input, meta }) => (
                      <>
                        <Map {...input} isFullScreenWidth />
                        {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                      </>
                    )}
                  </Field>
                </FormGroupStyles>

                <FormGroupStyles>
                  <AcceptLabel>
                    Zapoznałem się z{' '}
                    <a
                      href="/i/regulamin/"
                      target="_blank"
                      style={{ color: 'inherit', fontWeight: 'bold' }}
                    >
                      regulaminem
                    </a>{' '}
                    tworzenia zgłoszenia.
                  </AcceptLabel>
                </FormGroupStyles>
                {/*<FormGroup>*/}
                <SaveButtonContainer>
                  <PrimarySmallButton type="submit">Wyślij</PrimarySmallButton>
                </SaveButtonContainer>
                {/*</FormGroup>*/}
              </StyledForm>
            )}
          />
        </FormContainerStyles>
      </Container>
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    user: state.auth.user,
    contactMessagePostStatus: state.contactMessages.contactMessagePostStatus
  };
}

export default connect(mapStateToProps, {})(ContactMessageAdd);
