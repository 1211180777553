import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import styled from 'styled-components';
import {
  getEventList,
  setEventList,
  setEventUserStatus,
  setLastSelectedDate
} from '../../reducers/events.actions';
import moment from 'moment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './../../../node_modules/@fullcalendar/core/main.css';
import './../../../node_modules/@fullcalendar/daygrid/main.css';
import { useHistory } from 'react-router-dom';
import plLocale from '@fullcalendar/core/locales/pl';
import { colors, sizes, fontSizes } from '../../theme';
import AbsoluteViewContainer from '../../components/AbsoluteViewContainer';
import StyledCalendarContainer from './StyledCalendarContainer';
import CalendarCard from './CalendarCard';
import { getEventDetails } from '../../routes';
import { useRefState } from '../../utils/customHooks';

const START_DATE_KEY = 'date__gte';
const END_DATE_KEY = 'date__lte';
const SELECTED_DATE_KEY = 'date__now';

const CalendarPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.primaryColor};
  padding-top: 18px;
`;

const CardsContainer = styled.div`
  height: 100%;
  margin-bottom: 20px;
`;

const NoEvents = styled.div`
  height: 300px;
  background: ${colors.whiteBgColor};
  text-align: center;
  line-height: 300px;
  font-weight: 600;
`;

function Calendar(props) {
  const { eventList, setEventUserStatus, setLastSelectedDate, lastSelectedDate } = props;

  const calendarRef = React.createRef();
  const isAnyEvent = useRef();

  const [preparedEvents, setPreparedEvents] = useState([]);
  const [storedDate, storedDateRef, setStoredDate] = useRefState(null);

  let history = useHistory();
  const dispatch = useDispatch();

  const dateClick = data => {
    if (data.dayEl.classList.contains('fc-other-month')) return;
    setStoredDate(data.dateStr);
  };

  const updateHighlight = _dateString => {
    const dateString = moment(_dateString).format('YYYY-MM-DD');
    const dayTags = document.getElementsByClassName('fc-day-top');
    let foundDayTag;
    // @ts-ignore
    for (let dayTag of dayTags) {
      if (!dayTag || !dayTag.classList) continue;
      if (dayTag.getAttribute('data-date') === dateString) foundDayTag = dayTag;
      if (dayTag.classList.contains('custom-highlight'))
        foundDayTag.classList.remove('custom-highlight');
    }
    foundDayTag && foundDayTag.classList && foundDayTag.classList.add('custom-highlight');
  };

  const changeSelectedEventDot = _dateString => {
    const dateString = moment(_dateString).format('YYYY-MM-DD');
    let allDotsBodies = document.querySelectorAll('.fc-content-skeleton tbody tr td'); //fc-content-skeleton tbody tr td
    let allDays = document.querySelectorAll('.fc-day-top'); //fc-day-top
    if (allDotsBodies.length !== allDays.length) return;
    // @ts-ignore
    let selectedDotIdx = 0;
    for (let [idx, day] of Object.entries(allDays)) {
      const date = day.getAttribute('data-date');
      if (date === dateString) {
        selectedDotIdx = +idx;
      }
      if (allDotsBodies[+idx]) allDotsBodies[+idx].classList.remove('custom-event-dot');
    }
    if (selectedDotIdx > 0) {
      allDotsBodies[selectedDotIdx].classList.add('custom-event-dot');
    }
  };

  const eventClick = data => {
    let clickedDate = moment(data.event.start).format('YYYY-MM-DD');
    if (clickedDate) setStoredDate(clickedDate);
  };

  const datesRender = data => {
    if (calendarRef && calendarRef.current) {
      const todayYearMoth = moment().format('YYYY-MM');
      const selectedYearMoth = moment(
        (calendarRef.current as any).getApi().view.currentStart
      ).format('YYYY-MM');

      const dateOnDiffMonth = moment(
        todayYearMoth === selectedYearMoth
          ? undefined
          : (calendarRef.current as any).getApi().view.currentStart
      ).format('YYYY-MM-DD');

      if (!moment(storedDate).isSame(dateOnDiffMonth)) {
        setStoredDate(dateOnDiffMonth);
        setPreparedEvents([]);
      }
    }
  };

  const handleCardClick = (data, event) => {
    let startDate = moment(storedDate)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment(storedDate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    history.push(`${getEventDetails()}/${event.id}`);
  };

  const changeEventUserStatus = (event, status) => {
    setEventUserStatus(
      event.id,
      { status: event.user_status === status ? null : status },
      getEventsListParams()
    );
  };

  const renderEvents = () => {
    let elements = preparedEvents
      .filter(calendarEvent => calendarEvent.date === moment(storedDate).format('YYYY-MM-DD'))
      .map((calendarEvent, idx) =>
        calendarEvent.objects.map((eventDetails, idx) => {
          isAnyEvent.current = idx;
          return (
            <CalendarCard
              key={idx}
              calendarEvent={eventDetails}
              onClick={data => handleCardClick(data, eventDetails)}
              changeEventUserStatus={changeEventUserStatus}
            />
          );
        })
      );
    return elements.length ? elements : <NoEvents>BRAK WYDARZEŃ W TYM DNIU</NoEvents>;
  };

  const getEventsListParams = () => ({
    date__gte: moment(storedDate)
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss'),
    date__lte: moment(storedDate)
      .endOf('month')
      .format('YYYY-MM-DD HH:mm:ss'),
    calendar: 1
  });

  const getDefaultCalendarDate = () => {
    return moment(storedDate).format('YYYY-MM-DD');
  };

  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Kalendarz' });
    const query = new URLSearchParams(props.location.search);
    let dateToSet = query.get(START_DATE_KEY) || moment();
    if (
      lastSelectedDate &&
      query.get(START_DATE_KEY) &&
      moment(lastSelectedDate).format('YYYY-MM') === moment(dateToSet).format('YYYY-MM')
    ) {
      dateToSet = lastSelectedDate;
    }
    setStoredDate(dateToSet);
    return () => {
      setLastSelectedDate(storedDateRef.current);
    };
  }, []);

  useEffect(() => {
    let preparedEvents = [];
    eventList.forEach(event => {
      let found = preparedEvents.find(
        x => x.date === moment(event.date * 1000).format('YYYY-MM-DD')
      );
      if (!found) {
        preparedEvents.push({
          date: moment(event.date * 1000).format('YYYY-MM-DD'),
          objects: [event],
          title: 1
        });
      } else {
        found.objects.push(event);
        found['title'] = found.objects.length;
      }
    });
    setPreparedEvents(preparedEvents);
  }, [eventList]);

  useEffect(() => {
    if (!storedDate) return;
    let newQueryParams = `${START_DATE_KEY}=${moment(storedDate)
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss')}&${END_DATE_KEY}=${moment(storedDate)
      .endOf('month')
      .format('YYYY-MM-DD HH:mm:ss')}`;
    var newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      newQueryParams;
    window.history.replaceState({ path: newUrl }, '', newUrl);
    dispatch(getEventList(getEventsListParams()));
  }, [storedDate]);

  useEffect(() => {
    if (!storedDate) return;
    let dateToSet = getDefaultCalendarDate();
    updateHighlight(dateToSet);
    changeSelectedEventDot(dateToSet);
  });

  return (
    <AbsoluteViewContainer bgColor={colors.backgroundColor}>
      <CalendarPartContainer>
        <StyledCalendarContainer>
          {!!preparedEvents && storedDate && (
            <FullCalendar
              defaultView="dayGridMonth"
              // @ts-ignore
              ref={calendarRef}
              locale={plLocale}
              dateClick={dateClick}
              eventClick={eventClick}
              height={'auto'}
              events={preparedEvents}
              defaultDate={getDefaultCalendarDate()}
              datesRender={datesRender}
              fixedWeekCount={false}
              header={{
                left: 'prev',
                center: 'title',
                right: 'next'
              }}
              plugins={[dayGridPlugin, interactionPlugin]}
            />
          )}
        </StyledCalendarContainer>
      </CalendarPartContainer>
      <CardsContainer>{renderEvents()}</CardsContainer>
    </AbsoluteViewContainer>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    eventList: state.events.eventList,
    lastSelectedDate: state.events.lastSelectedDate
  };
}

export default connect(mapStateToProps, { setEventUserStatus, setLastSelectedDate })(Calendar);
