import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import FormGroup from '@material-ui/core/FormGroup';

export const PrimaryButton = styled.button`
  border: 2px solid ${props => props.theme.secondaryColor};
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 50px;
  height: 55px;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 20px;
  margin: 15px 0;
  text-transform: uppercase;
`;

export const PrimarySmallButton = styled.button`
  border: ${props => (props.disabled ? 'none' : `2px solid ${props.theme.secondaryColor}`)};
  background-color: ${props => (props.disabled ? 'darkgray' : props.theme.secondaryColor)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  border-radius: 50px;
  height: 40px;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 20px;
  margin: 25px 0;
  width: 150px;
  user-select: none;
`;

export const PrimaryFlexButton = styled.button`
  border: ${props => (props.disabled ? 'none' : `2px solid ${props.theme.secondaryColor}`)};
  background-color: ${props => (props.disabled ? 'darkgray' : props.theme.secondaryColor)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  border-radius: 50px;
  height: 40px;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 20px;
  margin: 25px 0;
  user-select: none;
`;

export const SecondaryButton = styled.div`
  border: 2px solid ${props => props.theme.secondaryColor};
  border-radius: 50px;
  height: 55px;
  width: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 20px;
  margin: 10px 0;
  position: relative;
  font-weight: lighter;
`;

export const PrimaryInput = styled.input`
  border: 4px solid ${props => (props.disabled ? 'darkgray' : props.theme.secondaryColor)};
  border-radius: 50px;
  color: ${props =>
    props.disabled ? 'darkgray' : props.whiteBg ? props.theme.primaryColor : 'white'};
  height: 45px;
  background-color: transparent;
  font-size: 20px;
  padding: 0 25px;
  margin-top: 10px;
  //width:100%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(203, 203, 203) inset !important;
  }
  &::placeholder {
    color: ${props => (props.whiteBg ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.4)')};
  }
`;

export const LabelError = styled.div`
  color: ${props => props.theme.errorColor};
  margin-top: 3px;
`;

export const PrimaryTextarea = styled.textarea`
  border: 4px solid ${props => props.theme.secondaryColor};
  border-radius: 20px;
  height: 80px;
  background-color: transparent;
  font-size: 20px;
  padding: 10px 25px;
  margin-top: 10px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(203, 203, 203) inset !important;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

export const PrimaryFieldLabel = styled.div`
  color: ${props => (props.whiteBg ? props.theme.primaryColor : 'white')};
  font-size: 20px;
  font-weight: lighter;
`;

export const RequiredFieldMessage = styled.div`
  position: absolute;
  bottom: 10px;
  left: 30px;
  color: white;
`;

export const CloseIconStyle = styled(CloseIcon)`
  position: absolute;
  top: calc(15px + env(safe-area-inset-top));
  left: 15px;
  cursor: pointer;
  color: ${props => (props['data=whitebg'] ? 'black' : 'white')};
  z-index: 10;
`;

export const FormStyles = styled.form`
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: 100%;
`;

export const StyledForm = FormStyles;

export const AddPhotoSquare = styled.div`
  height: 50px;
  width: 50px;
  border: 3px solid ${props => props.theme.secondaryColor};
  border-radius: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.secondaryColor};
`;

export const FormContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const BoxButton = styled.div`
  height: 25px;
  width: 25px;
  border: 3px solid ${props => props.theme.secondaryColor};
  border-radius: 7px;
  position: relative;
  &.selected {
    &:before {
      position: absolute;
      top: 5px;
      right: 5px;
      bottom: 5px;
      left: 5px;
      content: '';
      background: ${props => (props.noFill ? '' : props.theme.secondaryColor)};
    }
  }
`;

export const FormGroupStyles = styled(FormGroup)`
  flex-wrap: initial !important;
`;
