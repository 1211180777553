import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import utils from './reducers/utils.reducer';
import auth from './reducers/auth.reducer';
import news from './reducers/news.reducer';
import events from './reducers/events.reducer';
import yourVoice from './reducers/your-voice.reducer';
import loader from './reducers/loader.reducer';
import logger from 'redux-logger';
import settings from './reducers/settings.reducer';
import contactMessages from './reducers/contact-messages.reducer';
import notifications from './reducers/notifications.reducer';
import weather from './reducers/weather.reducer';
import knowledgeBase from './reducers/knowledge-base.reducer';

const reducer = combineReducers({
  utils,
  auth,
  news,
  events,
  yourVoice,
  loader,
  settings,
  contactMessages,
  notifications,
  weather,
  knowledgeBase
});

const store = createStore(reducer, applyMiddleware(thunk, logger));

export default store;
