import styled from 'styled-components';
import React from 'react';

const ImageContainer = styled.div`
  position: relative;
  padding-bottom: 56.2%;
`;

const FullHeightImageContainer = styled.div`
  height: 100%;
  min-height: 100px;
`;

const FullWidthImageContainer = styled(ImageContainer)`
  @media only screen and (min-width: 800px) {
    max-height: 80vh;
    padding-bottom: 70vh;
  }
`;

const Image = styled.img`
  position: absolute;
  object-fit: scale-down;
  width: 100%;
  height: 100%;
`;

const FullHeightImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ResponsiveImage = function ResponsiveImageRatio16_9({ src, isFullWidth = false }) {
  return isFullWidth ? (
    <FullHeightImageContainer>
      <FullHeightImage src={src} />
    </FullHeightImageContainer>
  ) : (
    <ImageContainer>
      <Image src={src} />
    </ImageContainer>
  );
};

export default ResponsiveImage;
