import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { setOpenPhotoFullscreenStatus } from '../../reducers/utils.actions';
import { PrimarySmallButton } from '../../utils/uiComponents';
import {
  AnswerButton,
  AnswerColumn,
  AnswerColumnExtended,
  AnswerContainer,
  AnswerContainerRow,
  AnswerElement,
  AnswerRow,
  AnswerRowExtended,
  ColoredProgress,
  DeclarantLabel,
  DeclarantNameLabel,
  Description,
  ExtendedSurveyContainer,
  Gallery,
  GalleryImg,
  GalleryObj,
  HeaderContainer,
  HeaderLabel,
  QuestionContainer,
  QuestionContainerExtended,
  SendButtonContainer
} from './QuestionComponentParts';
import { QuestionCompGallery } from './QuestionCompGallery';
import Checkbox from '../Checkbox';

const StyledPrimarySmallButton = styled(PrimarySmallButton)`
  margin: 0 auto;
`;

const QuestionComponent = props => {
  const { headerLabel, declarantLabel, details, setAnswer, openPhotoFullscreenStatus } = props;
  const dispatch = useDispatch();

  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [detailsObj, setDetailsObj] = useState(null);

  const setSelectedAnswer = obj => {
    let cloned = [...selectedAnswers];
    let foundedQuestion = cloned.find(x => x.question_id === obj.question_id);

    if (foundedQuestion) {
      if (foundedQuestion.answer_id !== obj.answer_id) {
        foundedQuestion.answer_id = obj.answer_id;
        setSelectedAnswers(cloned);
      } else {
        setSelectedAnswers(
          cloned.filter(
            x => !(x.question_id === obj.question_id && foundedQuestion.answer_id === obj.answer_id)
          )
        );
      }
    } else {
      cloned.push(obj);
      setSelectedAnswers(cloned);
    }
  };

  const sendAnswers = () => {
    let response = selectedAnswers.map(x => x.answer_id);
    setAnswer(response);
  };

  const checkSelectedAnswer = (question, answer) => {
    let response = !!selectedAnswers.find(
      x => x.question_id === question.id && x.answer_id === answer.id
    );
    return response;
  };

  const isFormCompleted = () => {
    let completed = true;
    detailsObj.questions.forEach(q => {
      if (q.is_required) {
        if (!selectedAnswers.find(x => x.question_id === q.id)) {
          completed = false;
        }
      }
    });
    return completed;
  };

  useEffect(() => {}, [selectedAnswers]);

  useEffect(() => {
    if (details.score && details.score.length) {
      details.score.forEach(scoreObj => {
        scoreObj.question.answers.forEach(answer => {
          answer.percent = scoreObj.total_votes
            ? Math.round((answer.votes / scoreObj.total_votes) * 100)
            : 0;
        });
      });
    }
    setDetailsObj(details);

    const answers = [];
    if (details.hasOwnProperty('submissions') && details.submissions.length) {
      details.submissions[0].answers.forEach(answer => {
        answers.push({ question_id: answer.question_id, answer_id: answer.id });
      });
      setSelectedAnswers(answers);
    }
    if (details.images && details.images.length) {
      details.images = details.images.map(x => {
        return { source: x };
      });
    }
  }, [details]);

  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderLabel>{headerLabel}</HeaderLabel>
      </HeaderContainer>

      {detailsObj && (
        <React.Fragment>
          {declarantLabel && (
            <React.Fragment>
              <DeclarantLabel>{declarantLabel}</DeclarantLabel>
              <DeclarantNameLabel>{detailsObj.author}</DeclarantNameLabel>
            </React.Fragment>
          )}
          {detailsObj.description && (
            <Description>
              {detailsObj.description.split('\n').map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
            </Description>
          )}

          {detailsObj.images && (
            <QuestionCompGallery
              detailsObj={detailsObj}
              onImgClick={idx => {
                dispatch(setOpenPhotoFullscreenStatus(+idx));
              }}
              openPhotoFullscreenStatus={openPhotoFullscreenStatus}
              onModalClose={() => dispatch(setOpenPhotoFullscreenStatus(null))}
            />
          )}

          {/* Iterate over questions */}
          {detailsObj.questions &&
            detailsObj.questions.map(
              (question, i) => (
                ////////////////////////////
                <React.Fragment key={question.id}>
                  {/*// UI for simplesurvey type  - START //*/}
                  {detailsObj.survey_type === 'surveys.simplesurvey' && (
                    <React.Fragment>
                      <QuestionContainer>{question.question}</QuestionContainer>

                      {/* Display available answers */}
                      {detailsObj.can_vote && !detailsObj.submissions.length && (
                        <React.Fragment>
                          <AnswerRow>
                            {question.answers.length &&
                              question.answers.map(answer => (
                                <React.Fragment key={answer.id}>
                                  <AnswerContainer>
                                    <AnswerButton
                                      yes={answer.text == 'Tak'}
                                      selected={
                                        checkSelectedAnswer(question, answer) ? 'selected' : ''
                                      }
                                      onClick={() =>
                                        setSelectedAnswer({
                                          question_id: question.id,
                                          answer_id: answer.id
                                        })
                                      }
                                    >
                                      {answer.text}
                                    </AnswerButton>
                                  </AnswerContainer>
                                </React.Fragment>
                              ))}
                          </AnswerRow>
                        </React.Fragment>
                      )}

                      {/* Display answers */}
                      {(!detailsObj.can_vote || !!detailsObj.submissions.length) && (
                        <AnswerColumn>
                          {detailsObj.score &&
                            detailsObj.score.length &&
                            detailsObj.score[i].question &&
                            detailsObj.score[i].question.answers.map(answer => (
                              <AnswerContainerRow key={answer.id}>
                                <AnswerButton
                                  yes={answer.text == 'Tak'}
                                  selected={checkSelectedAnswer(question, answer) ? 'selected' : ''}
                                >
                                  {' '}
                                  {answer.text}
                                </AnswerButton>
                                <ColoredProgress
                                  progress={answer.percent}
                                  yes={answer.text == 'Tak'}
                                >
                                  {answer.percent}%
                                </ColoredProgress>
                              </AnswerContainerRow>
                            ))}
                        </AnswerColumn>
                      )}
                    </React.Fragment>
                  )}
                  {/*// UI for simplesurvey type  - END //*/}

                  {/*// UI for extendedsurvey type  - START //*/}
                  {detailsObj.survey_type === 'surveys.extendedsurvey' && (
                    <ExtendedSurveyContainer>
                      <QuestionContainerExtended>
                        {i + 1}. {question.question}
                      </QuestionContainerExtended>

                      {/* Display available answers */}
                      {detailsObj.can_vote && !detailsObj.submissions.length && (
                        <AnswerColumnExtended>
                          {question.answers.length &&
                            question.answers.map(answer => (
                              <AnswerElement key={answer.id}>
                                <Checkbox
                                  onClick={() =>
                                    setSelectedAnswer({
                                      question_id: question.id,
                                      answer_id: answer.id
                                    })
                                  }
                                  selected={!!checkSelectedAnswer(question, answer)}
                                />
                                <div
                                  onClick={() =>
                                    setSelectedAnswer({
                                      question_id: question.id,
                                      answer_id: answer.id
                                    })
                                  }
                                >
                                  {answer.text}
                                </div>
                              </AnswerElement>
                            ))}
                        </AnswerColumnExtended>
                      )}

                      {/* Display answers */}
                      {(!detailsObj.can_vote || !!detailsObj.submissions.length) && (
                        <AnswerColumnExtended>
                          {detailsObj.score &&
                            detailsObj.score.length &&
                            detailsObj.score[i].question &&
                            detailsObj.score[i].question.answers.map(answer => (
                              <AnswerRowExtended key={answer.id}>
                                <AnswerElement>
                                  <Checkbox
                                    selected={!!checkSelectedAnswer(question, answer)}
                                    disable={true}
                                  />
                                  <div>{answer.text}</div>
                                </AnswerElement>
                                <div>{answer.percent}%</div>
                              </AnswerRowExtended>
                            ))}
                        </AnswerColumnExtended>
                      )}
                    </ExtendedSurveyContainer>
                  )}

                  {/*// UI for extendedsurvey type  - END //*/}
                </React.Fragment>
              )
              ////////////////////////////
            )}
          {detailsObj && detailsObj.can_vote && (
            <SendButtonContainer>
              <StyledPrimarySmallButton disabled={!isFormCompleted()} onClick={() => sendAnswers()}>
                Wyślij
              </StyledPrimarySmallButton>
            </SendButtonContainer>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state, otherProps) {
  return {
    openPhotoFullscreenStatus: state.utils.openPhotoFullscreenStatus
  };
}

export default connect(mapStateToProps, {
  setOpenPhotoFullscreenStatus
})(QuestionComponent);
