import React from 'react';
import Loadable from 'react-loadable';
import VotingList from './containers/VotingList/VotingList';
import WeatherForecast from './containers/WeatherForecast/WeatherForecast';
import EventsList from './containers/EventsList/EventsList';
import NewsList from './containers/NewsList/NewsList';
import NewsDetails from './containers/NewsDetails/NewsDetails';
import PetitionDetails from './containers/PetitionDetails/PetitionDetails';
import PetitionConfirmation from './containers/PetitionConfirmation/PetitionConfirmation';
import OpinionPollDetails from './containers/OpinionPollDetails/OpinionPollDetails';
import OpinionPollConfirmation from './containers/OpinionPollConfirmation/OpinionPollConfirmation';
import PetitionAdd from './containers/PetitionAdd/PetitionAdd';
import SurveyConfirmation from './containers/SurveyConfirmation/SurveyConfirmation';
import YourVoiceList from './containers/YourVoiceList/YourVoiceList';
import QuestionnaireDetails from './containers/QuestionnaireDetails/QuestionnaireDetails';
import QuestionnaireConfirmation from './containers/QuestionnaireConfirmation/QuestionnaireConfirmation';
import WeatherList from './containers/WeatherList/WeatherList';
import SmugAlert from './containers/SmugAlert/SmugAlert';
import SettingsList from './containers/SettingsList/SettingsList';
import ContactMessageAdd from './containers/ContactMessageAdd/ContactMessageAdd';
import ContactMessageConfirmation from './containers/ContactMessageConfirmation/ContactMessageConfirmation';
import Calendar from './containers/Calendar';
import PhoneVerification from './containers/PhoneVerification/PhoneVerification';
import EventsDetails from './containers/EventsDetails';
import KnowledgeBase from './containers/KnowledgeBase';

// function Loading() {
//   return <div></div>;
// }
//
// const VotingList = Loadable({
//   loader: () => import('./containers/VotingList'),
//   loading: Loading
// });

export const getNewsList = () => '/informator';
export const getWeatherList = () => '/weather';
export const getEventsList = () => '/events';
export const getEventDetails = () => '/event';
export const getYourVoiceList = () => '/your-voice';
export const getSettingsList = () => '/settings';
export const getHelpPage = () => '/help';
export const getCalendar = () => '/calendar';
export const getPhoneVerification = () => '/phone-verification';
export const getKnowledgeBase = () => '/knowledge-base';

const routes = [
  {
    path: `${getNewsList()}`,
    exact: true,
    name: 'NewsList',
    component: NewsList
  },
  {
    path: `${getNewsList()}/:id`,
    exact: true,
    name: 'NewsDetail',
    component: NewsDetails
  },
  {
    path: `${getWeatherList()}`,
    exact: true,
    name: 'WeatherList',
    component: WeatherList
  },
  {
    path: `${getWeatherList()}/forecast`,
    exact: true,
    name: 'WeatherForecast',
    component: WeatherForecast
  },
  {
    path: `${getWeatherList()}/smug-alert`,
    exact: true,
    name: 'SmugAlert',
    component: SmugAlert
  },
  {
    path: `${getEventsList()}`,
    exact: true,
    name: 'EventList',
    component: EventsList
  },
  {
    path: `${getEventDetails()}/:id`,
    exact: true,
    name: 'EventDetails',
    component: EventsDetails
  },
  {
    path: `${getYourVoiceList()}`,
    exact: true,
    name: 'YourVoiceList',
    component: YourVoiceList
  },
  {
    path: `${getYourVoiceList()}/votings`,
    exact: true,
    name: 'VotingList',
    component: VotingList
  },
  {
    path: `${getYourVoiceList()}/petitions/add`,
    exact: true,
    name: 'PetitionAdd',
    component: PetitionAdd
  },
  {
    path: `${getYourVoiceList()}/petitions/:id/confirmation`,
    exact: true,
    name: 'PetitionConfirmation',
    component: PetitionConfirmation
  },
  {
    path: `${getYourVoiceList()}/petitions/:id`,
    exact: true,
    name: 'PetitionDetails',
    component: PetitionDetails
  },
  {
    path: `${getYourVoiceList()}/questionnaire/:id`,
    exact: true,
    name: 'QuestionnaireDetails',
    component: QuestionnaireDetails
  },
  {
    path: `${getYourVoiceList()}/questionnaire/:id/confirmation`,
    exact: true,
    name: 'QuestionnaireConfirmation',
    component: QuestionnaireConfirmation
  },
  {
    path: `${getYourVoiceList()}/opinion-polls/:id/confirmation`,
    exact: true,
    name: 'OpinionPollConfirmation',
    component: OpinionPollConfirmation
  },
  {
    path: `${getYourVoiceList()}/opinion-polls/:id`,
    exact: true,
    name: 'OpinionPollDetails',
    component: OpinionPollDetails
  },
  {
    path: `${getYourVoiceList()}/contact-messages/add`,
    exact: true,
    name: 'ReportAdd',
    component: ContactMessageAdd
  },
  {
    path: `${getYourVoiceList()}/contact-messages/confirmation`,
    exact: true,
    name: 'ReportConfirmation',
    component: ContactMessageConfirmation
  },
  {
    path: `${getYourVoiceList()}/surveys/:id/confirmation`,
    exact: true,
    name: 'SurveyConfirmation',
    component: SurveyConfirmation
  },
  {
    path: `${getSettingsList()}/`,
    exact: true,
    name: 'SettingsList',
    component: SettingsList
  },
  {
    path: `${getCalendar()}/`,
    exact: true,
    name: 'Calendar',
    component: Calendar
  },
  {
    path: `${getPhoneVerification()}/`,
    exact: true,
    name: 'Phone Verification',
    component: PhoneVerification
  },
  {
    path: `${getKnowledgeBase()}/`,
    exact: true,
    name: 'Knowledge Base',
    namePl: 'Baza Wiedzy',
    component: KnowledgeBase
  },
  // redirects
  {
    path: '/news',
    exact: true,
    redirect: `${getNewsList()}/`
  },
  {
    path: `/news/:id`,
    exact: true,
    redirect: `${getNewsList()}/:id`
  }
];
export default routes;
