import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import BrowserLocation from './BrowserLocation';
import { defaultLatLng } from '../../app.constants';
import { useRefState } from '../../utils/customHooks';

const ComponentContainer = styled.div`
  height: 28rem;
`;

const MapContainer = styled.div`
  height: 28rem;
  position: relative;
  @media (max-width: 950px) {
    ${props =>
      props.isFullScreenWidth
        ? `width: 100vw; position: absolute; 
      left: 0px; margin-left: -30px;`
        : ''}
  }
`;

const MapControlsContainer = styled.div`
  position: absolute;
  top: 0px;
  right: ${props => (props.isFullScreenButton ? 45 : 0)}px;
  max-width: 50%;
  margin: 10px;
  margin-right: ${props => (props.isFullScreenButton ? 10 : 0)}px;
  display: flex;

  @media (max-width: 330px) {
    top: 45px;
    right: 0px;
    max-width: none;
  }
`;

const MapLeftControlsContainer = styled.div`
  position: absolute;
  top: 45px;
  left: 0px;
  max-width: 50%;
  margin: 10px;
  display: flex;
`;

const StyledAutocomplete = styled(Autocomplete)`
  border: 0px;
  border-radius: 2px;
  height: 38px;
  padding-left: 16px;
  font-size: 18px;

  @media (max-width: 530px) and (min-width: 331px) {
    display: none;
  }
`;

const StyledLeftAutocomplete = styled(Autocomplete)`
  border: 0px;
  border-radius: 2px;
  height: 38px;
  padding-left: 16px;
  font-size: 18px;
  display: none;

  @media (max-width: 530px) and (min-width: 331px) {
    display: block;
    margin-left: 0px;
  }
`;

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
Geocode.enableDebug();

const formAddress = address => {
  return address.street + ' ' + address.postcode + ' ' + address.city + ' ' + address.country;
};

const GoogleMapComponent = withScriptjs(
  withGoogleMap((props: any) => {
    return (
      <GoogleMap
        defaultZoom={12}
        center={props.coordinates || defaultLatLng}
        onClick={event => {
          props.onChange({ latitude: event.latLng.lat(), longitude: event.latLng.lng() });
        }}
        onIdle={props.onLoad}
        onDrag={props.setFocus}
      >
        {props.children}
      </GoogleMap>
    );
  })
);

const MapWithMarker = props => {
  return (
    <GoogleMapComponent {...props} coordinates={props.coordinates}>
      <MapControlsContainer
        className="controls-container"
        isFullScreenButton={props.isFullScreenButton}
      >
        <BrowserLocation onChange={props.onChange} icon={true} />
        <StyledAutocomplete
          onPlaceSelected={place => {
            if (place && place.geometry)
              props.onChange({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
              });
          }}
          types={['address']}
          componentRestrictions={{ country: 'pl' }}
          isfullscreenbutton={String(props.isFullScreenButton)}
        />
      </MapControlsContainer>
      <MapLeftControlsContainer className="controls-left-container">
        <StyledLeftAutocomplete
          onPlaceSelected={place => {
            if (place && place.geometry)
              props.onChange({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
              });
          }}
          types={['address']}
          componentRestrictions={{ country: 'pl' }}
          isfullscreenbutton={String(props.isFullScreenButton)}
        />
      </MapLeftControlsContainer>
      {props.coordinates && <Marker position={props.coordinates} />}
    </GoogleMapComponent>
  );
};

const MapComponent = props => {
  const { value: location, address, onChange, isFullScreenWidth } = props;

  const [isFullScreenButton, isFullScreenButtonRef, setIsFullScreenButton] = useRefState(null);

  const [coordinates, setCoordinates] = useState(
    location && location.latitude
      ? { lat: location.latitude, lng: location.longitude }
      : defaultLatLng
  );

  const [isMapActive, setMapActive] = useState(false);

  const onMapLoad = event => {
    if (isFullScreenButtonRef.current !== null) return;
    setTimeout(() => {
      const fullScreenButton = document.getElementsByClassName('gm-fullscreen-control');
      setIsFullScreenButton(!!fullScreenButton.length);
    }, 3000);
  };

  const setFocus = event => {
    // to prevent scrolling page to the last active input
    // @ts-ignore
    document.activeElement.blur();
  };

  useEffect(() => {
    if (!location) {
      setMapActive(true);
    }
  }, []);

  useEffect(() => {
    if (location && location.latitude)
      setCoordinates({ lat: location.latitude, lng: location.longitude });
  }, [location]);

  // if will need changes to base on address
  useEffect(() => {
    if (address && Object.keys(address).length >= 4) {
      Geocode.fromAddress(formAddress(address)).then(response => {
        const { lat, lng } = response.results[0].geometry.location;
        setCoordinates({ lat, lng });
        setMapActive(true);
        onChange({ lat, lon: lng });
      });
    }
  }, [address]);

  return isMapActive ? (
    <ComponentContainer>
      <MapWithMarker
        isMarkerShown={true}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?${
          process.env.REACT_APP_GOOGLE_MAP_KEY ? 'key=' + process.env.REACT_APP_GOOGLE_MAP_KEY : ''
        }&v=3.exp&libraries=geometry,drawing,places`}
        containerElement={
          <MapContainer className="map-container" isFullScreenWidth={isFullScreenWidth} />
        }
        loadingElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        coordinates={coordinates}
        onChange={onChange}
        onLoad={onMapLoad}
        isFullScreenButton={isFullScreenButton}
        setFocus={setFocus}
      />
    </ComponentContainer>
  ) : (
    <></>
  );
};

export default MapComponent;
