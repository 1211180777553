import { SET_OPEN_PHOTO_FULLSCREEN_STATUS, SET_VOTING_LIST_TAB_INDEX, SNACKBAR_STATUS } from "./utils.actionTypes";

export function hideSnackBar(data) {
  return function (dispatch) {
    dispatch({type: SNACKBAR_STATUS, payload: {status: false, content: null}})
  };
}

export const setVotingListTabIndex = index => {
  return {
    type: SET_VOTING_LIST_TAB_INDEX,
    payload: index
  };
};

export const setOpenPhotoFullscreenStatus = status => {
  return {
    type: SET_OPEN_PHOTO_FULLSCREEN_STATUS,
    payload: status
  };
};
