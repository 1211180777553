import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, sizes } from '../theme';
import Browser from '../utils/Browser';

const AbsoluteViewContainer = styled.div`
  position: absolute;
  top: ${sizes.navbarHeight};
  left: 0;
  right: 0;
  margin-bottom: ${sizes.bottomNavHeight};
  background-color: ${props => props.bgColor || colors.whiteBgColor};
  // to fix missing pixel over bottom nav when content is short
  min-height: 101%;

  @media only screen and (min-width: 600px) {
    top: ${sizes.navbarHeightDesktop};
  }
`;

const AbsoluteViewContent = styled.div`
  max-width: ${sizes.maxContentWidthPxVal}px;
  margin: 0 auto;
  min-height: 100%;
`;

const BottomSpaceForSafari = styled.div`
  height: ${sizes.bottomNavHeight};
`;

const TextContent = styled.div`
  max-width: ${sizes.maxContentWidthVuVal}vw;
  margin: 18px auto 0 auto;
`;

function ViewContainer(props) {
  const { children } = props;

  const childrenContainerRef = useRef();

  const [isScrollableContent, setIsScrollableContent] = useState(false);

  const updateIsScrollableContent = () => {
    if (!childrenContainerRef || !childrenContainerRef.current) return;
    // @ts-ignore
    const boundingRect = childrenContainerRef.current.getBoundingClientRect();
    const contentHeight = boundingRect.height + boundingRect.top;
    const availableHeight = sizes.getSafariWindowHeight() - sizes.bottomNavHeightPxVal;
    setIsScrollableContent(contentHeight > availableHeight);
    return contentHeight > availableHeight;
  };

  useEffect(() => {
    if (Browser.isBottomSpaceNeeded()) updateIsScrollableContent();
  });

  return (
    <AbsoluteViewContainer {...props}>
      <AbsoluteViewContent ref={childrenContainerRef} className={'absolute-view-content'}>
        {children}
      </AbsoluteViewContent>
      {isScrollableContent ? <BottomSpaceForSafari /> : <></>}
    </AbsoluteViewContainer>
  );
}

export { TextContent };
export default ViewContainer;
