import { connect, useDispatch } from 'react-redux';
import EventsDetails from './EventsDetails';
import { setNavTitle } from '../../reducers/nav.actions';
import {
  getEventDetails,
  setEventUserStatus,
  setEventDetails
} from '../../reducers/events.actions';

function mapStateToProps(state, otherProps) {
  return {
    event: state.events.eventDetails
  };
}

export default connect(mapStateToProps, {
  setNavTitle,
  getEventDetails,
  setEventUserStatus,
  setEventDetails,
})(EventsDetails);
