import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import UploadComponent from '../../components/UploadComponent/UploadComponent';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { birthDateValidator, composeValidators, required } from '../../utils/formValidators';
import {
  AddPhotoSquare,
  FormContainer,
  FormGroupStyles,
  FormStyles,
  LabelError,
  PrimaryButton,
  PrimaryFieldLabel,
  PrimaryInput,
  RequiredFieldMessage
} from '../../utils/uiComponents';
import Checkbox from '../../components/Checkbox';
import {userAgreement} from "../Login/register.constants";

const ProfileContainer = styled.div`
  padding: 50px 0;
  justify-self: center;
  grid-template-columns: 80%;
  justify-content: center;
  position: relative;
`;

const AddPhotoContainer = styled.div`
  display: grid;
  color: white;
  grid-template-columns: 1fr 3fr;
  align-items: center;
`;

const MainHeader = styled.div`
  color: white;
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

const ChangePasswordButton = styled.button`
  border: 2px solid ${props => props.theme.secondaryColor};
  background-color: ${props => props.theme.primaryColor};
  border-radius: 50px;
  height: 35px;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 15px;
  text-transform: uppercase;
  margin: 20px 30px 0 30px;
`;

const AdditionalButton = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 40px auto;
  color: white;
`;

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy', {
  maxYear: new Date().getFullYear()
});

function Profile(props) {
  const { userData, setTab, onSubmit } = props;

  const [photo, setPhoto] = useState(null);
  const [photosToSend, setPhotosToSend] = useState({});

  useEffect(() => {
    setPhotosToSend(Object.assign({}, photosToSend, photo));
  }, [photo]);

  useEffect(() => {}, [photosToSend]);

  const submit = values => {
    onSubmit(values, photosToSend);
  };

  return (
    <ProfileContainer>
      <MainHeader> Mój profil</MainHeader>
      {/*<Form style={{margin: '30px'}}*/}
      <Form
        onSubmit={submit}
        initialValues={userData}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          }
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <FormStyles
            onSubmit={handleSubmit}
            style={{
              gridTemplateColumns: '80%',
              justifyContent: 'center'
            }}
          >
            <FormGroupStyles>
              <AddPhotoContainer>
                <div>
                  <AddPhotoSquare>
                    <UploadComponent imageKey={'photo'} data={userData} callback={setPhoto} />
                  </AddPhotoSquare>
                  {/*<AddPhotoSquare>*/}
                  {/*    <AddOutlinedIcon style={{fontSize: 35}}></AddOutlinedIcon>*/}
                  {/*</AddPhotoSquare>*/}
                </div>
                <div>Zdjęcie</div>
              </AddPhotoContainer>
            </FormGroupStyles>
            <FormGroupStyles>
              <PrimaryFieldLabel>Imię*</PrimaryFieldLabel>
              <Field name="first_name" validate={composeValidators(required)}>
                {({ input, meta }) => (
                  <React.Fragment>
                    <PrimaryInput type="text" placeholder={'Imię'} {...input} />
                    {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                  </React.Fragment>
                )}
              </Field>
            </FormGroupStyles>

            <FormGroupStyles>
              <PrimaryFieldLabel>Nazwisko*</PrimaryFieldLabel>
              <Field name="last_name" validate={composeValidators(required)}>
                {({ input, meta }) => (
                  <React.Fragment>
                    <PrimaryInput type="text" placeholder={'Nazwisko'} {...input} />
                    {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                  </React.Fragment>
                )}
              </Field>
            </FormGroupStyles>

            <FormGroupStyles>
              <PrimaryFieldLabel>Data urodzenia</PrimaryFieldLabel>
              <Field name="birth_date" validate={composeValidators(birthDateValidator)}>
                {({ input, meta }) => (
                  <React.Fragment>
                    <MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      pipe={autoCorrectedDatePipe}
                      placeholder="Data urodzenia (dd/mm/rrrr)"
                      keepCharPositions={true}
                      id="birth-date-id"
                      render={(ref, props) => (
                        <PrimaryInput
                          name="birth_date"
                          ref={inputRef => ref(inputRef)}
                          onChange={e => {
                            props.onChange(e);
                            input.onChange(e);
                          }}
                          defaultValue={input.value}
                          placeholder={props.placeholder}
                        />
                      )}
                    />
                    {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                  </React.Fragment>
                )}
              </Field>
            </FormGroupStyles>

            <FormGroupStyles>
              <PrimaryFieldLabel>Telefon*</PrimaryFieldLabel>
              <Field name="phone" validate={composeValidators(required)}>
                {({ input, meta }) => (
                  <React.Fragment>
                    <MaskedInput
                      //@ts-ignore
                      mask={s => ['+'].concat(Array.from(s).map(() => /[0-9]/i))}
                      placeholder="Telefon"
                      id="phone-id"
                      guide={false}
                      render={(ref, props) => (
                        <PrimaryInput
                          name="phone"
                          defaultValue={input.value}
                          ref={inputRef => ref(inputRef)}
                          placeholder={props.placeholder}
                          onChange={e => {
                            props.onChange(e);
                            input.onChange(e);
                          }}
                        />
                      )}
                    />
                    {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                  </React.Fragment>
                )}
              </Field>
            </FormGroupStyles>
            <FormGroupStyles>
              <PrimaryFieldLabel>E-mail</PrimaryFieldLabel>
              <Field name="email">
                {({ input }) => <PrimaryInput disabled type="email" {...input} />}
              </Field>
            </FormGroupStyles>

            <FormGroupStyles style={{ marginTop: '10px' }}>
              <Field name="is_resident">
                {({ input, meta }) => (
                  <React.Fragment>
                    <AdditionalButton>
                      <Checkbox
                        onClick={() => {
                          form.mutators.setValue('is_resident', !values.is_resident);
                        }}
                        selected={!!values.is_resident}
                      />
                      {userAgreement}
                    </AdditionalButton>
                  </React.Fragment>
                )}
              </Field>
            </FormGroupStyles>

            <FormGroupStyles>
              <PrimaryButton>Zapisz</PrimaryButton>
            </FormGroupStyles>
            <FormGroupStyles>
              <ChangePasswordButton type={'button'} onClick={() => setTab('change-password')}>
                Zmiana hasła
              </ChangePasswordButton>
            </FormGroupStyles>
          </FormStyles>
        )}
      />

      <RequiredFieldMessage>* Pola obowiązkowe</RequiredFieldMessage>
    </ProfileContainer>
  );
}

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(mapStateToProps, {})(Profile);
