import { RequestStatus } from "../utils/RequestStatus";
import axios from "axios";
import { SET_SETTING_LIST, SET_SETTINGS_FETCH_STATUS } from "./settings.actionTypes";
import { SNACKBAR_STATUS } from "./utils.actionTypes";

export function getSettingList(data) {
  let url = process.env.REACT_APP_API_URL + `/notifications/`;
  return function (dispatch) {
    dispatch(setSettingListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setSettingList(response.data));
        dispatch(setSettingListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setSettingListFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

const setSettingList = status => {
  return {
    type: SET_SETTING_LIST,
    payload: status
  };
};

const setSettingListFetchStatus = status => {
  return {
    type: SET_SETTINGS_FETCH_STATUS,
    payload: status
  };
};


export function patchSettingList(data) {
  let url = process.env.REACT_APP_API_URL + `/notifications/save/`;
  return function (dispatch) {
    dispatch(setSettingListPatchStatus(RequestStatus.status.FETCHING));
    axios
      .patch(url, data)
      .then(response => {
        dispatch(setSettingListPatchStatus(RequestStatus.status.DONE));
        dispatch({type: SNACKBAR_STATUS, payload: {status: true, content: 'Zmiany zostały zapisane!'}})
      })
      .catch(error => {
        dispatch(setSettingListPatchStatus(RequestStatus.status.ERROR));
        dispatch({type: SNACKBAR_STATUS, payload: {status: true, content: 'Wystąpił prooblem z zapisem zmian!'}})
      });
  };
}

const setSettingListPatchStatus = status => {
  return {
    type: SET_SETTINGS_FETCH_STATUS,
    payload: status
  };
};
