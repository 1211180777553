import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import styled from 'styled-components';
import { getEventList, setEventUserStatus } from '../../reducers/events.actions';
import moment from 'moment';
import EventCard from './EventCard';
import { getEventDetails } from '../../routes';

const TextMuted = styled.div`
  text-align: center;
  margin: ${props => (props.small ? '5px' : '50px')};
  margin: ${props => (props.small ? '5px' : '50px')};
  color: darkgray;
`;

function EventsList(props) {
  const { eventList } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleCardClick = (data, event) => {
    history.push(`${getEventDetails()}/${event.id}`);
  };

  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Wydarzenia' });
    const query = new URLSearchParams(props.location.search);
    if (query.get('calendar')) {
      dispatch(
        getEventList({
          date__gte: query.get('date__gte'),
          date__lte: query.get('date__lte')
        })
      );
    } else {
      dispatch(getEventList({}));
    }
  }, []);

  const changeEventUserStatus = (event, status) => {
    dispatch(
      setEventUserStatus(event.id, { status: event.user_status === status ? null : status })
    );
  };

  return (
    <React.Fragment>
      {eventList.map((event, idx) => {
        return (
          <EventCard
            key={idx}
            calendarEvent={event}
            changeEventUserStatus={changeEventUserStatus}
            onClick={data => handleCardClick(data, event)}
          />
        );
      })}

      {(!eventList || !eventList.length) && <TextMuted>Brak elementów do wyświetlenia</TextMuted>}
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    eventList: state.events.eventList
  };
}

export default connect(mapStateToProps, {})(EventsList);
// EventsList.whyDidYouRender = true;
