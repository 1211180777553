import React from 'react';
import styled from 'styled-components';
import { colors, fontSizes } from '../../theme';

const StyledCalendarContainer = styled.div`
  padding: 0 10px;
  margin-bottom: 20px;

  .fc-head {
    display: none;
  }
  .fc-header-toolbar {
    margin-bottom: 1em !important;
  }
  .fc-center h2 {
    color: ${colors.calendarFontColor};
    border: none;
  }
  .fc-icon,
  .fc-day-number {
    color: ${colors.calendarFontColor};
  }
  .fc-widget-content,
  .fc-day,
  .fc-day-top,
  .fc-content-skeleton,
  .fc-content,
  .fc-content-skeleton table tbody tr td {
    border: none;
  }
  .fc-widget-header,
  .fc-other-month {
    visibility: hidden;
    pointer-events: none;
  }
  .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    float: none;
  }
  .fc-content-skeleton {
    height: 100%;
  }
  //.fc-row {
  //  height: 35px !important;
  //}
  .fc-day-top {
    text-align: center;
    height: 45px !important;
    padding-bottom: 0px !important;

    span {
      float: none;
      font-size: ${fontSizes.calendarDay};
    }
  }
  //.fgdaIR .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  //  padding-bottom: 0px !important;
  //}
  .fc-day-number {
    padding: 10px !important;
    display: inline-block;
    width: 35px;
    height: 35px;
    font-weight: 300;
    cursor: pointer;
  }
  // today
  .fc-unthemed td.fc-today {
    border: none !important;
    background: rgba(124, 172, 68, 0) !important;
  }
  // selected
  .fc-unthemed td.custom-highlight {
    border: none !important;
    background: rgba(124, 172, 68, 0) !important;

    span {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: ${colors.accentLightColor};
      display: inline-block;
      font-weight: 600;
    }
  }
  // events
  .fc-event-container {
    height: 0px !important;
    width: 100%;

    .fc-day-grid-event {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin: 0 auto;
      margin-top: -20px;
      padding: 0px;
      background-color: ${colors.accentLightColor} !important;
      border: none;

      .fc-content {
        display: none;
        .fc-title {
          display: none;
        }
      }
    }

    &.custom-event-dot .fc-day-grid-event {
      background-color: ${colors.accentDarkColor} !important;
    }
  }
`;

export default StyledCalendarContainer;
