import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import { getNewsDetails } from '../../reducers/news.actions';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors, sizes } from '../../theme';
import AbsoluteViewContainer from '../../components/AbsoluteViewContainer';
import EventDetailsView from '../../components/EventsElement/EventDetailsView';

function EventsDetails(props) {
  const { event, setNavTitle, getEventDetails, setEventUserStatus, setEventDetails } = props;
  let { id } = useParams();

  useEffect(() => {
    setNavTitle('Wydarzenia');
    getEventDetails(id);
    return () => {
      setEventDetails(null);
    };
  }, []);

  return (
    <AbsoluteViewContainer>
      {event && event.id && (
        <EventDetailsView
          descriptionKey={'description'}
          event={event}
          setEventUserStatus={setEventUserStatus}
        />
      )}
    </AbsoluteViewContainer>
  );
}

export default EventsDetails;
