import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import routes from '../../routes';
import { useHistory } from 'react-router-dom';
import BaseTemplate from '../BaseTemplate';
import styled from 'styled-components';
import { connect } from 'react-redux';

const BasePage = styled.div`
  //margin-top:env(safe-area-inset-top);
  //margin-bottom:env(safe-area-inset-bottom);
  padding-top: calc(56px + env(safe-area-inset-top));
  padding-bottom: calc(56px + env(safe-area-inset-bottom));

  @media (min-width: 600px) {
    // Header has 64px height but we have here 72px
    // because of drop shadow of header
    padding-top: calc(72px + env(safe-area-inset-top));
  }
`;

function Layout(props) {
  let history = useHistory();
  const { isLogged } = props;
  const [previousState, setPreviousState] = useState(isLogged);

  useEffect(() => {
    if (!isLogged && previousState !== null) {
      history.push('/login');
      setPreviousState(isLogged);
    }
  }, [isLogged]);

  return (
    <BaseTemplate history={history}>
      <BasePage>
        <Switch>
          {routes.map((route, idx) => {
            if (route.component)
              return (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => <route.component {...props} />}
                />
              );
            else if (route.redirect) {
              let redirectPath = route.redirect;
              if (
                props.history.location &&
                route.path.includes(':id') &&
                route.redirect.includes(':id')
              ) {
                const pathParts = props.history.location.pathname.split('/');
                const elementId = pathParts[pathParts.length - 1];
                redirectPath = redirectPath.replace(':id', elementId);
              }
              return (
                <Route
                  key={idx}
                  exact={route.exact}
                  path={route.path}
                  render={props => <Redirect to={redirectPath} />}
                />
              );
            } else return null;
          })}
        </Switch>
      </BasePage>
    </BaseTemplate>
  );
}

// export default Layout;
function mapStateToProps(state, otherProps) {
  return {
    isLogged: state.auth.isLogged
  };
}

export default withRouter(connect(mapStateToProps, {})(Layout));
// Layout.whyDidYouRender = true;
