const statuses = {
  FETCHING: 'FETCHING',
  DONE: 'DONE',
  ERROR: 'ERROR',
  NULL: null
};

export class RequestStatus {
  static get status() {
    return statuses
  }

  static isDone = (status) => statuses.DONE === status;
  static isFetching = (status) => statuses.FETCHING === status;
  static isError = (status) => statuses.ERROR === status;
  static isNull = (status) => statuses.NULL === status;

  static extractErrorMessage = (axiosError) => Object.values(axiosError.response.data)[0][0]
    || Object.values(axiosError.response.data)[0];
}
