import moment from "moment";

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = value => (value ? undefined : 'To pole jest wymagane');

export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Wartość musi być większa niż ${min}`;

export const maxValue = max => value =>
  isNaN(value) || value <= max ? undefined : `Wartość musi być mniejsza niż ${max}`;

export const minLength = (min) => value =>
  ("" + value).length >= min ? undefined : `Minimalna długość pola to ${min}`;

export const maxLength = max => value =>
  ("" + value).length <= max ? undefined : `Maksymalna długość pola to  ${max}`;

export const birthDateValidator = value => {
  if (!value)
    return undefined;
  return moment(value, 'DD/MM/YYYY', true).isValid() ? undefined : `Błędna data`;
};

export const phoneNumberValidator = value => {
  return value && value.replace(/-/gi, '').length === 9 ? undefined : `Niepoprawny numer telefonu`
};
