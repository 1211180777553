import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getPetitionDetails, saveAnswer, setPetitionData } from '../../reducers/your-voice.actions';
import { RequestStatus } from '../../utils/RequestStatus';
import Share from '../../components/ShareComponent/Share';
import QuestionComponent from '../../components/QuestionComponent/QuestionComponent';
import AbsoluteViewContainer, { TextContent } from '../../components/AbsoluteViewContainer';

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  justify-content: center;
  align-items: center;
`;

function QuestionnaireDetails(props) {
  const { petitionDetails, saveAnswerPostStatus } = props;
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState(null);
  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Twój głos' });
    dispatch(getPetitionDetails(id));
  }, []);

  useEffect(() => {
    if (answer) {
      dispatch(saveAnswer(id, { answers: answer }));
    }
  }, [answer]);

  useEffect(() => {
    if (saveAnswerPostStatus === RequestStatus.status.DONE) {
      history.push(`/your-voice/questionnaire/${petitionDetails.id}/confirmation`);
    }
  }, [saveAnswerPostStatus]);

  return (
    <AbsoluteViewContainer>
      <Container>
        {petitionDetails && (
          <>
            <QuestionComponent
              headerLabel={'Ankieta'}
              declarantLabel={null}
              details={petitionDetails}
              setAnswer={setAnswer}
            />
            <TextContent>
              <Share
                style={{ marginTop: '30px' }}
                url={petitionDetails && petitionDetails.share_url}
              />
            </TextContent>
          </>
        )}
      </Container>
    </AbsoluteViewContainer>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    petitionDetails: state.yourVoice.petitionDetails,
    saveAnswerPostStatus: state.yourVoice.saveAnswerPostStatus
  };
}

export default connect(mapStateToProps, {
  getPetitionDetails,
  saveAnswer,
  setPetitionData
})(QuestionnaireDetails);
