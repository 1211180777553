import {
  SET_HEADER_PRIMARY_TEXT,
  SET_HEADER_SECONDARY_TEXT,
  SET_MENU_OPEN_STATUS, SET_OPEN_PHOTO_FULLSCREEN_STATUS, SET_VOTING_LIST_TAB_INDEX, SNACKBAR_STATUS
} from "./utils.actionTypes";
import moment from 'moment';
import 'moment/locale/pl';

moment.locale('de');

export const INITIAL_STATE: any = {
  headerPrimaryText: '',
  headerSecondaryText: `${moment().format('DD.MM.YYYY, dddd')}`,
  showMenu: false,
  snackBarStatus: false,
  snackbarContent: null,
  votingListTabIndex: 1,
  openPhotoFullscreenStatus: null
};

export default function utils(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_HEADER_PRIMARY_TEXT:
      return {
        ...state,
        headerPrimaryText: action.payload,
      };
    case SET_HEADER_SECONDARY_TEXT:
      return {
        ...state,
        headerSecondaryText: action.payload
      };
    case SET_MENU_OPEN_STATUS:
      return {
        ...state,
        showMenu: action.payload
      };
    case SNACKBAR_STATUS:
      return {
        ...state,
        snackBarStatus: action.payload.status,
        snackBarContent: action.payload.content
      };
    case SET_VOTING_LIST_TAB_INDEX:
      return {
        ...state,
        votingListTabIndex: action.payload
      };
    case SET_OPEN_PHOTO_FULLSCREEN_STATUS:
      return {
        ...state,
        openPhotoFullscreenStatus: action.payload
      };
    // case userActionTypes.UNAUTH_USER:
    //     return {...INITIAL_STATE};
    default:
      return state;
  }
}
