import {
  CHANGE_PASSWORD_POST_STATUS,
  LOGIN_REQUEST_FETCH_STATUS, PHONE_LOGIN_DATA,
  PHONE_LOGIN_REQUEST_FETCH_STATUS,
  PHONE_VERIFICATION_SEND_DATA,
  PHONE_VERIFICATION_SEND_FETCH_STATUS,
  PHONE_VERIFICATION_VERIFY_FETCH_STATUS,
  REGISTRATION_POST_STATUS,
  SET_IS_LOGGED_STATUS,
  SET_PASSWORD_RESET_POST_STATUS,
  SET_USER_DATA,
  SET_USER_FETCH_STATUS,
  SET_USER_PATCH_STATUS,
  TOKEN_UPDATE
} from "./auth.actionTypes";

export const INITIAL_STATE: any = {
  isLogged: null,
  loginFetchStatus: null,
  phoneLoginFetchStatus: null,
  phoneLoginData: null,
  phoneVerificationSendData: null,
  phoneVerificationVerifyData: null,
  token: null,
  registrationPostStatus: null,
  changePasswordPostStatus: null,
  passwordResetPostStatus: null,
  userFetchStatus: null,
  userPatchStatus: null,
  user: null
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_REQUEST_FETCH_STATUS:
      return {...state, loginFetchStatus: action.payload};
    case PHONE_LOGIN_REQUEST_FETCH_STATUS:
      return {...state, phoneLoginFetchStatus: action.payload};
    case PHONE_VERIFICATION_SEND_FETCH_STATUS:
      return {...state, phoneVerificationFetchStatus: action.payload};
    case PHONE_VERIFICATION_SEND_DATA:
      return {...state, phoneVerificationSendData: action.payload};
    case PHONE_LOGIN_DATA:
      return {...state, phoneLoginData: action.payload};
    case PHONE_VERIFICATION_VERIFY_FETCH_STATUS:
      return {...state, phoneVerificationVerifyFetchStatus: action.payload};
    case SET_IS_LOGGED_STATUS:
      return {
        ...state, isLogged: action.payload
      };
    case SET_USER_DATA:
      return {
        ...state, user: action.payload
      };
    case REGISTRATION_POST_STATUS:
      return {
        ...state, registrationPostStatus: action.payload
      };
    case CHANGE_PASSWORD_POST_STATUS:
      return {
        ...state, changePasswordPostStatus: action.payload
      };
    case TOKEN_UPDATE:
      return {
        ...state, token: action.payload
      };
    case SET_PASSWORD_RESET_POST_STATUS:
      return {
        ...state, passwordResetPostStatus: action.payload
      };

    case SET_USER_FETCH_STATUS:
      return {
        ...state, userFetchStatus: action.payload
      };

    case SET_USER_PATCH_STATUS:
      return {
        ...state, userPatchStatus: action.payload
      };
    // case userActionTypes.UNAUTH_USER:
    //     return {...INITIAL_STATE};
    default:
      return state;
  }
}
