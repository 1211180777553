import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { SET_HEADER_PRIMARY_TEXT } from "../../reducers/utils.actionTypes";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import FormGroup from "@material-ui/core/FormGroup";
import { postPetition, setPetitionPostStatus } from "../../reducers/your-voice.actions";
import { RequestStatus } from "../../utils/RequestStatus";
import UploadComponent from "../../components/UploadComponent/UploadComponent";
import { composeValidators, maxLength, maxValue, minLength, minValue, required } from "../../utils/formValidators";
import {
  AddPhotoSquare,
  FormContainer,
  FormGroupStyles,
  FormStyles,
  LabelError,
  PrimaryFieldLabel,
  PrimaryInput,
  PrimarySmallButton,
  PrimaryTextarea
} from "../../utils/uiComponents";


const Container = styled.div`
    margin:0 30px;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 100%;
`;

const HeaderContainer = styled.div`
    text-align: center;
    margin: 5px 0 40px 0;
    font-size: 23px;
    text-transform: uppercase;
    display: block;
    padding-top: 15px;
    font-weight: bold;
`;

const HeaderLabel = styled.div`
    display: initial;
    border-bottom: 4px solid ${props => props.theme.secondaryColor};
    padding-bottom:10px;
`;

const AddPhotoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 55px);
    grid-column-gap: 15px;
    margin-top: 10px;
    color: ${props => props.theme.secondaryColor};    
`;

const AcceptLabel = styled.div`
    font-size:13px;
`;

const SaveButtonContainer = styled.div`
    display: grid;
    justify-content: end;
`;

const FormContainerStyles = styled(FormContainer)`
    padding-bottom: 50px;
    grid-template-columns: 100%;
    position: relative;
`;

function PetitionAdd(props) {
  const {petitionPostStatus, petitionDetails} = props;
  let history = useHistory();

  const [photo, setPhoto] = useState(null);
  const [photosToSend, setPhotosToSend] = useState({});

  useEffect(() => {
    setPhotosToSend(Object.assign({}, photosToSend, photo));
  }, [photo]);

  useEffect(() => {
  }, [photosToSend]);

  useEffect(() => {
    dispatch({type: SET_HEADER_PRIMARY_TEXT, payload: 'Twój głos'})
  }, []);

  useEffect(() => {
    if (petitionPostStatus === RequestStatus.status.DONE) {
      history.push(`${petitionDetails.id}/confirmation`);
    }
  }, [petitionPostStatus]);

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(postPetition(data, photosToSend))
  };

  return (
    <React.Fragment>
      <Container>

        <HeaderContainer>
          <HeaderLabel>Postulat</HeaderLabel>
        </HeaderContainer>


        <FormContainerStyles>
          {/*<Form style={{margin: '30px'}}*/}
          <Form onSubmit={onSubmit}
                initialValues={{question: '', description: '', duration: 14}}
                render={({handleSubmit, form, submitting, pristine, values}) => (
                  <FormStyles onSubmit={handleSubmit} style={{
                    gridTemplateColumns: '80%',
                    justifyContent: 'center'}}>
                    <FormGroupStyles>
                      <PrimaryFieldLabel whiteBg>Czas trwania (dni)</PrimaryFieldLabel>
                      <Field name="duration"
                             validate={composeValidators(required, minValue(2), maxValue(30))}
                      >
                        {({input, meta}) => (
                          <React.Fragment>
                            <PrimaryInput
                              whiteBg
                              type="number"
                              min={1}
                              placeholder="Liczba dni"
                              {...input}
                            />
                            {meta.error && meta.touched &&
                            <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles>
                      <PrimaryFieldLabel whiteBg>Postulat</PrimaryFieldLabel>
                      <Field name="question"
                             validate={composeValidators(minLength(5), maxLength(200))}>
                        {({input, meta}) => (
                          <React.Fragment>
                            <PrimaryTextarea
                              placeholder="Treść postulatu"
                              {...input}>
                            </PrimaryTextarea>
                            {meta.error && meta.touched &&
                            <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles>
                      <PrimaryFieldLabel whiteBg>Szczegółowy opis</PrimaryFieldLabel>
                      <Field name="description"
                             validate={composeValidators(minLength(5), maxLength(5000))}>
                        {({input, meta}) => (
                          <React.Fragment>
                            <PrimaryTextarea
                              placeholder="Dodatkowe informacje na temat postulatu"
                              {...input}>
                            </PrimaryTextarea>
                            {meta.error && meta.touched &&
                            <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>

                    {/*<FormGroup style={{flexWrap: 'noWrap'}}>*/}
                    <FormGroupStyles>
                      <PrimaryFieldLabel whiteBg>Dodaj zdjęcie</PrimaryFieldLabel>
                      <AddPhotoContainer>
                        <AddPhotoSquare>
                          <UploadComponent imageKey={'desc_image1'} callback={setPhoto}/>
                        </AddPhotoSquare>
                        <AddPhotoSquare>
                          <UploadComponent imageKey={'desc_image2'} callback={setPhoto}/>
                        </AddPhotoSquare>
                        <AddPhotoSquare>
                          <UploadComponent imageKey={'desc_image3'} callback={setPhoto}/>
                        </AddPhotoSquare>
                      </AddPhotoContainer>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <AcceptLabel>Zapoznałem się z <a
                        href="/i/regulamin/"
                        target="_blank"
                        style={{color: 'inherit', fontWeight: 'bold'}}
                      >regulaminem</a> tworzenia postulatu.</AcceptLabel>
                    </FormGroupStyles>
                    {/*<FormGroup style={{alignItems: 'flexEnd'}}>*/}
                    <SaveButtonContainer>
                      <PrimarySmallButton>Opublikuj</PrimarySmallButton>
                    </SaveButtonContainer>
                    {/*</FormGroup>*/}
                  </FormStyles>
                )}/>
        </FormContainerStyles>
      </Container>
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    petitionPostStatus: state.yourVoice.petitionPostStatus,
    petitionDetails: state.yourVoice.petitionDetails
  };
}

export default connect(
  mapStateToProps,
  {
    postPetition,
    setPetitionPostStatus
  }
)(PetitionAdd);
