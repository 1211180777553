import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const WhiteText = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  cursor: pointer;
`;

const SignInHelper = props => {
  const { setTab } = props;
  return (
    <>
      <WhiteText>
        <div onClick={() => setTab('register')}>Nie masz konta? Zarejestruj się!</div>
      </WhiteText>

      <WhiteText>
        <div onClick={() => setTab('forgot-password')}>Zapomniałem hasła</div>
      </WhiteText>
    </>
  );
};

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(mapStateToProps, {})(SignInHelper);
