import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ButtonContainer,
  ButtonIcon,
  LogByLabel,
  LogoContainer,
  LogoImg,
  MenuBarPlaceholder,
  WhiteText
} from './LoginParts';
import { SecondaryButton } from '../../utils/uiComponents';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import SignInHelper from '../../components/SignInHelper/SignInHelper';
import LoginKnowledgeBase from './LoginKnowledgeBase';
import { getVideosList } from '../../reducers/knowledge-base.actions';

function LoginMain(props) {
  const { handleFacebookLogin, setTab, knowledgeBaseURL, getVideosList } = props;

  useEffect(() => {
    if (!knowledgeBaseURL) getVideosList();
  }, []);

  return (
    <React.Fragment>
      <LogoContainer>
        <LogoImg src={`images/logo.png`} alt="Logo" />
      </LogoContainer>
      <LogByLabel>Zaloguj poprzez:</LogByLabel>
      <ButtonContainer>
        <SecondaryButton onClick={() => handleFacebookLogin()}>
          <ButtonIcon>
            <PersonOutlinedIcon style={{ fontSize: 35 }} />
          </ButtonIcon>
          Facebook
        </SecondaryButton>
        <SecondaryButton onClick={() => setTab('login')}>
          <ButtonIcon>
            <AlternateEmailOutlinedIcon style={{ fontSize: 35 }} />
          </ButtonIcon>
          E-mail
        </SecondaryButton>
        <SecondaryButton onClick={() => setTab('phone-login')}>
          <ButtonIcon>
            <PhoneAndroidOutlinedIcon style={{ fontSize: 35 }} />
          </ButtonIcon>
          Numer telefonu
        </SecondaryButton>
      </ButtonContainer>

      <SignInHelper setTab={setTab} />

      <LoginKnowledgeBase url={knowledgeBaseURL} />

      <WhiteText>
        <p>
          Logując się akceptujesz{' '}
          <a href="/i/regulamin/" target="_blank" style={{ color: 'inherit', fontWeight: 'bold' }}>
            regulamin
          </a>{' '}
          aplikacji w jego aktualnym brzmieniu
        </p>
      </WhiteText>

      <MenuBarPlaceholder />
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    knowledgeBaseURL: state.knowledgeBase.knowledgeBaseLink
      ? state.knowledgeBase.knowledgeBaseLink.url ||
        state.knowledgeBase.knowledgeBaseLink.youtube_url
      : ''
  };
}

export default connect(mapStateToProps, { getVideosList })(LoginMain);
