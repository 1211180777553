import React, { useEffect } from 'react';
import { SET_HEADER_PRIMARY_TEXT } from "../../reducers/utils.actionTypes";
import { connect, useDispatch } from "react-redux";
import UnavailablePageComponent from "../../components/UnavailablePageComponent/UnavailablePageComponent";


function SmugAlert(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({type: SET_HEADER_PRIMARY_TEXT, payload: 'Alert smogowy'})
  }, []);

  return (
    <div>
      <UnavailablePageComponent/>
    </div>
  );
}

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(SmugAlert);

