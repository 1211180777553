import React from 'react';
import './App.css';
import { connect, Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Route, Switch, Router } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Layout from './containers/Layout/Layout';
import { history } from './utils/utils';
import { colors } from './theme';
import store from './store';
import Login from './containers/Login/Login';
import NetworkService from './utils/network.service';
import { getAuthInfo } from './reducers/auth.actions';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import { hideSnackBar } from './reducers/utils.actions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpPage from './containers/HelpPage/HelpPage';
import ScrollToTop from './components/ScrollToTopComponent/ScrollToTopComponent';
import FakeSplash from './components/Splash';

const Loader = styled.div`
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  opacity: ${props => (props.show ? 0.4 : 0)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  outline: none;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const LoaderImg = styled.img`
  width: 100px;
  height: 100px;
  pointer-events: none;
`;

const ModalEl = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

class App extends React.Component<any, any> {
  state = {
    showSplash: true,
    animate: false
  };

  constructor(props) {
    super(props);
    setTimeout(() => {
      this.setState({ showSplash: false });
    }, 2000);

    setTimeout(() => {
      this.setState({ animate: true });
    }, 1500);
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      NetworkService.setupDefaultHeaders(localStorage.getItem('token'));
    }
    NetworkService.setupInterceptors(store);
    this.props.getAuthInfo();
  }

  render() {
    const handleClose = data => {
      this.props.hideSnackBar({});
    };

    return (
      <ThemeProvider theme={colors}>
        <Provider store={store}>
          <ModalEl style={{ pointerEvents: 'none' }}>
            <Loader show={!this.state.showSplash && this.props.requestCount}>
              <LoaderImg src={`/images/loader.gif`} />
            </Loader>
          </ModalEl>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={this.props.snackBarStatus}
            autoHideDuration={4000}
            onClose={handleClose}
            message={this.props.snackBarContent}
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
          />
          <Router history={history}>
            <ScrollToTop />
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/help" component={HelpPage} />
              {this.props.user && <PrivateRoute path="/" component={Layout} />}
            </Switch>
          </Router>

          <FakeSplash animate={this.state.animate} isVisible={this.state.showSplash} />
        </Provider>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state, otherProps) {
  return {
    snackBarStatus: state.utils.snackBarStatus,
    snackBarContent: state.utils.snackBarContent,
    requestCount: state.loader.requestCount,
    user: state.auth.user
  };
}

export default connect(mapStateToProps, {
  getAuthInfo,
  hideSnackBar
})(App);
