import moment from 'moment';

export const convertTimestampToDate = timestamp => moment(timestamp * 1000).format('DD.MM.YYYY');

export const convertTimestampToHour = timestamp => moment(timestamp * 1000).format('HH:mm');

export const isTimestampFromThePast = timestamp => {
  const dayDiff = moment(timestamp * 1000).diff(moment(), 'days');
  return dayDiff < 0;
};
