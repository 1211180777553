import React from 'react';
import styled from 'styled-components';
import { sizes, colors } from '../theme';
import { BoxButton } from '../utils/uiComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const AdditionalButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  grid-template-columns: 40px auto;
`;

const TextMuted = styled.div`
  text-align: center;
  margin: ${props => (props.small ? '5px' : '50px')};
  margin: ${props => (props.small ? '5px' : '50px')};
  color: darkgray;
`;

const FlexText = styled.div`
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
`;

const StyledBoxButton = styled(BoxButton)`
  border: 2px solid ${props => (props.disable ? colors.disableColor : colors.accentLightColor)};
  border-radius: 50%;
  cursor: pointer;
`;

const StyledFaIcon = styled(FontAwesomeIcon)`
  height: 25px;
  color: ${props => (props.disable ? colors.disableColor : colors.secondaryColor)};
  width: 70% !important;
  padding-left: 3px !important;
`;

const Checkbox = ({
  selected = false,
  onClick = () => {},
  text = '',
  countStatus = null,
  disable = false
}) => {
  return (
    <AdditionalButton>
      <StyledBoxButton
        noFill={true}
        className={selected ? 'selected' : ''}
        onClick={disable ? () => {} : onClick}
        disable={disable}
      >
        {selected ? (
          <StyledFaIcon icon={faCheck} disable={disable ? String(disable) : undefined} />
        ) : (
          <></>
        )}
      </StyledBoxButton>
      <FlexText onClick={disable ? () => {} : onClick}>
        {text + ' '}
        {countStatus && <TextMuted small>({countStatus})</TextMuted>}
      </FlexText>
    </AdditionalButton>
  );
};

export default React.memo(Checkbox);
