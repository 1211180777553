import React, { useCallback, useState, useEffect } from 'react';
import styled from "styled-components";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CloseIcon from '@material-ui/icons/Close';

const Container = styled.div`
       width:100%;
       height:100%;
       display: flex;
       justify-content: center;
       align-items: center;
       &:focus {
       outline: none;
       }
       position:relative;
`;

const RemoveFile = styled.div`
       width:30px;
       height:30px;
       border-radius: 50%;
       background-color: ${props => props.theme.dangerColor};
       position: absolute;
       top:-15px;
       right:-15px;
         display: flex;
justify-content: center;
align-items: center;
`;

const InputStyle = styled.input`
       outline: none;
       &:focus {
       outline: none;
       }
`;

const Thumb = styled.img`
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: contain;
`;

const CloseIconStyle = styled(CloseIcon)`
color: white;
`;

const UploadComponent = (props) => {
  const [file, setFile] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const onDrop = useCallback(acceptedFiles => {

    var reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onloadend = () => {
      var base64data = reader.result;
      setFile(base64data);
    };
    let obj = {};
    obj[props.imageKey || file] = acceptedFiles;
    props.callback(obj);

  }, []);

  const removePhoto = () => {
    setFile(null);
    setCurrentPhoto(null);
    let obj = {};
    obj[props.imageKey || file] = null;
    props.callback(obj);
  };

  useEffect(() => {
    if (props.data)
      setCurrentPhoto(props.data.photo);
  }, [props.data]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop, ...{accept: 'image/jpeg, image/png, image/gif'}
  });
  return (
    <Container {...getRootProps({className: 'dropzone'})}>
      {!file && !currentPhoto ? <React.Fragment>
        <InputStyle {...getInputProps()} />
        <AddOutlinedIcon style={{fontSize: 35}}></AddOutlinedIcon>
      </React.Fragment> : <React.Fragment>
        <RemoveFile>
          <CloseIconStyle style={{fontSize: 25}}
                          onClick={() => removePhoto()}/></RemoveFile><Thumb
        src={currentPhoto || file}/></React.Fragment>}
    </Container>
  );
};

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(UploadComponent);
