import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { sizes } from '../../theme';
import { CardDate, CardDescription, CardTitle } from '../Card/CardParts';
import ResponsiveImage from '../ResponsiveImage';
import Share from '../ShareComponent/Share';
import { convertTimestampToDate, isTimestampFromThePast } from '../../utils/dateFormatting';
import { userStatus } from '../../containers/EventsList/events.constatns';
import Checkbox from '../../components/Checkbox';
import { TextContent } from '../AbsoluteViewContainer';

const StyledCheckbox = styled(Checkbox)``;

const CheckboxContainer = styled.div`
  margin-bottom: 15px;
`;

const EventDetailsView = props => {
  const { event, descriptionKey, setEventUserStatus } = props;

  const changeEventUserStatus = (event, status) => {
    setEventUserStatus(event.id, { status: event.user_status === status ? null : status });
  };

  return (
    <>
      {event.image && <ResponsiveImage src={event.image} isFullWidth={true} />}
      <TextContent>
        <CardTitle>{event.name}</CardTitle>
        <CardDate>{event.date ? convertTimestampToDate(event.date) : '-'}</CardDate>
        <CardDescription dangerouslySetInnerHTML={{ __html: event[descriptionKey] }} />
        <CheckboxContainer>
          <StyledCheckbox
            selected={event.user_status === userStatus.GOING}
            text="Wezmę udział"
            onClick={() => changeEventUserStatus(event, userStatus.GOING)}
            disable={isTimestampFromThePast(event.date)}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <StyledCheckbox
            selected={event.user_status === userStatus.INTERESTED}
            text="Zainteresowany"
            onClick={() => changeEventUserStatus(event, userStatus.INTERESTED)}
            disable={isTimestampFromThePast(event.date)}
          />
        </CheckboxContainer>

        <Share url={event && event.share_url} />
      </TextContent>
    </>
  );
};

export default EventDetailsView;
