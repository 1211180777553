import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const token = 'token';
//
// export const login = (login) => {
//     localStorage.setItem(token, 'TestLogin');
// };
//
// export const logout = () => {
//     localStorage.removeItem(token);
// };
//
// export const isLogin = () => {
//     console.log("!!localStorage.getItem(token)", !!localStorage.getItem(token));
//     // return true
// };


