import React from 'react';
import { BoxButton } from '../../utils/uiComponents';
import { AdditionalButton } from './LoginParts';
import { acceptUserAgreement } from './register.constants';
import styled from 'styled-components';
import Checkbox from '../../components/Checkbox';

const StyledLink = styled.a`
  color: inherit;
  font-weight: bold;
`;

function LoginAgreement(props) {
  const { form, values } = props;

  return (
    <AdditionalButton>
      <Checkbox
        onClick={() => {
          form.mutators.setValue('is_agreement_accepted', !values.is_agreement_accepted);
        }}
        selected={!!values.is_agreement_accepted}
      />
      <StyledLink href="/i/regulamin/" target="_blank">
        {acceptUserAgreement}
      </StyledLink>
    </AdditionalButton>
  );
}

export default LoginAgreement;
