import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { SET_HEADER_PRIMARY_TEXT } from "../../reducers/utils.actionTypes";

const Container = styled.div`
    grid-template-columns: 80%;
    justify-content: center;
    align-items: center;
    display: grid;
    //padding: 60px 0;
    height: calc(100vh - (56px + env(safe-area-inset-top)) - (56px + env(safe-area-inset-bottom)) - 55px); 
`;

const ButtonContainer = styled.div`
    display: grid;
    //width:70vw;
`;

const MainButton = styled.div`
    border: 3px solid ${props => props.theme.secondaryColor};
    border-radius: 40px;
    min-height: 45px;
    width: 100%;
    justify-content: center;
    align-items:center;
    display:grid;
    font-size:24px;
    font-weight: bold;
    margin:15px 0;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    padding:7px 10px;
    box-sizing: border-box;
    cursor: pointer;
`;


function WeatherList() {
  let history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({type: SET_HEADER_PRIMARY_TEXT, payload: 'Pogoda'})
  }, []);

  const openPage = (url) => {
    history.push(url);
  };

  return (
    <React.Fragment>
        <Container>
          <ButtonContainer>
            <MainButton onClick={() => openPage('/weather/forecast')}>
              Prognoza Pogody
            </MainButton>
            <MainButton onClick={() => openPage('/weather/smug-alert')}>
              Alert Smogowy
            </MainButton>
          </ButtonContainer>
        </Container>
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(WeatherList);
// WeatherList.whyDidYouRender = true;
