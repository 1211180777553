import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { GalleryContainer, Gallery, GalleryImg, GalleryObj } from './QuestionComponentParts';

// image modal styles are global, defined in App.css

export function QuestionCompGallery(props) {
  const { detailsObj, onImgClick, openPhotoFullscreenStatus, onModalClose } = props;

  return (
    <>
      <ModalGateway>
        {openPhotoFullscreenStatus !== null && +openPhotoFullscreenStatus >= 0 ? (
          <Modal
            onClose={() => onModalClose()}
            styles={{
              blanket: (base, state) => ({ ...base, zIndex: 1100 }),
              positioner: (base, state) => ({ ...base, zIndex: 1110 }),
              dialog: (base, state) => ({ ...base, zIndex: 1120 })
            }}
          >
            {detailsObj && (
              <Carousel
                views={detailsObj.images}
                hideControlsWhenIdle={3000}
                currentIndex={+openPhotoFullscreenStatus}
                frameProps={{ autoSize: false }}
                styles={{
                  header: (base, state) => ({
                    ...base,
                    opacity: 1,
                    transform: 'translateY(-10px)',
                    top: 'env(safe-area-inset-top)'
                  })
                }}
              />
            )}
          </Modal>
        ) : null}
      </ModalGateway>
      <GalleryContainer>
        <Gallery>
          {detailsObj.images.map((img, i) => (
            <GalleryObj key={img.source + i}>
              <GalleryImg
                onClick={() => {
                  onImgClick(+i);
                }}
                src={img.source}
              />
            </GalleryObj>
          ))}
        </Gallery>
      </GalleryContainer>
    </>
  );
}
