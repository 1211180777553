import React from 'react';
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const HeaderContainer = styled.div`
    text-align: center;
    margin: 5px 0 40px 0;
    font-size: 23px;
    text-transform: uppercase;
    display: block;
    padding-top: 15px;
    font-weight: bold;
`;

const HeaderLabel = styled.div`
display: initial;
border-bottom: 4px solid ${props => props.theme.secondaryColor};
padding-bottom:10px;
`;

const ButtonContainer = styled.div`
display: grid;
justify-content: center;
`;

const MainButton = styled.div`
background-color: ${props => props.theme.secondaryColor};
border-radius: 50px;
height: 60px;
width: 70vw;
color: white;
justify-content: center;
align-items:center;
display:grid;
font-size:30px;
font-weight: bold;
margin:15px 0;
position: relative;
text-transform: uppercase;
`;

const MoreButton = styled.div`
border: 4px solid ${props => props.theme.secondaryColor};
border-radius: 50px;
height: 40px;
width: 50vw;
justify-content: center;
align-items:center;
display:grid;
font-size:25px;
margin:15px 0;
position: relative;
text-transform: uppercase;
`;

const TextContaier = styled.div`
    padding: 20px 30px;
    font-size: 25px;
    text-align: center;
`;

const ConfirmationComponent = (props) => {
  let history = useHistory();
  const {headerLabel, text, url, actionType} = props;

  const goToUrl = () => {
    actionType === 'CREATE' ? history.replace(url) : history.goBack();
  };

  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderLabel>{headerLabel}</HeaderLabel>
      </HeaderContainer>

      <ButtonContainer>
        <MainButton>Gratulacje!</MainButton>
      </ButtonContainer>

      <TextContaier>{text}</TextContaier>

      {actionType !== '' && <ButtonContainer>
          <MoreButton type={'button'} onClick={goToUrl}>Zobacz</MoreButton>
      </ButtonContainer>}
    </React.Fragment>
  );
};

export default ConfirmationComponent;
