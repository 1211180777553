import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';
import { playerOpts } from './knowledgeBase.constants';
import CustomExpansionPanel from '../../components/CustomExpansionPanel';
import CircularProgress from '@material-ui/core/CircularProgress';

const YouTubeWrapper = styled.div`
  margin: 0 auto !important;
  margin-bottom: -4px !important;
  position: relative;
`;

const CircularProgressContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 45%;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: relative;
  left: -50%;
  top: -50%;
`;

function KnowledgeBase(props) {
  const { setNavTitle, videos = [], getVideosList } = props;

  const expansionPanelParentRef = useRef();

  const [loadedVideos, setLoadedVideos] = useState([]);
  const [expansionPanelReady, setExpansionPanelReady] = useState(false);

  const onVideoReady = (event, title) => {
    setLoadedVideos([...loadedVideos, title]);
  };

  const onVideoStateChange = (event, title) => {};

  const getPlayerDims = () => {
    let vidWidth;
    let vidHeight;
    if (expansionPanelParentRef.current) {
      // @ts-ignore
      let boundingRect = expansionPanelParentRef.current.getBoundingClientRect();
      vidWidth = boundingRect.width - 2;
      vidHeight = Math.ceil(vidWidth * (9 / 16));
    } else {
      vidWidth = window.innerWidth * 0.7;
      vidHeight = Math.ceil(window.innerWidth * 0.7 * (9 / 16));
    }
    return { width: vidWidth, height: vidHeight };
  };

  const listData = videos.map((video, idx) => ({
    title: video.title,
    content: (
      <YouTubeWrapper>
        {loadedVideos.includes(video.title) ? (
          <></>
        ) : (
          <CircularProgressContainer>
            <StyledCircularProgress />
          </CircularProgressContainer>
        )}
        {expansionPanelReady && (
          <YouTube
            videoId={video.youtube_id}
            // @ts-ignore
            opts={{ ...playerOpts, ...getPlayerDims() }}
            onReady={event => onVideoReady(event, video.title)}
            onStateChange={event => onVideoStateChange(event, video.title)}
          />
        )}
      </YouTubeWrapper>
    )
  }));

  useEffect(() => {
    setNavTitle('Baza Wiedzy');
    if (!videos || !videos.length) getVideosList();
  }, []);

  useEffect(() => {
    if (expansionPanelParentRef.current && !expansionPanelReady) {
      // to fix incorrect loading state
      setExpansionPanelReady(true);
    }
  });

  return (
    <div ref={expansionPanelParentRef}>
      <CustomExpansionPanel listData={listData} onExpandClick={title => {}} />
    </div>
  );
}

export default KnowledgeBase;
