import { RequestStatus } from "../utils/RequestStatus";
import axios from "axios";
import {
  SET_WEATHER_ALERTS_DATA,
  SET_WEATHER_ALERTS_FETCH_STATUS, SET_WEATHER_FORECAST_DATA,
  SET_WEATHER_FORECAST_FETCH_STATUS
} from "./weather.actionTypes";


export function getWeatherForecast(data) {
  let url = process.env.REACT_APP_API_URL + `/weather_forecast/`;
  return function (dispatch) {
    dispatch(setWeatherForecastFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setWeatherForecastData(response.data));
        dispatch(setWeatherForecastFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setWeatherForecastFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

const setWeatherForecastFetchStatus = status => {
  return {
    type: SET_WEATHER_FORECAST_FETCH_STATUS,
    payload: status
  };
};

const setWeatherForecastData = data => {
  return {
    type: SET_WEATHER_FORECAST_DATA,
    payload: data
  };
};

export function getWeatherAlerts(data) {
  let url = process.env.REACT_APP_API_URL + `/weather_alerts/`;
  return function (dispatch) {
    dispatch(setWeatherAlertsFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setWeatherAlertsData(response.data));
        dispatch(setWeatherAlertsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setWeatherAlertsFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

const setWeatherAlertsFetchStatus = status => {
  return {
    type: SET_WEATHER_ALERTS_FETCH_STATUS,
    payload: status
  };
};

const setWeatherAlertsData = data => {
  return {
    type: SET_WEATHER_ALERTS_DATA,
    payload: data
  };
};
