import axios from 'axios';
import { RequestStatus } from "../utils/RequestStatus";
import {
  SET_PETITION_DETAILS,
  SET_PETITION_DETAILS_FETCH_STATUS,
  SET_PETITION_POST_STATUS, SET_SAVE_ANSWER_POST_STATUS, SET_SURVEY_LIST, SET_SURVEYS_FETCH_STATUS
} from "./your-voice.actionTypes";
import {SNACKBAR_STATUS} from "./utils.actionTypes";


export function getSurveyList(params) {
  let qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  let url = process.env.REACT_APP_API_URL + `/surveys/?${qs}`;
  return function (dispatch) {
    dispatch(setSurveysFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setSurveyList(response.data));
        dispatch(setSurveysFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setSurveysFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

export const setSurveysFetchStatus = status => {
  return {
    type: SET_SURVEYS_FETCH_STATUS,
    payload: status
  };
};

export const setSurveyList = data => {
  return {
    type: SET_SURVEY_LIST,
    payload: data
  };
};


////////////////////////////////////


export function postPetition(data, photos) {
  let url = process.env.REACT_APP_API_URL + `/surveys/`;
  return function (dispatch) {
    dispatch(setPetitionPostStatus(RequestStatus.status.FETCHING));
    let formData = null;
    if (photos) {
      formData = new FormData();
      Object.keys(photos).forEach(key => {
        if (photos[key])
          formData.append(key, new Blob(photos[key], {type: photos[key][0].type}), photos[key][0].name);

      });
      Object.keys(data).filter(key => key.indexOf('image') === -1).forEach(key => {
        formData.set(key, data[key]);
      });
    }
    axios
      .post(url, formData || data)
      .then(response => {
        dispatch(setPetitionData(response.data));
        dispatch(setPetitionPostStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch({type: SNACKBAR_STATUS, payload: {status: true, content: 'Nie można dodać postulatu. Upewnij się, że wszystkie pola są wypełnione poprawnie i spróbuj ponownie.'}});
        dispatch(setPetitionPostStatus(RequestStatus.status.ERROR));
      });
  };
}

export function getPetitionDetails(id) {
  let url = process.env.REACT_APP_API_URL + `/surveys/${id}/`;
  return function (dispatch) {
    dispatch(setPetitionDetailsFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setPetitionData(response.data));
        dispatch(setPetitionDetailsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setPetitionDetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

export function saveAnswer(id, data) {
  let url = process.env.REACT_APP_API_URL + `/surveys/${id}/submit/`;
  return function (dispatch) {
    dispatch(setSaveAnswerPostStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        // dispatch(setPetitionData(response.data));
        dispatch(setSaveAnswerPostStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch({type: SNACKBAR_STATUS, payload: {status: true, content: 'Wystąpił problem z wysłaniem głosu!'}});
        dispatch(setSaveAnswerPostStatus(RequestStatus.status.ERROR));
      });
  };
}

export const setPetitionPostStatus = status => {
  return {
    type: SET_PETITION_POST_STATUS,
    payload: status
  };
};

export const setPetitionDetailsFetchStatus = status => {
  return {
    type: SET_PETITION_DETAILS_FETCH_STATUS,
    payload: status
  };
};

export const setSaveAnswerPostStatus = status => {
  return {
    type: SET_SAVE_ANSWER_POST_STATUS,
    payload: status
  };
};

export const setPetitionData = status => {
  return {
    type: SET_PETITION_DETAILS,
    payload: status
  };
};
