import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { connect, useDispatch } from 'react-redux';
import {
  changePasswordAction,
  getAuthInfo,
  setChangePasswordPostStatus,
  setIsLoggedStatus,
  updateUserData,
  updateToken,
  setUserPatchStatus
} from '../../reducers/auth.actions';
import { Field, Form } from 'react-final-form';
import FormGroup from '@material-ui/core/FormGroup';
import { SET_MENU_OPEN_STATUS, SNACKBAR_STATUS } from '../../reducers/utils.actionTypes';
import { RequestStatus } from '../../utils/RequestStatus';
import Profile from '../../containers/Profile/Profile';
import { colors } from '../../theme';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import firebase from 'firebase';
import { deleteDevice } from '../../reducers/notifications.actions';
import { composeValidators, minLength, required } from '../../utils/formValidators';
import {
  CloseIconStyle,
  FormContainer,
  FormGroupStyles,
  FormStyles,
  LabelError,
  PrimaryButton,
  PrimaryFieldLabel,
  PrimaryInput,
  RequiredFieldMessage,
  SecondaryButton
} from '../../utils/uiComponents';
import { getKnowledgeBase } from '../../routes';

const DrawerContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  position: relative;
  background: ${props => props.theme.primaryColor};
  display: grid;
  min-height: 100vh;
  grid-template-columns: 100%;
  justify-items: center;
`;

const ButtonContainer = styled.div`
  display: grid;
  justify-items: center;
  padding: 50px 0;
  grid-template-columns: 80%;
  justify-content: center;
  max-width: 968px;
  width: 100%;
`;

const Container = styled.div`
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  align-self: center;
  position: relative;
  max-width: 968px;
  width: 100%;
`;

const MainHeader = styled.div`
  color: white;
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

const useStyles = makeStyles({
  paper: {
    background: colors.primaryColor
  }
});

const CustomDrawer = props => {
  const classes = useStyles({});
  const {
    showMenu,
    history,
    callback,
    setIsLoggedStatus,
    user,
    changePasswordPostStatus,
    userPatchStatus,
    setUserPatchStatus
  } = props;
  const [tab, setTab] = useState('main');
  const [userData, setUserData] = useState(user);
  const [template, setTemplate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !user.phone) {
      if (history.location.pathname.indexOf('phone-verification') >= 0) {
        setTab('profile');
      }
    }
  });

  useEffect(() => {
    if (showMenu) {
      const query = new URLSearchParams(history.location.search);
      if (+query.get('from_fb') === 1) {
        setTab('profile');
        history.replace(history.location.pathname, {});
      }
    }
  }, [showMenu]);

  const [photo, setPhoto] = useState(null);
  const [photosToSend, setPhotosToSend] = useState({});

  useEffect(() => {
    setPhotosToSend(Object.assign({}, photosToSend, photo));
  }, [photo]);

  useEffect(() => {}, [photosToSend]);

  const onSubmit = (values, photosToSend) => {
    let data = Object.assign({}, values);
    if (data.birth_date) {
      data.birth_date = moment(data.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    if (data.phone) {
      data.phone = data.phone.replace(/-/gi, '');
    } else {
      data.phone = null;
    }

    if (!Object.keys(photosToSend).length) {
      if (user.photo) {
        data.photo = null;
      }
    }
    dispatch(updateUserData(data, photosToSend));
  };

  const onPasswordChangeSubmit = data => {
    dispatch(changePasswordAction(data));
  };

  useEffect(() => {
    const userCloned = Object.assign({}, user);
    if (userCloned.birth_date) {
      userCloned.birth_date = moment(userCloned.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    if (userCloned.phone) {
      let phone = userCloned.phone.match(/.{1,3}/g);
      userCloned.phone = phone.join('-');
    } else {
      userCloned.phone = '+48';
    }

    setUserData(userCloned);
  }, [user]);

  useEffect(() => {
    if (userPatchStatus === RequestStatus.status.DONE) {
      if (!user.is_phone_verified) {
        dispatch(setUserPatchStatus(RequestStatus.status.NULL));
        history.push('phone-verification');
        dispatch({ type: SET_MENU_OPEN_STATUS, payload: false });
        //Only for visual effect
        setTimeout(() => {
          setTab('main');
        }, 2000);
      }
    }
  }, [userPatchStatus]);

  useEffect(() => {
    if (changePasswordPostStatus === RequestStatus.status.DONE) {
      setTab('main');
      dispatch(setChangePasswordPostStatus(RequestStatus.status.NULL));
    }
  }, [changePasswordPostStatus]);

  useEffect(() => {
    let formType;
    switch (tab) {
      case 'main':
        formType = (
          <React.Fragment>
            <CloseIconStyle style={{ fontSize: 40 }} onClick={() => callback(false)} />
            <Container>
              <ButtonContainer>
                <SecondaryButton onClick={() => setTab('profile')}>Mój profil</SecondaryButton>
                <SecondaryButton onClick={() => openPage(getKnowledgeBase())}>
                  Baza wiedzy
                </SecondaryButton>
                <SecondaryButton onClick={() => openPage('/settings')}>Ustawienia</SecondaryButton>

                <SecondaryButton
                  onClick={() => {
                    dispatch({ type: SET_MENU_OPEN_STATUS, payload: false });
                    openPage('/help');
                  }}
                >
                  Pomoc
                </SecondaryButton>
                <SecondaryButton onClick={() => logout()}>Wyloguj</SecondaryButton>
              </ButtonContainer>
            </Container>
          </React.Fragment>
        );
        break;
      case 'profile':
        formType = (
          <React.Fragment>
            <Container>
              <CloseIconStyle
                style={{ fontSize: 40 }}
                onClick={() => {
                  setTab('main');
                  callback(false);
                }}
              />
              <Profile userData={userData} setTab={setTab} onSubmit={onSubmit} {...props} />
            </Container>
          </React.Fragment>
        );
        break;

      case 'change-password':
        formType = (
          <React.Fragment>
            <CloseIconStyle style={{ fontSize: 40 }} onClick={() => setTab('main')} />
            <Container>
              <div style={{ paddingBottom: '25px' }}>
                <MainHeader> Zmiana Hasła</MainHeader>
                {/*<Form style={{margin: '30px'}}*/}
                <Form
                  onSubmit={onPasswordChangeSubmit}
                  initialValues={userData}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <FormStyles
                      onSubmit={handleSubmit}
                      style={{
                        gridTemplateColumns: '80%',
                        justifyContent: 'center'
                      }}
                    >
                      <FormGroupStyles>
                        <PrimaryFieldLabel>Stare Hasło*</PrimaryFieldLabel>
                        <Field name="old_password" validate={composeValidators(required)}>
                          {({ input, meta }) => (
                            <React.Fragment>
                              <PrimaryInput type="password" {...input} />
                              {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                            </React.Fragment>
                          )}
                        </Field>
                      </FormGroupStyles>

                      <FormGroupStyles>
                        <PrimaryFieldLabel>Nowe Hasło*</PrimaryFieldLabel>
                        <Field
                          name="new_password1"
                          validate={composeValidators(required, minLength(8))}
                        >
                          {({ input, meta }) => (
                            <React.Fragment>
                              <PrimaryInput type="password" {...input} />
                              {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                            </React.Fragment>
                          )}
                        </Field>
                      </FormGroupStyles>

                      <FormGroupStyles>
                        <PrimaryFieldLabel>Potwierdź Nowe Hasło*</PrimaryFieldLabel>
                        <Field
                          name="new_password2"
                          validate={composeValidators(required, minLength(8))}
                        >
                          {({ input, meta }) => (
                            <React.Fragment>
                              <PrimaryInput type="password" {...input} />
                              {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                            </React.Fragment>
                          )}
                        </Field>
                      </FormGroupStyles>

                      <FormGroup>
                        <PrimaryButton type="submit">Zmień hasło</PrimaryButton>
                      </FormGroup>
                    </FormStyles>
                  )}
                />

                <RequiredFieldMessage>* Pola obowiązkowe</RequiredFieldMessage>
              </div>
            </Container>
          </React.Fragment>
        );
        break;

      default:
        break;
    }

    setTemplate(formType);

    if (tab === 'profile') {
      dispatch(getAuthInfo());
    }
  }, [tab]);

  const openPage = url => {
    history.push(url);
    callback(false);
  };

  const logout = async () => {
    dispatch(updateToken());
    dispatch(setIsLoggedStatus(false));
    dispatch({
      type: SNACKBAR_STATUS,
      payload: { status: true, content: 'Wylogowano pomyślnie!' }
    });
    unregisterDevice();
    history.push('/login');
  };

  const unregisterDevice = async () => {
    const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
    if (!messaging) return;

    await messaging.requestPermission();
    const token = await messaging.getToken();
    if (token) dispatch(deleteDevice(token));
  };

  return (
    <Drawer
      open={showMenu}
      style={{ background: colors.primaryColor }}
      classes={{
        paper: classes.paper
      }}
    >
      <DrawerContainer>
        {/*    <FlexContainer>*/}
        {template}
        {/*</FlexContainer>*/}
      </DrawerContainer>
    </Drawer>
  );
};

function mapStateToProps(state, otherProps) {
  return {
    user: state.auth.user,
    changePasswordPostStatus: state.auth.changePasswordPostStatus,
    userPatchStatus: state.auth.userPatchStatus
  };
}

export default connect(mapStateToProps, {
  setIsLoggedStatus,
  setUserPatchStatus
})(CustomDrawer);
