import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { getNewsList } from '../routes'

const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    (
      rest['location']['pathname'] !== '/' ?
        <Route {...rest} render={props => (
          (rest.isLogged || rest.isLogged === null) ?
            <Component {...props} />
            : <Redirect to="/login"/>
        )}/>
        :
        <Redirect to={getNewsList()}/>
    )

  );
};

const mapStateToProps = (state, otherProps) => ({
  isLogged: state.auth.isLogged
});
export default connect(
  mapStateToProps,
  {}
)(PrivateRoute);
