import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { SET_HEADER_PRIMARY_TEXT } from "../../reducers/utils.actionTypes";
import { useHistory } from "react-router-dom";
import styled from "styled-components";


const Container = styled.div`
//width:100vw;
//max-width:100%;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 80%;
`;


const ButtonContainer = styled.div`
display: grid;
//width:70vw;
`;

const MainButton = styled.div`
border: 3px solid ${props => props.theme.secondaryColor};
border-radius: 40px;
min-height: 45px;
width: 100%;
justify-content: center;
align-items:center;
display:grid;
font-size:24px;
font-weight: bold;
margin:15px 0;
position: relative;
text-transform: uppercase;
text-align: center;
padding:7px 10px;
box-sizing: border-box;
cursor: pointer;
`;

const HeaderContainer = styled.div`
    text-align: center;
    margin: 5px 0 40px 0;
    font-size: 23px;
    text-transform: uppercase;
    display: block;
    padding-top: 15px;
    font-weight: bold;
`;

const HeaderLabel = styled.div`
display: initial;
border-bottom: 4px solid ${props => props.theme.secondaryColor};
padding-bottom:10px;
`;

function YourVoiceList() {
  let history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({type: SET_HEADER_PRIMARY_TEXT, payload: 'Twój głos'})
  }, []);

  const openPage = (url) => {
    history.push(url);
  };

  return (
    <React.Fragment>
      <Container>
        <HeaderContainer>
          <HeaderLabel>Trwające</HeaderLabel>
        </HeaderContainer>
        <ButtonContainer>
          <MainButton onClick={() => openPage('/your-voice/votings/?survey_type=surveys.simplesurvey')}>
            Sondaże
          </MainButton>
          <MainButton onClick={() => openPage('/your-voice/votings/?survey_type=surveys.extendedsurvey')}>
            Ankiety
          </MainButton>
          <MainButton onClick={() => openPage('/your-voice/contact-messages/add?type=1')}>
            Zgłoszenie
          </MainButton>
          <MainButton onClick={() => openPage('/your-voice/petitions/add')}>
            Dodaj Postulat
          </MainButton>
          <MainButton style={{opacity: 0.3}}>
            Budżet obywatelski
          </MainButton>
        </ButtonContainer>
      </Container>
    </React.Fragment>
  );
}

export default YourVoiceList;
// YourVoiceList.whyDidYouRender = true;
