import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  loginAction,
  phoneLoginAction,
  phoneLoginConfirmationAction,
  registerAction,
  sendPasswordReset,
  setPasswordResetPostStatus,
  setPhoneLoginData,
  setPhoneLoginStatus,
  updateToken
} from '../../reducers/auth.actions';
import { SET_MENU_OPEN_STATUS } from '../../reducers/utils.actionTypes';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { RequestStatus } from '../../utils/RequestStatus';
import moment from 'moment';
import {
  birthDateValidator,
  composeValidators,
  minLength,
  required
} from '../../utils/formValidators';
import {
  BoxButton,
  CloseIconStyle,
  FormContainer,
  FormGroupStyles,
  FormStyles,
  LabelError,
  PrimaryButton,
  PrimaryFieldLabel,
  PrimaryInput,
  RequiredFieldMessage,
  SecondaryButton
} from '../../utils/uiComponents';
import SignInHelper from '../../components/SignInHelper/SignInHelper';
import {
  AdditionalButton,
  ButtonContainer,
  ButtonIcon,
  Container,
  FormContainerStyles,
  LogByLabel,
  LoginPage,
  LogoContainer,
  LogoImg,
  MenuBarPlaceholder,
  Paragraph,
  TabName,
  WhiteText
} from './LoginParts';
import LoginMain from './LoginMain';
import LoginAgreement from './LoginAgreement';
import LoginAcceptAgreement from './LoginAcceptAgreement';
import { getNewsList } from '../../routes';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy', {
  maxYear: new Date().getFullYear()
});

function Login(props) {
  const { isLogged, passwordResetPostStatus, phoneLoginFetchStatus, phoneLoginData } = props;
  const [tab, setTab] = useState('main');
  const [template, setTemplate] = useState(null);
  const [phoneLoginResponse, setPhoneLoginResponse] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogged) {
      history.push(getNewsList());
      dispatch({ type: SET_MENU_OPEN_STATUS, payload: false });
    }
  });

  useEffect(() => {
    if (passwordResetPostStatus === RequestStatus.status.DONE) {
      setTab('main');
      dispatch(setPasswordResetPostStatus(RequestStatus.status.NULL));
    }
  }, [passwordResetPostStatus]);

  useEffect(() => {
    if (phoneLoginFetchStatus === RequestStatus.status.DONE) {
      setPhoneLoginResponse(phoneLoginData);
      setTab('phone-login-confirmation');
      dispatch(setPhoneLoginStatus(RequestStatus.status.NULL));
      dispatch(setPhoneLoginData(null));
    }
  }, [phoneLoginFetchStatus]);

  const onSubmitLogin = data => {
    dispatch(loginAction(data));
  };

  const onSubmitPhoneLogin = data => {
    setPhoneNumber(data);
    dispatch(phoneLoginAction(data));
  };

  const onSubmitPhoneLoginConfirmation = data => {
    dispatch(phoneLoginConfirmationAction(data));
  };

  const resendSMSCode = data => {
    dispatch(phoneLoginAction(data));
  };

  const onSubmitRegistration = data => {
    let payload = { ...data };
    if (payload.birth_date) {
      payload.birth_date = moment(payload.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (data.is_agreement_accepted) delete data.is_agreement_accepted;
    dispatch(registerAction(payload));
  };

  const onSubmitForgotPassword = data => {
    dispatch(sendPasswordReset(data));
  };

  const handleFacebookLogin = () => {
    const domain = process.env.REACT_APP_API_URL.split('/')
        .splice(0, 3)
        .join('/'),
      url = domain + '/accounts/facebook/login/';

    let listener = window.addEventListener('message', event => {
      if (!(event.data && event.data.sessionKey && event.origin.startsWith(domain))) {
        return;
      }

      dispatch(updateToken({ key: event.data.sessionKey }));
    });

    const popup = window.open(url, '_fb_login', 'width=500,height=500');
    let timer = setInterval(function() {
      if (popup.closed) {
        clearInterval(timer);
        window.location.href = getNewsList() + '?from_fb=1';
      }
    }, 1000);
  };

  useEffect(() => {
    let formType;
    switch (tab) {
      case 'main':
        formType = <LoginMain handleFacebookLogin={handleFacebookLogin} setTab={setTab} />;
        break;
      case 'login':
        formType = (
          <Container>
            <CloseIconStyle style={{ fontSize: 40 }} onClick={() => setTab('main')} />

            <LogoContainer>
              <LogoImg src={`images/logo.png`} alt="Logo" />
            </LogoContainer>
            <FormContainerStyles>
              <TabName>Logowanie</TabName>
              {/*<Form style={{marginTop: '30px'}}*/}
              <Form
                onSubmit={onSubmitLogin}
                initialValues={{ email: '', password: '' }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <FormStyles onSubmit={handleSubmit}>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>E-mail*</PrimaryFieldLabel>
                      <Field name="email" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput
                              type="email"
                              // placeholder="E-mail"
                              {...input}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>Hasło*</PrimaryFieldLabel>
                      <Field name="password" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput
                              type="password"
                              // placeholder="Hasło"
                              {...input}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryButton type="submit">Zaloguj</PrimaryButton>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <SignInHelper setTab={setTab} />
                    </FormGroupStyles>
                  </FormStyles>
                )}
              />
              <RequiredFieldMessage>* Pola obowiązkowe</RequiredFieldMessage>
            </FormContainerStyles>
          </Container>
        );
        break;
      case 'phone-login':
        formType = (
          <Container>
            <CloseIconStyle style={{ fontSize: 40 }} onClick={() => setTab('main')} />

            <LogoContainer>
              <LogoImg src={`images/logo.png`} alt="Logo" />
            </LogoContainer>
            <FormContainerStyles>
              <TabName>Logowanie</TabName>
              {/*<Form style={{marginTop: '30px'}}*/}
              <Form
                onSubmit={onSubmitPhoneLogin}
                initialValues={{ phone: '+48' }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <FormStyles onSubmit={handleSubmit}>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>Numer telefonu*</PrimaryFieldLabel>
                      <Field name="phone" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <MaskedInput
                              //@ts-ignore
                              mask={s => ['+'].concat(Array.from(s).map(() => /[0-9]/i))}
                              placeholder="Numer telefonu"
                              id="phone-id"
                              guide={false}
                              render={(ref, props) => (
                                <PrimaryInput
                                  name="phone"
                                  defaultValue={input.value}
                                  ref={inputRef => ref(inputRef)}
                                  placeholder={props.placeholder}
                                  onChange={e => {
                                    props.onChange(e);
                                    input.onChange(e);
                                  }}
                                />
                              )}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryButton type="submit">Wyślij kod SMS</PrimaryButton>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <SignInHelper setTab={setTab} />
                    </FormGroupStyles>
                  </FormStyles>
                )}
              />
              <RequiredFieldMessage>* Pola obowiązkowe</RequiredFieldMessage>
            </FormContainerStyles>
          </Container>
        );
        break;
      case 'phone-login-confirmation':
        formType = (
          <Container>
            <CloseIconStyle style={{ fontSize: 40 }} onClick={() => setTab('main')} />

            <LogoContainer>
              <LogoImg src={`images/logo.png`} alt="Logo" />
            </LogoContainer>
            <FormContainerStyles>
              <TabName>Logowanie</TabName>

              <Paragraph>Wprowadź {phoneLoginResponse.label}</Paragraph>

              {/*<Form style={{marginTop: '30px'}}*/}
              <Form
                onSubmit={onSubmitPhoneLoginConfirmation}
                initialValues={{ id: phoneLoginResponse.id, raw_code: '' }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <FormStyles onSubmit={handleSubmit}>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>Wprowadź otrzymany kod</PrimaryFieldLabel>
                      <Field name="raw_code" validate={composeValidators(required, minLength(6))}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <MaskedInput
                              mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                              placeholder="Kod"
                              id="raw_code-id"
                              guide={false}
                              render={(ref, props) => (
                                <PrimaryInput
                                  name="raw_code"
                                  defaultValue={input.value}
                                  ref={inputRef => ref(inputRef)}
                                  placeholder={props.placeholder}
                                  onChange={e => {
                                    props.onChange(e);
                                    input.onChange(e);
                                  }}
                                />
                              )}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryButton type="submit">Zaloguj</PrimaryButton>
                    </FormGroupStyles>

                    <FormGroupStyles>
                      <div
                        onClick={() => {
                          resendSMSCode(phoneNumber);
                        }}
                      >
                        Wyślij SMS weryfikacyjny ponownie
                      </div>
                    </FormGroupStyles>
                  </FormStyles>
                )}
              />
            </FormContainerStyles>
          </Container>
        );
        break;

      case 'register':
        formType = (
          <Container>
            <CloseIconStyle style={{ fontSize: 40 }} onClick={() => setTab('main')} />
            <LogoContainer>
              <LogoImg src={`images/logo.png`} alt="Logo" />
            </LogoContainer>
            <FormContainerStyles>
              <TabName>Rejestracja</TabName>
              {/*<Form style={{margin: '30px'}}*/}
              <Form
                mutators={{
                  setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                  }
                }}
                onSubmit={onSubmitRegistration}
                initialValues={{
                  email: '',
                  password1: '',
                  password2: '',
                  first_name: '',
                  last_name: '',
                  phone: '+48',
                  is_resident: false
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <FormStyles onSubmit={handleSubmit}>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>Imię*</PrimaryFieldLabel>
                      <Field name="first_name" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput type="text" placeholder={'Imię'} {...input} />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles>
                      <PrimaryFieldLabel>Nazwisko*</PrimaryFieldLabel>
                      <Field name="last_name" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput type="text" placeholder={'Nazwisko'} {...input} />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>E-mail*</PrimaryFieldLabel>
                      <Field name="email" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput
                              type="email"
                              // placeholder="E-mail"
                              {...input}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>Data urodzenia</PrimaryFieldLabel>
                      <Field name="birth_date" validate={composeValidators(birthDateValidator)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <MaskedInput
                              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                              pipe={autoCorrectedDatePipe}
                              placeholder="Data urodzenia"
                              keepCharPositions={true}
                              id="birth-date-id"
                              render={(ref, props) => (
                                <PrimaryInput
                                  name="birth_date"
                                  ref={inputRef => ref(inputRef)}
                                  onChange={e => {
                                    props.onChange(e);
                                    input.onChange(e);
                                  }}
                                  defaultValue={input.value}
                                  placeholder={props.placeholder}
                                />
                              )}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles>
                      <PrimaryFieldLabel>Telefon*</PrimaryFieldLabel>
                      <Field name="phone" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <MaskedInput
                              //@ts-ignore
                              mask={s => ['+'].concat(Array.from(s).map(() => /[0-9]/i))}
                              placeholder="Telefon"
                              id="phone-id"
                              guide={false}
                              render={(ref, props) => (
                                <PrimaryInput
                                  name="phone"
                                  defaultValue={input.value}
                                  ref={inputRef => ref(inputRef)}
                                  placeholder={props.placeholder}
                                  onChange={e => {
                                    props.onChange(e);
                                    input.onChange(e);
                                  }}
                                />
                              )}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>Hasło*</PrimaryFieldLabel>
                      <Field name="password1" validate={composeValidators(required, minLength(8))}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput
                              type="password"
                              // placeholder="Hasło"
                              {...input}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>Powtórz Hasło*</PrimaryFieldLabel>
                      <Field name="password2" validate={composeValidators(required, minLength(8))}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput
                              type="password"
                              // placeholder="Hasło"
                              {...input}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles style={{ marginTop: '10px' }}>
                      <Field name="is_resident">
                        {({ input, meta }) => <LoginAgreement form={form} values={values} />}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles style={{ marginTop: '10px' }}>
                      <Field name="is_agreement_accepted" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <>
                            <LoginAcceptAgreement form={form} values={values} />{' '}
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </>
                        )}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles>
                      <PrimaryButton type="submit">Zarejestruj</PrimaryButton>
                    </FormGroupStyles>
                  </FormStyles>
                )}
              />
              <RequiredFieldMessage>* Pola obowiązkowe</RequiredFieldMessage>
            </FormContainerStyles>
          </Container>
        );
        break;
      case 'forgot-password':
        formType = (
          <Container>
            <CloseIconStyle style={{ fontSize: 40 }} onClick={() => setTab('main')} />

            <LogoContainer>
              <LogoImg src={`images/logo.png`} alt="Logo" />
            </LogoContainer>
            <FormContainerStyles>
              <TabName>Przypomnij hasło</TabName>
              {/*<Form style={{margin: '30px'}}*/}
              <Form
                onSubmit={onSubmitForgotPassword}
                initialValues={{ email: '', password1: '', password2: '' }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <FormStyles onSubmit={handleSubmit}>
                    <FormGroupStyles>
                      <PrimaryFieldLabel>E-mail*</PrimaryFieldLabel>
                      <Field name="email" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <React.Fragment>
                            <PrimaryInput
                              type="email"
                              // placeholder="E-mail"
                              {...input}
                            />
                            {meta.error && meta.touched && <LabelError>{meta.error}</LabelError>}
                          </React.Fragment>
                        )}
                      </Field>
                    </FormGroupStyles>

                    <FormGroupStyles>
                      <PrimaryButton type="submit">Przypomnij</PrimaryButton>
                    </FormGroupStyles>
                  </FormStyles>
                )}
              />
              <RequiredFieldMessage>* Pola obowiązkowe</RequiredFieldMessage>
            </FormContainerStyles>
          </Container>
        );
        break;

      default:
        break;
    }

    setTemplate(formType);
  }, [tab]);

  return (
    <LoginPage>
      <div style={{ margin: '0 auto', width: '100vw', maxWidth: '940px' }}>{template}</div>
    </LoginPage>
  );
}

// export default Login;

function mapStateToProps(state, otherProps) {
  return {
    headerPrimaryText: state.utils.headerPrimaryText,
    headerSecondaryText: state.utils.headerSecondaryText,
    showMenu: state.utils.showMenu,
    isLogged: state.auth.isLogged,
    registrationPostStatus: state.auth.registrationPostStatus,
    passwordResetPostStatus: state.auth.passwordResetPostStatus,
    phoneLoginFetchStatus: state.auth.phoneLoginFetchStatus,
    phoneLoginData: state.auth.phoneLoginData
  };
}

export default connect(mapStateToProps, {})(Login);
