import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import { getNewsDetails, setNewsDetails } from '../../reducers/news.actions';
import { useParams } from 'react-router-dom';
import NewsDetailsView from '../../components/NewsElement/NewsDetailsView';
import styled from 'styled-components';
import { colors, sizes } from '../../theme';
import AbsoluteViewContainer from '../../components/AbsoluteViewContainer';

function NewsDetails(props) {
  const { news, setNewsDetails } = props;
  const dispatch = useDispatch();
  let { id } = useParams();

  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Informator' });
    dispatch(getNewsDetails(id));
    return () => {
      setNewsDetails(null);
    };
  }, []);

  return (
    <AbsoluteViewContainer>
      {news && news.id && <NewsDetailsView descriptionKey={'description'} news={news} />}
    </AbsoluteViewContainer>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    news: state.news.newsDetails
  };
}

export default connect(mapStateToProps, { setNewsDetails })(NewsDetails);
