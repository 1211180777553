import Bowser from 'bowser';

class Browser {
  bowserInstance = null;
  windowNavigator = null;

  static init = _windowNavigator => {
    this.windowNavigator = _windowNavigator;
    return (this.bowserInstance = Bowser.getParser(_windowNavigator.userAgent));
  };

  static get instance() {
    return this.bowserInstance;
  }

  static isMobile() {
    return this.bowserInstance && this.bowserInstance.getPlatform().type === 'mobile';
  }

  static isChrome() {
    return this.bowserInstance.getBrowserName() === 'Chrome';
  }

  static isFirefox() {
    return this.bowserInstance.getBrowserName() === 'Firefox';
  }

  static isSafari() {
    return this.bowserInstance.getBrowserName() === 'Safari';
  }

  // Workaround according to https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
  static isIPad() {
    return (
      this.windowNavigator &&
      this.windowNavigator.userAgent.match(/Mac/) &&
      this.windowNavigator.maxTouchPoints &&
      this.windowNavigator.maxTouchPoints > 2
    );
  }

  static isMobileOrIPad() {
    return this.isMobile() || this.isIPad();
  }

  static isAppleDevice() {
    return this.bowserInstance.getPlatform().vendor === 'Apple';
  }

  static isMobileSafari() {
    return this.isMobileOrIPad() && this.isAppleDevice();
  }

  static isBottomSpaceNeeded() {
    return this.isMobileSafari() || this.isSafari() || this.isFirefox();
  }
}

export default Browser;
