import React, { useEffect, useState } from 'react';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getWeatherAlerts, getWeatherForecast } from '../../reducers/weather.actions';
import moment from 'moment';
import {targetName} from "../../app.constants";

const HeaderContainer = styled.div`
  text-align: center;
  margin: 15px 0 40px 0;
  font-size: 25px;
  text-transform: uppercase;
  display: block;
  padding-top: 15px;
  font-weight: bold;
`;

const HeaderLabel = styled.div`
  display: initial;
  border-bottom: 4px solid ${props => props.theme.secondaryColor};
  padding-bottom: 10px;
`;

const MessageContainer = styled.div`
  font-size: 20px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const MessageText = styled.div`
  display: initial;
  border-radius: 25px;
  border: 3px solid orange;
  text-align: center;
  width: 100%;
  padding: 15px;
`;

const WeatherRow = styled.div`
  display: flex;
  width: 100vw;
  max-width: 100%;
  margin: 10px 0;
  padding: 10px 0;
  overflow: scroll;
`;

const WeatherElement = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
  grid-row-gap: 5px;
  font-size: 20px;
`;

const WeatherIcon = styled.img`
  height: 70px;
  width: 100px;
  object-fit: contain;
`;

const Hours = styled.div`
  color: ${props => props.theme.secondaryColor};
`;

const DayLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin: 40px 0px 5px 0;
`;

function WeatherForecast(props) {
  const { weatherForecast, weatherAlerts } = props;
  const dispatch = useDispatch();
  const [preparedWeather, setPreparedWeather] = useState([]);
  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Pogoda' });
    dispatch(getWeatherForecast({}));
    dispatch(getWeatherAlerts({}));
  }, []);

  useEffect(() => {
    let weather = [];
    if (!weatherForecast) return;
    weatherForecast.forEach(w => {
      let day = weather.find(x => x.date === moment(w.date_ts * 1000).format('DD-MM-YYYY'));
      if (day) {
        day.objects.push(w);
      } else {
        weather.push({ date: moment(w.date_ts * 1000).format('DD-MM-YYYY'), objects: [w] });
      }
    });
    setPreparedWeather(weather);
  }, [weatherForecast]);

  return (
    <div>
      <HeaderContainer>
        <HeaderLabel>{targetName}</HeaderLabel>
      </HeaderContainer>
      {!!weatherAlerts &&
        !!weatherAlerts.length &&
        weatherAlerts.map(alert => (
          <MessageContainer key={alert.id}>
            <MessageText>{alert.message}</MessageText>
          </MessageContainer>
        ))}

      {!!preparedWeather &&
        !!preparedWeather.length &&
        preparedWeather.map((day, i) => (
          <React.Fragment key={i}>
            <DayLabel>
              {moment(day.date, 'DD-MM-YYYY').format('dddd')}{' '}
              {moment().isSame(moment(day.date, 'DD-MM-YYYY'), 'day')
                ? '(dziś)'
                : moment(day.date, 'DD-MM-YYYY').format('(DD.MM)')}
            </DayLabel>
            <WeatherRow>
              {!!day.objects &&
                day.objects.length &&
                day.objects.map(weather => {
                  return (
                    <WeatherElement key={weather.id}>
                      <div>{weather.temperature}&deg;C</div>
                      <div>
                        <WeatherIcon src={weather.icon_url} />
                      </div>
                      <div>{weather.humidity}%</div>
                      <Hours>{moment(weather.date_ts * 1000).format('HH:mm')}</Hours>
                      <div>{weather.wind_speed} km/h</div>
                    </WeatherElement>
                  );
                })}
            </WeatherRow>
          </React.Fragment>
        ))}
    </div>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    weatherForecast: state.weather.weatherForecast,
    weatherAlerts: state.weather.weatherAlerts
  };
}

export default connect(mapStateToProps, {})(WeatherForecast);
// WeatherForecast.whyDidYouRender = true;
