export const videos = [
  {
    title: 'Instalacja aplikacji',
    ytId: 'yTuoEBc-rz0'
  },
  {
    title: 'Sondaż',
    ytId: '9mtm1xzIjZM'
  },
  {
    title: 'Zgłoszenie',
    ytId: 'OXhyrp7_bek'
  },
  {
    title: 'Informator',
    ytId: 'Pw8LDMCNgAs'
  },
  {
    title: 'Postulat',
    ytId: 'jcA5pgzpO_8'
  },
  {
    title: 'Atrakcje',
    ytId: 'QyKLxdesE38'
  },
  {
    title: 'Pogoda',
    ytId: 'FLxc45_2Aa0'
  },
  {
    title: 'Ankieta',
    ytId: '7hds1OzzRU0'
  }
];

export const playerOpts = {
  width: window.innerWidth * 0.7,
  height: window.innerWidth * 0.7 * (9/16),
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};
