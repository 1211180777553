import { connect } from 'react-redux';
import BaseTemplate from "./BaseTemplate";
import { withRouter } from 'react-router-dom';

function mapStateToProps(state, otherProps) {
  return {
    headerPrimaryText: state.utils.headerPrimaryText,
    headerSecondaryText: state.utils.headerSecondaryText,
    showMenu: state.utils.showMenu,
    user: state.auth.user,
  };
}

export default withRouter(connect(
  mapStateToProps,
  {}
)(BaseTemplate));
