import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getPetitionDetails, saveAnswer, setPetitionData } from '../../reducers/your-voice.actions';
import { RequestStatus } from '../../utils/RequestStatus';
import Share from '../../components/ShareComponent/Share';
import QuestionComponent from '../../components/QuestionComponent/QuestionComponent';
import { TextContent } from '../../components/AbsoluteViewContainer';

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  justify-content: center;
  align-items: center;
`;

function OpinionPollDetails(props) {
  const { petitionDetails, saveAnswerPostStatus } = props;
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState(null);
  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Twój głos' });
    dispatch(getPetitionDetails(id));
  }, []);

  useEffect(() => {
    if (answer) {
      dispatch(saveAnswer(id, { answers: answer }));
    }
  }, [answer]);

  useEffect(() => {
    if (saveAnswerPostStatus === RequestStatus.status.DONE) {
      history.push(`/your-voice/opinion-polls/${petitionDetails.id}/confirmation`);
    }
  }, [saveAnswerPostStatus]);

  return (
    <React.Fragment>
      <Container>
        {petitionDetails && (
          <QuestionComponent
            headerLabel={'Sondaż'}
            declarantLabel={'Sondaż zlecił'}
            details={petitionDetails}
            setAnswer={setAnswer}
          />
        )}
        <TextContent>
          <Share url={petitionDetails && petitionDetails.share_url} />
        </TextContent>
      </Container>
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    petitionDetails: state.yourVoice.petitionDetails,
    saveAnswerPostStatus: state.yourVoice.saveAnswerPostStatus
  };
}

export default connect(mapStateToProps, {
  getPetitionDetails,
  saveAnswer,
  setPetitionData
})(OpinionPollDetails);
