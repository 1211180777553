import axios from 'axios';
import { RequestStatus } from '../utils/RequestStatus';
import {
  SET_NEWS_DETAILS,
  SET_NEWS_DETAILS_FETCH_STATUS,
  SET_NEWS_LIST,
  SET_NEWS_LIST_FETCH_STATUS
} from './news.actionTypes';

export function getNewsList(data) {
  let url = process.env.REACT_APP_API_URL + `/news/`;
  return function(dispatch) {
    dispatch(setNewsListFetchStatus(RequestStatus.status.FETCHING));
    // setTimeout(() => {
    axios
      .get(url)
      .then(response => {
        dispatch(setNewsList(response.data));
        dispatch(setNewsListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setNewsListFetchStatus(RequestStatus.status.ERROR));
      });
    // }, 5000)
  };
}

const setNewsList = status => {
  return {
    type: SET_NEWS_LIST,
    payload: status
  };
};

const setNewsListFetchStatus = status => {
  return {
    type: SET_NEWS_LIST_FETCH_STATUS,
    payload: status
  };
};

//-------------------------------------------

export function getNewsDetails(id) {
  let url = process.env.REACT_APP_API_URL + `/news/${id}/`;
  return function(dispatch) {
    dispatch(setNewsDetailsFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setNewsDetails(response.data));
        dispatch(setNewsDetailsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setNewsDetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
}

export const setNewsDetails = status => {
  return {
    type: SET_NEWS_DETAILS,
    payload: status
  };
};

const setNewsDetailsFetchStatus = status => {
  return {
    type: SET_NEWS_DETAILS_FETCH_STATUS,
    payload: status
  };
};
