import React, { useEffect, useState } from 'react';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getSettingList, patchSettingList } from '../../reducers/settings.actions';
import Checkbox from '../../components/Checkbox';

const HeaderContainer = styled.div`
  text-align: center;
  margin: 15px 0 40px 0;
  font-size: 25px;
  text-transform: uppercase;
  display: block;
  padding-top: 15px;
  font-weight: bold;
`;

const HeaderLabel = styled.div`
  display: initial;
  border-bottom: 4px solid ${props => props.theme.secondaryColor};
  padding-bottom: 10px;
`;

const AnswerColumnExtended = styled.div`
  display: grid;
  grid-row-gap: 15px;
`;

const AnswerElement = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  font-size: 18px;
`;

const QuestionContainerExtended = styled.div`
  margin: 30px 0 15px 0;
  font-size: 22px;
`;

const ExtendedSurveyContainer = styled.div`
  margin: 20px;
`;

const ButtonContainer = styled.div`
  display: grid;
  justify-content: end;
`;

const CustomButton = styled.button`
  border: 2px solid ${props => props.theme.secondaryColor};
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 50px;
  height: 40px;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 20px;
  margin: 25px;
  width: 150px;
`;

function SettingsList(props) {
  const { settingList } = props;
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Ustawienia' });
    dispatch(getSettingList({}));
  }, []);

  useEffect(() => {
    setList(settingList);
  }, [settingList]);

  const setStatus = (index, key) => {
    list[index][key] = !list[index][key];
    setList([...list]);
  };
  const saveSettingList = () => {
    const response = {};
    settingList.forEach(x => {
      let answers = {};
      ['sms', 'email', 'push'].forEach(key => {
        answers[key] = x[key];
      });
      response[x.type] = answers;
    });
    dispatch(patchSettingList(response));
  };

  return (
    <div>
      <HeaderContainer>
        <HeaderLabel>Ustawienia</HeaderLabel>
      </HeaderContainer>
      {list && list.length ? (
        <React.Fragment>
          {list.map((setting, i) => (
            <ExtendedSurveyContainer key={i}>
              <QuestionContainerExtended>{setting.type_display}</QuestionContainerExtended>
              <AnswerColumnExtended>
                <AnswerElement>
                  <Checkbox onClick={() => setStatus(i, 'sms')} selected={!!setting['sms']} />
                  <div onClick={() => setStatus(i, 'sms')}>SMS</div>
                </AnswerElement>
                <AnswerElement>
                  <Checkbox onClick={() => setStatus(i, 'email')} selected={!!setting['email']} />
                  <div onClick={() => setStatus(i, 'email')}>E-MAIL</div>
                </AnswerElement>
                <AnswerElement>
                  <Checkbox onClick={() => setStatus(i, 'push')} selected={!!setting['push']} />
                  <div onClick={() => setStatus(i, 'push')}>PUSH (tylko android)</div>
                </AnswerElement>
              </AnswerColumnExtended>
            </ExtendedSurveyContainer>
          ))}
          <ButtonContainer>
            <CustomButton onClick={() => saveSettingList()}>Zapisz</CustomButton>
          </ButtonContainer>
        </React.Fragment>
      ) : (
        <></>
      )}
    </div>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    settingList: state.settings.settingList
  };
}

export default connect(mapStateToProps, {})(SettingsList);
