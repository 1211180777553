import React, { useEffect } from 'react';
import styled from "styled-components";
import ConfirmationComponent from "../../components/ConfirmationComponent/ConfirmationComponent";
import { useDispatch } from "react-redux";
import { SET_HEADER_PRIMARY_TEXT } from "../../reducers/utils.actionTypes";


const Container = styled.div`
width:100vw;
max-width:100%;
justify-content: center;
    align-items: center;
    display: grid;
`;

function SurveyConfirmation() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({type: SET_HEADER_PRIMARY_TEXT, payload: 'Twój głos'})
  }, []);
  return (
    <React.Fragment>
      <Container>
        <ConfirmationComponent
          headerLabel={'Ankieta'}
          text={'Twoja ankieta została pomyślnie wysłana!'}
        />
      </Container>
    </React.Fragment>
  );
}

export default SurveyConfirmation;
