import styled from 'styled-components';
import { TextContent } from '../AbsoluteViewContainer';

export const HeaderContainer = styled.div`
  text-align: center;
  margin: 5px 0 40px 0;
  font-size: 23px;
  text-transform: uppercase;
  display: block;
  padding-top: 15px;
  font-weight: bold;
`;

export const HeaderLabel = styled.div`
  display: initial;
  border-bottom: 4px solid ${props => props.theme.secondaryColor};
  padding-bottom: 10px;
`;

export const DeclarantLabel = styled.div`
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`;

export const DeclarantNameLabel = styled.div`
  margin-top: 5px;
  font-size: 20px;
  text-align: center;
`;

export const old_Description = styled.div`
  padding: 20px 20px 10px 20px;
  font-size: 18px;
  text-align: justify;
`;

export const Description = styled(TextContent)`
  font-size: 18px;
  text-align: justify;
`;

export const QuestionContainer = styled.div`
  background-color: ${props => props.theme.secondaryColor};
  color: white;
  padding: 15px 20px;
  margin: 30px 0 40px 0;
  text-align: center;
  font-size: 24px;
`;

export const QuestionContainerExtended = styled.div`
  margin: 30px 0 15px 0;
  font-size: 22px;
`;

export const ExtendedSurveyContainer = styled.div`
  margin: 20px;
  //font-size:22px;
`;

export const AnswerRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const AnswerColumn = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  grid-row-gap: 15px;
`;

export const AnswerColumnExtended = styled.div`
  display: grid;
  grid-row-gap: 15px;
`;

export const AnswerElement = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  font-size: 18px;
`;

export const AnswerRowExtended = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnswerContainer = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
`;

export const AnswerContainerRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  justify-content: center;
  align-items: center;
`;

export const ColoredProgress = styled.div`
  background-color: ${props => (props.yes ? props.theme.secondaryColor : props.theme.dangerColor)};
  color: white;
  padding: 10px;
  height: 30px;
  font-size: 25px;
  width: ${props => props.progress}%;
  min-width: 50px;
`;

export const AnswerButton = styled.div`
  width: 120px;
  height: 70px;
  border: 4px solid ${props => (props.yes ? props.theme.secondaryColor : props.theme.dangerColor)};
  background-color: ${props =>
    props.selected ? (props.yes ? props.theme.secondaryColor : props.theme.dangerColor) : 'white'};
  color: ${props => (props.selected ? 'white' : 'black')};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 30px;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 38px;
`;

export const GalleryContainer = styled.div`
  margin: 0 5px;
`;

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  overflow-x: scroll;
`;

export const GalleryObj = styled.div`
  justify-content: center;
  display: grid;
`;

export const GalleryImg = styled.img`
  height: 70px;
  object-fit: cover;
`;
