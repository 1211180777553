import styled from 'styled-components';
import { colors, fontSizes } from '../../theme';

export const CardTitle = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: ${fontSizes.cardTitle};
  text-transform: uppercase;
`;

export const CardDate = styled.div`
  margin-top: 7px;
  text-transform: uppercase;
  color: ${colors.accentDarkColor};
  width: 100%;
  font-size: ${fontSizes.cardDate};
  font-weight: bold;
`;

export const CardDescription = styled.div`
  margin-top: 15px;
  font-size: ${fontSizes.cardContent};
  line-height: 1.6;
  color: ${colors.descriptionColor};
  text-align: left;

  img {
    max-width: 100%;
    height: auto !important;

    &.replaced {
      height: 0px !important;
      margin: 0px !important;
      padding: 0px !important;
    }
  }
`;
