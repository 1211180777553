import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getBrowserLocation } from '../../utils/geolocation';
import { PrimaryFlexButton } from '../../utils/uiComponents';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { colors } from '../../theme';

const StyledPrimaryFlexButton = styled(PrimaryFlexButton)`
  margin: 1px;
`;

const IconContainer = styled.div`
  color: ${colors.descriptionColor};
  cursor: pointer;
  background-color: white;
  border-radius: 2px;
  background-color: white;
  border-radius: 2px;
  font-size: 30px;
  margin: 0px 5px 2px 0px;
  width: 32px;
  height: 36px;
  padding: 0px;
  padding-top: 4px;
  padding-left: 8px;
`;

function BrowserLocation(props) {
  const { onChange, icon } = props;

  const getLocation = event => {
    event.preventDefault();
    getBrowserLocation().then(location => {
      onChange(location);
    });
  };

  return (
    <div>
      {icon ? (
        <IconContainer onClick={getLocation}>
          <MyLocationIcon />
        </IconContainer>
      ) : (
        <StyledPrimaryFlexButton onClick={getLocation}>Pobierz lokalizację</StyledPrimaryFlexButton>
      )}
    </div>
  );
}

export default BrowserLocation;
