import {
  SET_KNOWLEDGE_BASE_FETCH_STATUS,
  UPDATE_KNOWLEDGE_BASE_LOGIN_LINK,
  UPDATE_KNOWLEDGE_BASE_VIDEOS
} from './knowledge-base.actionTypes';

export const INITIAL_STATE: any = {
  fetchStatus: null,
  knowledgeBaseVideos: [],
  knowledgeBaseLink: null
};

export default function events(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_KNOWLEDGE_BASE_FETCH_STATUS:
      return { ...state, fetchStatus: action.payload };
    case UPDATE_KNOWLEDGE_BASE_VIDEOS:
      return { ...state, knowledgeBaseVideos: action.payload };
    case UPDATE_KNOWLEDGE_BASE_LOGIN_LINK:
      return { ...state, knowledgeBaseLink: action.payload };
    default:
      return state;
  }
}
