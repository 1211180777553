import React, { useEffect, useRef, useState } from 'react';

/**
 * 'useState' wrapper. Use it instead of useState to always get current object value in functions.
 * Can cause problems in some async cases!
 * Based on: https://dev.to/scastiel/react-hooks-get-the-current-state-back-to-the-future-3op2
 * @param initialValue
 * @returns {(any | React.MutableRefObject<any> | React.Dispatch<React.SetStateAction<any>>)[]}
 */
export const useRefState = initialValue => {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};
