export const LOGIN_REQUEST_FETCH_STATUS = 'LOGIN_REQUEST_FETCH_STATUS';
export const PHONE_LOGIN_REQUEST_FETCH_STATUS = 'PHONE_LOGIN_REQUEST_FETCH_STATUS';
export const PHONE_LOGIN_CONFIRMATION_REQUEST_FETCH_STATUS = 'PHONE_LOGIN_CONFIRMATION_REQUEST_FETCH_STATUS';
export const PHONE_VERIFICATION_SEND_FETCH_STATUS = 'PHONE_VERIFICATION_SEND_FETCH_STATUS';
export const PHONE_VERIFICATION_VERIFY_FETCH_STATUS = 'PHONE_VERIFICATION_VERIFY_FETCH_STATUS';
export const PHONE_VERIFICATION_SEND_DATA = 'PHONE_VERIFICATION_SEND_DATA';
export const PHONE_LOGIN_DATA = 'PHONE_LOGIN_DATA';
export const SET_IS_LOGGED_STATUS = 'SET_IS_LOGGED_STATUS';
export const REGISTRATION_POST_STATUS = 'REGISTRATION_POST_STATUS';
export const CHANGE_PASSWORD_POST_STATUS = 'CHANGE_PASSWORD_POST_STATUS';
export const SET_PASSWORD_RESET_POST_STATUS = 'SET_PASSWORD_RESET_POST_STATUS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_FETCH_STATUS = 'SET_USER_FETCH_STATUS';
export const SET_USER_PATCH_STATUS = 'SET_USER_PATCH_STATUS';
export const TOKEN_UPDATE = 'TOKEN_UPDATE';
