import {
  SET_EVENT_DETAILS,
  SET_EVENT_LIST,
  SET_EVENT_LIST_FETCH_STATUS,
  SET_EVENT_USER_STATUS_POST_STATUS,
  SET_LAST_SELECTED_DATE
} from './events.actionTypes';

export const INITIAL_STATE: any = {
  eventList: [],
  eventDetails: null,
  lastSelectedDate: null,
  eventListFetchStatus: null,
  eventUserStatusPostStatus: null
};

export default function events(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_EVENT_LIST:
      return { ...state, eventList: action.payload };
    case SET_EVENT_DETAILS:
      return { ...state, eventDetails: action.payload };
    case SET_EVENT_LIST_FETCH_STATUS:
      return { ...state, eventListFetchStatus: action.payload };
    case SET_EVENT_USER_STATUS_POST_STATUS:
      return { ...state, eventUserStatusPostStatus: action.payload };
    case SET_LAST_SELECTED_DATE:
      return { ...state, lastSelectedDate: action.payload };
    default:
      return state;
  }
}
