import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ConfirmationComponent from '../../components/ConfirmationComponent/ConfirmationComponent';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import Share from '../../components/ShareComponent/Share';
import { RequestStatus } from '../../utils/RequestStatus';
import { setPetitionPostStatus, setSaveAnswerPostStatus } from '../../reducers/your-voice.actions';
import { useHistory } from 'react-router-dom';
import { TextContent } from '../../components/AbsoluteViewContainer';

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
`;

function OpinionPollConfirmation(props) {
  let history = useHistory();
  const { petitionDetails, petitionPostStatus } = props;
  const dispatch = useDispatch();
  const [id, setId] = useState(petitionDetails ? petitionDetails.id : null);
  const [type, setType] = useState(() => {
    if (petitionPostStatus === RequestStatus.status.DONE) {
      return 'CREATE';
    } else {
      return 'ANSWER';
    }
  });

  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Twój głos' });
    dispatch(setPetitionPostStatus(RequestStatus.status.NULL));
    dispatch(setSaveAnswerPostStatus(RequestStatus.status.NULL));
    if (!id) {
      history.goBack();
    }
  }, []);

  return (
    <React.Fragment>
      <Container>
        <ConfirmationComponent
          headerLabel={'Sonda'}
          url={`/your-voice/opinion-polls/${id}`}
          actionType={type}
          text={'Twój głos został pomyślnie zapisany!'}
        />
      </Container>
      <TextContent>
        <Share style={{ marginTop: '30px' }} url={petitionDetails && petitionDetails.share_url} />
      </TextContent>
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    petitionDetails: state.yourVoice.petitionDetails,
    petitionPostStatus: state.yourVoice.petitionPostStatus
  };
}

export default connect(mapStateToProps, {})(OpinionPollConfirmation);
