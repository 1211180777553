import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT } from '../../reducers/utils.actionTypes';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { colors } from './../../theme';
import { getSurveyList, setPetitionData, setSurveyList } from '../../reducers/your-voice.actions';
import { useHistory } from 'react-router-dom';
import { setVotingListTabIndex } from '../../reducers/utils.actions';
import SurveyRowComponent from '../../components/SurveyRowComponent/SurveyRowComponent';
import TabPanel from './TabPanel';

const useStyles = makeStyles({
  root: {
    background: 'white',
    border: 0,
    color: 'black',
    height: 48,
    padding: '0 30px'
  },
  textColorPrimary: {
    color: 'black',
    fontWeight: 'bold',
    border: 0,
    '&$selected': {
      color: 'black'
    }
  },
  selected: {
    color: 'black !important'
  },
  indicator: {
    backgroundColor: colors.secondaryColor,
    height: 3
  }
});

function LinkTab(props) {
  const classes = useStyles({});

  return (
    <Tab
      classes={{
        root: classes.root,
        textColorPrimary: classes.textColorPrimary,
        selected: classes.selected
      }}
      onClick={event => {
        event.preventDefault();
      }}
      style={{ padding: '0 15px' }}
      {...props}
    />
  );
}

function VotingList(props) {
  const { surveyList, votingListTabIndex, requestCount } = props;
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [filterType, setFilterType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [index, handleChangeIndex] = useState(votingListTabIndex);

  const listData = useMemo(() => {
    if (!surveyList || !surveyList.length || !filterType) return [];
    return surveyList && filterType
      ? surveyList.filter(x => x.survey_type === filterType)
      : surveyList;
  }, [filterType, surveyList]);

  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Twój głos' });
    const query = new URLSearchParams(props.location.search);
    setFilterType(query.get('survey_type'));
  }, []);

  useEffect(() => {
    let filter = null;
    if (index === 0) setFilter('my_surveys');
    else if (index === 1) setFilter('current');
    else if (index === 2) setFilter('ended');
    else setFilter('');

    dispatch(setVotingListTabIndex(index));
  }, [index]);

  useEffect(() => {
    dispatch(setSurveyList([]));
    if (filter) dispatch(getSurveyList({ filter }));
  }, [filter]);

  return (
    <div>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          classes={{ indicator: classes.indicator }}
          variant="fullWidth"
          value={index}
          onChange={(event, value) => handleChangeIndex(value)}
          indicatorColor="primary"
          textColor="primary"
          style={{ background: 'white', boxShadow: 'none' }}
        >
          <LinkTab label="Mój głos" />
          <LinkTab label="Trwające" />
          <LinkTab label="Zakończone" />
        </Tabs>
      </AppBar>

      <TabPanel value={index} index={0}>
        <SurveyRowComponent list={listData} />
      </TabPanel>
      <TabPanel value={index} index={1}>
        <SurveyRowComponent list={listData} />
      </TabPanel>
      <TabPanel value={index} index={2}>
        <SurveyRowComponent list={listData} />
      </TabPanel>
    </div>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    surveyList: state.yourVoice.surveyList,
    votingListTabIndex: state.utils.votingListTabIndex,
    requestCount: state.loader.requestCount
  };
}

export default connect(mapStateToProps, {
  setSurveyList,
  setPetitionData,
  setVotingListTabIndex
})(VotingList);
// VotingList.whyDidYouRender = true;
