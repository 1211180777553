import { SET_NEWS_DETAILS, SET_NEWS_LIST } from "./news.actionTypes";
import {
  SET_PETITION_DETAILS,
  SET_PETITION_DETAILS_FETCH_STATUS,
  SET_PETITION_POST_STATUS, SET_SAVE_ANSWER_POST_STATUS, SET_SURVEY_LIST, SET_SURVEYS_FETCH_STATUS
} from "./your-voice.actionTypes";

export const INITIAL_STATE: any = {
  petitionDetails: null,
  petitionPostStatus: null,
  petitionDetailsFetchStatus: null,
  saveAnswerPostStatus: null,
  surveysFetchStatus: null,
  surveyList: null
};

export default function yourVoice(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PETITION_POST_STATUS:
      return {...state, petitionPostStatus: action.payload};
    case SET_PETITION_DETAILS_FETCH_STATUS:
      return {...state, petitionDetailsFetchStatus: action.payload};
    case SET_PETITION_DETAILS:
      return {...state, petitionDetails: action.payload};
    case SET_SAVE_ANSWER_POST_STATUS:
      return {...state, saveAnswerPostStatus: action.payload};
    case SET_SURVEYS_FETCH_STATUS:
      return {...state, surveysFetchStatus: action.payload};
    case SET_SURVEY_LIST:
      return {...state, surveyList: action.payload};
    default:
      return state;
  }
}
