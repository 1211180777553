import { connect } from 'react-redux';
import KnowledgeBase from './KnowledgeBase';
import { setNavTitle } from '../../reducers/nav.actions';
import { getVideosList } from '../../reducers/knowledge-base.actions';

function mapStateToProps(state, otherProps) {
  return {
    videos: state.knowledgeBase.knowledgeBaseVideos
  };
}

export default connect(mapStateToProps, { setNavTitle, getVideosList })(KnowledgeBase);
