import {
  SET_NEWS_DETAILS,
  SET_NEWS_DETAILS_FETCH_STATUS,
  SET_NEWS_LIST,
  SET_NEWS_LIST_FETCH_STATUS
} from "./news.actionTypes";

export const INITIAL_STATE: any = {
  newsList: [],
  newsDetails: null,
  newsListFetchStatus: null,
  newsDetailsFetchStatus: null,
};

export default function news(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_NEWS_LIST:
      return {...state, newsList: action.payload};
    case SET_NEWS_LIST_FETCH_STATUS:
      return {...state, newsListFetchStatus: action.payload};
    case SET_NEWS_DETAILS:
      return {...state, newsDetails: action.payload};
    case SET_NEWS_DETAILS_FETCH_STATUS:
      return {...state, newsDetailsFetchStatus: action.payload};
    default:
      return state;
  }
}
