import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { setPetitionData } from "../../reducers/your-voice.actions";
import { useHistory } from "react-router-dom";

const QuestionContainer = styled.div`
    padding: 15px 10px;
    margin:5px 0;   
    background: ${props => props.answer === true ? props.theme.secondaryColor : props.answer === false ? props.theme.dangerColor : 'gray'};
    color: white;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
`;

const AnswerBox = styled.div`
    font-weight: bold;
    border: 1px solid white;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
        height: 35px;
        
`;

const TextMuted = styled.div`
    text-align: center;
    margin: 50px;
    color: darkgray;
       `;

const SectionLabel = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px 20px 5px 20px;
`;


const SurveyRowComponent = (props) => {
  const {list, requestCount} = props;
  let history = useHistory();
  const dispatch = useDispatch();

  const [surveyLists, setSurveyLists] = useState(null);

  useEffect(() => {
    const tmpLists = {
      petitions: [],
      opinionPolls: [],
      extendedSurveys: []
    };
    if (list && list.length) {
      list.forEach(x => {
        if (x.survey_type === 'surveys.simplesurvey') {
          tmpLists[x.hasOwnProperty('is_user_survey') && x.is_user_survey ? 'petitions' : 'opinionPolls'].push(x);
        } else {
          tmpLists['extendedSurveys'].push(x);
        }
      })
    }
    setSurveyLists(tmpLists);
  }, [list]);

  const checkAnswer = (survey) => {
    if (!survey.submissions || !survey.submissions.length || survey.survey_type === 'surveys.extendedsurvey')
      return null;
    else {
      return survey.submissions && survey.submissions.length && survey.submissions[0].answers && survey.submissions[0].answers.length && survey.submissions[0].answers[0].text === 'Tak'
    }
  };

  const goToDetails = (survey) => {
    dispatch(setPetitionData(null));
    if (survey.survey_type === 'surveys.extendedsurvey') {
      history.push(`/your-voice/questionnaire/${survey.id}`);
    } else if (survey.survey_type === 'surveys.simplesurvey') {
      if (survey.hasOwnProperty('is_user_survey') && survey.is_user_survey) {
        history.push(`/your-voice/petitions/${survey.id}`);
      } else {
        history.push(`/your-voice/opinion-polls/${survey.id}`);
      }
    }
  };

  return (
    <React.Fragment>
      {(list && list.length && surveyLists) ? <React.Fragment>
        {(surveyLists && !!surveyLists.petitions.length) && <SectionLabel>Postulaty</SectionLabel>}
        {surveyLists.petitions.length ? surveyLists.petitions.map(survey =>
          <QuestionContainer key={survey.id}
                             onClick={() => {
                               goToDetails(survey)
                             }}
                             answer={checkAnswer(survey)}>
            {survey.survey_type === 'surveys.simplesurvey' ?
              <AnswerBox>{(!survey.submissions || !survey.submissions.length)
                ? '-'
                : survey.submissions && survey.submissions.length && survey.submissions[0].answers && survey.submissions[0].answers.length && survey.submissions[0].answers[0].text === 'Tak' ? 'TAK' : 'NIE'}
              </AnswerBox> : <div></div>}
            <div>{survey.label}</div>
          </QuestionContainer>
        ) : ''}

        {(surveyLists && !!surveyLists.opinionPolls.length) && <SectionLabel>Sondaże</SectionLabel>}
        {surveyLists.opinionPolls ? surveyLists.opinionPolls.map(survey =>
          <QuestionContainer key={survey.id}
                             onClick={() => {
                               goToDetails(survey)
                             }}
                             answer={checkAnswer(survey)}>
            {survey.survey_type === 'surveys.simplesurvey' ?
              <AnswerBox>{(!survey.submissions || !survey.submissions.length)
                ? '-'
                : survey.submissions && survey.submissions.length && survey.submissions[0].answers && survey.submissions[0].answers.length && survey.submissions[0].answers[0].text === 'Tak' ? 'TAK' : 'NIE'}
              </AnswerBox> : <div></div>}
            <div>{survey.label}</div>
          </QuestionContainer>
        ) : ''}

        {(surveyLists && !!surveyLists.extendedSurveys.length) && <SectionLabel>Ankiety</SectionLabel>}
        {surveyLists.extendedSurveys ? surveyLists.extendedSurveys.map(survey =>
          <QuestionContainer key={survey.id}
                             onClick={() => {
                               goToDetails(survey)
                             }}
                             answer={checkAnswer(survey)}>
            {survey.survey_type === 'surveys.simplesurvey' ?
              <AnswerBox>{(!survey.submissions || !survey.submissions.length)
                ? '-'
                : survey.submissions && survey.submissions.length && survey.submissions[0].answers && survey.submissions[0].answers.length && survey.submissions[0].answers[0].text === 'Tak' ? 'TAK' : 'NIE'}
              </AnswerBox> : <div></div>}
            <div>{survey.label}</div>
          </QuestionContainer>
        ) : ''}
      </React.Fragment> : ''}
      {
        !requestCount && list && !list.length && <TextMuted>
            Brak elementów do wyświetlenia
        </TextMuted>
      }
    </React.Fragment>
  );
};

function mapStateToProps(state, otherProps) {
  return {
    requestCount: state.loader.requestCount
  };
}

export default connect(
  mapStateToProps,
  {}
)(SurveyRowComponent);
// SurveyRowComponent.whyDidYouRender = true;
