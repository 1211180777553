import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SET_HEADER_PRIMARY_TEXT, SET_MENU_OPEN_STATUS } from '../../reducers/utils.actionTypes';
import NewsElement from '../../components/NewsElement/NewsCard';
import { useHistory } from 'react-router-dom';
import { getNewsList } from '../../reducers/news.actions';
import { getNewsList as getNewsListURL } from '../../routes';

function NewsList(props) {
  const { newsList, user } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const [userData] = useState(user);

  useEffect(() => {
    dispatch({ type: SET_HEADER_PRIMARY_TEXT, payload: 'Informator' });
    dispatch(getNewsList({}));
  }, []);

  useEffect(() => {
    if (userData && !userData.phone) {
      const query = new URLSearchParams(props.location.search);
      if (+query.get('from_fb') === 1) {
        setTimeout(() => {
          dispatch({ type: SET_MENU_OPEN_STATUS, payload: true });
        });
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      {newsList.map(news => {
        return (
          <div
            key={news.id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push(`${getNewsListURL()}/${news.id}`);
            }}
          >
            <NewsElement news={news} descriptionKey={'short_description'} />
          </div>
        );
      })}
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {
    user: state.auth.user,
    newsList: state.news.newsList
  };
}

export default connect(mapStateToProps, {})(NewsList);
// NewsList.whyDidYouRender = true;
