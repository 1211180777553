const getBrowserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => locationSuccess(position, resolve),
        error => locationError(error, reject)
      );
    } else {
      console.log('Geo Location not supported by browser');
    }
  });
};

const locationSuccess = (position, resolve) =>
  resolve({
    longitude: position.coords.longitude,
    latitude: position.coords.latitude
  });

const locationError = (error, reject) => {
  console.error(error);
  reject(error);
};

export { getBrowserLocation };
