import { SET_CONTACT_MESSAGE_POST_STATUS } from "./contact-messages.actionTypes";

export const INITIAL_STATE: any = {
  contactMessagePostStatus: null
};

export default function contactMessages(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CONTACT_MESSAGE_POST_STATUS:
      return {
        ...state,
        contactMessagePostStatus: action.payload,
      };
    default:
      return state;
  }
}
