import React from 'react';
import styled from 'styled-components';
import { ButtonContainer, ButtonIcon, WhiteText } from './LoginParts';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

const LoginKnowledgeBaseContainer = styled(ButtonContainer)`
  height: 25px;
  margin-top: 22px;
  margin-bottom: 22px;
`;

const LoginKnowledgeBaseElements = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  color: white;
`;

const StyledWhiteText = styled(WhiteText)`
  padding-top: 0px;
  margin-right: 20px;
  text-align: right;
`;

const IconContainer = styled.div`
  padding-top: 3px;
`;

function LoginKnowledgeBase(props) {
  const { url: knowledgeBaseURL } = props;

  return (
    <LoginKnowledgeBaseContainer>
      <LoginKnowledgeBaseElements href={knowledgeBaseURL}>
        <StyledWhiteText>
          Dowiedz się <br /> jak korzystać z aplikacji:
        </StyledWhiteText>

        <IconContainer>
          <OndemandVideoIcon style={{ fontSize: 35 }} />
        </IconContainer>
      </LoginKnowledgeBaseElements>
    </LoginKnowledgeBaseContainer>
  );
}

export default LoginKnowledgeBase;
