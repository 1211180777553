// import theme from 'styled-theming';

// general bgColors set in BaseTemplate
export const colors = {
  primaryColor: '#195F34',
  secondaryColor: '#8DB755',
  accentDarkColor: '#1B7049',
  accentLightColor: '#8DB755',
  splashColor: '#1F7044',
  dangerColor: '#E41D1A',
  errorColor: '#ff8f8d',
  descriptionColor: '#666666',
  backgroundColor: '#F2F2F2',
  whiteBgColor: '#FFFFFF',
  calendarFontColor: '#FFFFFF',
  disableColor: '#888888'
};

export const fontSizes = {
  cardTitle: '25px',
  cardDate: '18px',
  cardContent: '18px',
  calendarDay: '20px'
};

export const sizes = {
  navbarHeightPxVal: 56,
  navbarHeightDesktopPxVal: 64,
  bottomNavHeightPxVal: 56,
  navbarHeight: 'calc(56px + env(safe-area-inset-top))',
  navbarHeightDesktop: 'calc(64px + env(safe-area-inset-top))',
  bottomNavHeight: 'calc(56px + env(safe-area-inset-bottom))',
  maxContentWidthVuVal: 90,
  maxContentWidthPxVal: 1000,
  // vh for mobile Safari
  getSafariWindowHeight: () =>
    document && document.documentElement
      ? document.documentElement.clientHeight
      : window.innerHeight,
  expansionPanelElementHeight: 53
};

// used in build script
export const splash = {
  isSplashWithBgImg: false
};