import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { sizes } from '../../theme';
import { CardDate, CardDescription, CardTitle } from '../Card/CardParts';
import ResponsiveImage from '../ResponsiveImage';
import Share from '../ShareComponent/Share';

const TextContent = styled.div`
  max-width: ${sizes.maxContentWidthVuVal}vw;
  margin: 18px auto 0 auto;
`;

const NewsDetailsView = props => {
  const { news, descriptionKey } = props;

  return (
    <>
      {news.image && <ResponsiveImage src={news.image} isFullWidth={true} />}
      <TextContent>
        <CardTitle>{news.name}</CardTitle>
        <CardDate>{moment(news.created_at * 1000).format('DD.MM.YYYY, dddd')}</CardDate>
        <CardDescription dangerouslySetInnerHTML={{ __html: news[descriptionKey] }} />
        <Share url={news && news.share_url} />
      </TextContent>
    </>
  );
};

export default NewsDetailsView;
