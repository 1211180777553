import { FormContainer } from '../../utils/uiComponents';
import styled from 'styled-components';
import { colors } from '../../theme';

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
`;

export const LogoContainer = styled.div`
  display: grid;
  justify-items: center;
`;

export const ButtonContainer = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-template-columns: 80%;
`;

export const ButtonIcon = styled.div`
  background: ${colors.primaryColor};
  border-radius: 100%;
  height: 55px;
  width: 55px;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 30px;
`;

export const LogoImg = styled.img`
  margin-top: 25px;
  margin-bottom: 25px;
  height: 200px;
`;

export const WhiteText = styled.div`
  text-align: center;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
`;

export const MenuBarPlaceholder = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginPage = styled.div`
  background: ${props => props.theme.primaryColor};
  min-height: 100vh;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr 3r 1fr 1fr;
  position: relative;
  align-items: center;
`;

export const LogByLabel = styled.div`
  color: white;
  text-align: center;
  font-size: 16px;
`;

export const TabName = styled.div`
  text-align: center;
  margin: 20px 0 20px 0;
  font-size: 20px;
`;

export const Paragraph = styled.p`
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
`;

export const AdditionalButton = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 40px auto;
`;

export const FormContainerStyles = styled(FormContainer)`
  padding-bottom: 50px;
  grid-template-columns: 80%;
  position: relative;
`;
