import { colors, splash } from '../theme';
import styled from 'styled-components';
import React, { forwardRef, useEffect, useState } from 'react';

const SingleSplashImage = styled.img`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  background-color: ${colors.splashColor};
  z-index: 2050;
`;

const MultiSplashImageContainer = styled.div`
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  opacity: ${props => (props.animate ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
  outline: none;
  position: fixed;
  top: 0;
  z-index: 2000;
`;

const SplashMainImage = styled.img`
  position: fixed;
  top: 25vh;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 50vw;
  max-height: 70vh;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  z-index: 2050;
`;

const SplashBgImage = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background-image: url('images/splash-bg.png');
  filter: grayscale(100%);
  background-size: cover;
`;

function FakeSplash(props) {
  const { forwardRef, animate, isVisible } = props;

  return !splash.isSplashWithBgImg ? (
    <MultiSplashImageContainer ref={forwardRef} animate={animate} isVisible={isVisible}>
      <SingleSplashImage src={`images/splash.png`} alt="" />
    </MultiSplashImageContainer>
  ) : (
    <MultiSplashImageContainer ref={forwardRef} animate={animate} isVisible={isVisible}>
      <SplashBgImage />
      <SplashMainImage src={`images/splash.png`} alt="" />
    </MultiSplashImageContainer>
  );
}

export default forwardRef((props: any, ref) => <FakeSplash {...props} forwardRef={ref} />);
