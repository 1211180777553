import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { CardDate, CardDescription, CardTitle } from '../Card/CardParts';
import Card from '../Card/Card';

const NewsCard = props => {
  const { news, descriptionKey } = props;

  const [modifiedContent, setModifiedContent] = useState(news);

  const modifyContentToExtractCardImage = () => {
    if (news.image) return;
    let modifiedNews = { ...news };
    let newsDescription = news[descriptionKey];
    const startIdxOfImgTag = newsDescription.indexOf('<img ');
    if (startIdxOfImgTag < 0) return;
    modifiedNews.image = extractImage(newsDescription, startIdxOfImgTag);
    modifiedNews[descriptionKey] = removeImgFromDescription({
      description: newsDescription,
      image: modifiedNews.image,
      startIdxOfImgTag
    });
    setModifiedContent(modifiedNews);
  };

  const extractImage = (newsDescription, startIdx) => {
    const startIdxOfImg = newsDescription.indexOf('src="') + 5;
    const endIdxOfImg = newsDescription.slice(startIdxOfImg).indexOf('"');
    return newsDescription.slice(startIdxOfImg, endIdxOfImg + startIdxOfImg);
  };

  const removeImgFromDescription = ({ description, image, startIdxOfImgTag }) => {
    let modifiedDesc = description.replace(image, '');
    //todo: case when img already have class
    return modifiedDesc.replace('<img ', '<img class="replaced" ');
  };

  useEffect(modifyContentToExtractCardImage, [news]);

  return (
    <Card image={modifiedContent.image}>
      <CardTitle>{news.name}</CardTitle>
      <CardDate>{moment(news.created_at * 1000).format('DD.MM.YYYY, dddd')}</CardDate>
      <CardDescription dangerouslySetInnerHTML={{ __html: modifiedContent[descriptionKey] }} />
    </Card>
  );
};

export default React.memo(NewsCard);
