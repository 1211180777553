import { SET_DEVICE_DELETE_STATUS, SET_DEVICE_POST_STATUS } from "./notifications.actionTypes";


export const INITIAL_STATE: any = {
  devicePostStatus: null,
  deviceDeleteStatus: null
};

export default function utils(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DEVICE_POST_STATUS:
      return {
        ...state,
        devicePostStatus: action.payload,
      };
    case SET_DEVICE_DELETE_STATUS:
      return {
        ...state,
        deviceDeleteStatus: action.payload,
      };
    // case userActionTypes.UNAUTH_USER:
    //     return {...INITIAL_STATE};
    default:
      return state;
  }
}
