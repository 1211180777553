import { RequestStatus } from "../utils/RequestStatus";
import axios from "axios";
import { SET_CONTACT_MESSAGE_POST_STATUS } from "./contact-messages.actionTypes";
import { SNACKBAR_STATUS } from "./utils.actionTypes";

export function postContactMessage(data, photos) {
  let url = process.env.REACT_APP_API_URL + `/contact_messages/`;
  return function (dispatch) {
    dispatch(setContactMessagePostStatus(RequestStatus.status.FETCHING));
    let formData = null;
    if (photos) {
      formData = new FormData();
      Object.keys(photos).forEach(key => {
        if (photos[key])
          formData.append(key, new Blob(photos[key], {type: photos[key][0].type}), photos[key][0].name);

      });
      Object.keys(data).filter(key => key.indexOf('image') === -1).forEach(key => {
        formData.set(key, data[key]);
      });
    }
    axios
      .post(url, formData || data)
      .then(response => {
        dispatch(setContactMessagePostStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setContactMessagePostStatus(RequestStatus.status.ERROR));
        dispatch({
          type: SNACKBAR_STATUS,
          payload: {
            status: true,
            content: 'Problem z wysłaniem zgłoszenia. Prosimy spróbować później.'
          }
        });
      });
  };
}

export const setContactMessagePostStatus = status => {
  return {
    type: SET_CONTACT_MESSAGE_POST_STATUS,
    payload: status
  };
};
