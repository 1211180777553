import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Container = styled.div`
  padding-bottom: 30px;
`;

const ShareHeader = styled.div`
  text-align: left;
  margin: 10px 0 18px 0;
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  padding-top: 15px;
  font-weight: bold;
`;

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 60px;
  justify-content: left;
  grid-column-gap: 35px;
`;

const IconElement = styled.div`
  width: 60px;
  height: 60px;
`;

const ImgElement = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

// according to redesigned mockups
const ShareComponent = props => {
  return (
    <Container>
      <ShareHeader>Podziel się</ShareHeader>
      <IconContainer>
        <IconElement>
          <ImgElement
            onClick={() =>
              props.url &&
              window.open(`https://www.facebook.com/sharer/sharer.php?u=${props.url}`, '_blank')
            }
            src={`/images/social/fb.png`}
            alt="Facebook Icon"
          />
        </IconElement>
        <IconElement>
          <ImgElement
            onClick={() =>
              props.url &&
              window.open(`https://twitter.com/intent/tweet?url=${props.url}`, '_blank')
            }
            src={`/images/social/twitter.png`}
            alt="Twitter Icon"
          />
        </IconElement>
      </IconContainer>
    </Container>
  );
};

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(mapStateToProps, {})(ShareComponent);
