import axios from 'axios';
import { setIsLoggedStatus } from '../reducers/auth.actions';
import { SET_IS_LOGGED_STATUS } from '../reducers/auth.actionTypes';
// import { userActionTypes } from '../reducers/auth.actionTypes';
// import { getSessionToken } from '../utilities';
import { history } from './utils';
import { SNACKBAR_STATUS } from '../reducers/utils.actionTypes';

export default class NetworkService {
  static setupDefaultHeaders(token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
  }

  // static syncHeaderTokenWithStorage() {
  //   const token = getSessionToken();
  //   if (token && token.length > 1) this.setupDefaultHeaders(token);
  // }

  static setupInterceptors(store) {
    // Add a response interceptor
    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (+error.response.status === 401) {
          localStorage.clear();
          axios.defaults.headers.common = {};
          // store.dispatch({ type: userActionTypes.UNAUTH_USER });
          // move this below to store action
          store.dispatch({ type: SET_IS_LOGGED_STATUS, payload: false });
          history.push('/login');
        }
        if (+error.response.status === 500) {
          store.dispatch({
            type: SNACKBAR_STATUS,
            payload: { status: true, content: 'Błąd serwera' }
          });
        }
        return Promise.reject(error);
      }
    );
  }
}
