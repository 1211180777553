import React from 'react';
import { BoxButton } from '../../utils/uiComponents';
import { AdditionalButton } from './LoginParts';
import { userAgreement } from './register.constants';
import Checkbox from '../../components/Checkbox'

function LoginAgreement(props) {
  const { form, values } = props;

  return (
    <AdditionalButton>
      <Checkbox
        onClick={() => {
          form.mutators.setValue('is_resident', !values.is_resident);
        }}
        selected={!!values.is_resident}
      />
      {userAgreement}
    </AdditionalButton>
  );
}

export default LoginAgreement;
