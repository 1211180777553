import { RequestStatus } from "../utils/RequestStatus";
import axios from "axios";
import { SET_DEVICE_DELETE_STATUS, SET_DEVICE_POST_STATUS } from "./notifications.actionTypes";


export function postDevice(data) {
  let url = process.env.REACT_APP_API_URL + `/devices/`;
  return function (dispatch) {
    dispatch(setDevicePostStatus(RequestStatus.status.FETCHING));
    axios
      .post(url, data)
      .then(response => {
        dispatch(setDevicePostStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setDevicePostStatus(RequestStatus.status.ERROR));
      });
  };
}

const setDevicePostStatus = status => {
  return {
    type: SET_DEVICE_POST_STATUS,
    payload: status
  };
};

export function deleteDevice(id) {
  let url = process.env.REACT_APP_API_URL + `/devices/${id}`;
  return function (dispatch) {
    dispatch(setDeviceDeleteStatus(RequestStatus.status.FETCHING));
    axios
      .delete(url)
      .then(response => {
        dispatch(setDeviceDeleteStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setDeviceDeleteStatus(RequestStatus.status.ERROR));
      });
  };
}

const setDeviceDeleteStatus = status => {
  return {
    type: SET_DEVICE_DELETE_STATUS,
    payload: status
  };
};
