import React from 'react';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { CardTitle, CardDate, CardDescription } from '../../components/Card/CardParts';
import { convertTimestampToHour, isTimestampFromThePast } from '../../utils/dateFormatting';
import { userStatus } from './calendar.constants';
import Checkbox from '../../components/Checkbox';

const ButtonsBlock = styled.div`
  display: flex;
  margin-top: 10px;
`;

const StyledCheckbox = styled(Checkbox)``;

function CalendarCard(props) {
  const { calendarEvent, onClick, changeEventUserStatus } = props;

  return (
    <Card image={calendarEvent.image} onImageClick={onClick}>
      <CardTitle onClick={onClick}>{calendarEvent.name}</CardTitle>
      <CardDate>GODZ. {convertTimestampToHour(calendarEvent.date)}</CardDate>
      <ButtonsBlock>
        <StyledCheckbox
          selected={calendarEvent.user_status === userStatus.GOING}
          text="Wezmę udział"
          onClick={() => changeEventUserStatus(calendarEvent, userStatus.GOING)}
          disable={isTimestampFromThePast(calendarEvent.date)}
        />
        <StyledCheckbox
          selected={calendarEvent.user_status === userStatus.INTERESTED}
          text="Zainteresowany"
          onClick={() => changeEventUserStatus(calendarEvent, userStatus.INTERESTED)}
          disable={isTimestampFromThePast(calendarEvent.date)}
        />
      </ButtonsBlock>
    </Card>
  );
}

export default React.memo(CalendarCard);
