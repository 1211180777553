import React, { useEffect } from 'react';
import styled from "styled-components";
import ConfirmationComponent from "../../components/ConfirmationComponent/ConfirmationComponent";
import { connect, useDispatch } from "react-redux";
import { SET_HEADER_PRIMARY_TEXT } from "../../reducers/utils.actionTypes";
import { RequestStatus } from "../../utils/RequestStatus";
import { setContactMessagePostStatus } from "../../reducers/contact-messages.actions";

const Container = styled.div`
width:100vw;
max-width:100%;
justify-content: center;
    align-items: center;
    display: grid;
`;

function ContactMessageConfirmation(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({type: SET_HEADER_PRIMARY_TEXT, payload: 'Zgłoszenie'});
    dispatch(setContactMessagePostStatus(RequestStatus.status.NULL));
  }, []);

  return (
    <React.Fragment>
      <Container>
        <ConfirmationComponent
          headerLabel={'Zgłoszenie'}
          actionType={''}
          text={'Dziękujemy za przesłanie zgłoszenia!'}
        />
      </Container>
    </React.Fragment>
  );
}

function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(ContactMessageConfirmation);
