import React, { useEffect } from 'react';
import { SET_HEADER_PRIMARY_TEXT, SET_MENU_OPEN_STATUS } from "../../reducers/utils.actionTypes";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { CloseIconStyle } from "../../utils/uiComponents";
import { getNewsList } from '../../routes'

const Container = styled.div`
width:100vw;
max-width: 100%;
height: 100vh;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url('/images/bg.jpg');
    background-position: center center;
    background-size: cover;
`;

const LogoImg = styled.img`
width:50%;
max-width: 300px;
object-fit: contain;
`;

const BadgeImg = styled.img`
    width: 125px;
`;

const DataContainer = styled.div`
height: 100%;
display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-column-gap: 20px;
    grid-template-rows: 3fr 2fr 1fr;
`;

const CompanyInfo = styled.div`
display: grid; 
grid-row-gap: 15px;
`;

const IconRow = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
`;

const BottomLabel = styled.div`
    margin-bottom:30px;
    font-size:14px;
`;


function HelpPage(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    dispatch({type: SET_HEADER_PRIMARY_TEXT, payload: 'Pomoc'});
    dispatch({type: SET_MENU_OPEN_STATUS, payload: false})
  }, []);


  return (
    <Container>
      <CloseIconStyle data-whitebg style={{fontSize: 40}} onClick={() => {
        history.push(`${getNewsList}/`)
      }}/>
      <div>
        <LogoImg src="/images/ivoting-logo.png" alt=""/>
      </div>
      <DataContainer>
        <CompanyInfo>
          <div><b>Carbonet.pl Sp. z o.o.</b></div>
          <div>ul. Cieszyńska 365,
            43-300 Bielsko-Biała
          </div>
          <div>REGON: 368637979 / NIP: 5472186055 <br/> KRS: 0000695975</div>
          <div>www.ivoting.pl / biuro@ivoting.pl</div>
          <div>Tel: 606 648 115</div>
        </CompanyInfo>
        <IconRow>
          <div><BadgeImg src="/images/google-play-icon.png" alt=""/></div>
          <div><BadgeImg src="/images/app-store-icon.png" alt=""/></div>
        </IconRow>
        <BottomLabel onClick={() => {
          history.push('/your-voice/contact-messages/add?type=2');
        }}><b>Zgłoś uwagi do aplikacji</b></BottomLabel>
      </DataContainer>
    </Container>
  );
}


function mapStateToProps(state, otherProps) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(HelpPage);

